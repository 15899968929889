import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";


const Export = () => {

    const editor = useRef(null)


    //Setto gli state
    const [allUsers, viewUsers] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(true)

    const [isCreated, setCreated] = useState(false)
    const [showSucc, setSucc] = useState('')
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);



	  const [content, setContent] = useState('')

	  const config = {
		readonly: false,
    height: 'auto',
    placeholder: '',
    language: 'it' // all options from https://xdsoft.net/jodit/doc/
	  }


      //Recupero i dati dal db
    useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/invoice/fetchYearsInvoice`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(users.data)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()


    //Chiamata post per inserimento utente
    const onSubmit = data => {
        const annoRiferimento = selectedOption;

    //Genero il report
    axios.post(`${window.$apiEndpoint}/invoice/fetchYearsInvoiceCSV`, {
        anno: annoRiferimento,
    }, {
        headers: {
            'x-access-token' : cookies.token
        }
    })
        .then(res => {
            setUser(res.data.id_export)
            //Setto un Timeout per la risposta
            setTimeout(() => {setLoading2(true)},10000)
            setTimeout(() => {setLoading3(false)},500)
            setSucc('Esportazione il elaborazione....Si prega di attendere..')
        }).catch(err => {
            setErr('Errore nella generazione del Export - ' + err.message)
        })



}

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
        // redirect to home if signed up
        return <Redirect to={'/add-appointments'} />;
        //return window.location.reload();
    }

    //Quando Seleziono

   const handleChange = selectedOption => {
      setSelectedOption(selectedOption.value);
      setSelectedOptionLabel(selectedOption.label);

}

    //Quando clicca sul tasto per scaricate
  const scarica = () => {
       console.log(user)
       const fetch = async () => {
         const users = await axios.get(`${window.$apiEndpoint}/invoice/exportCsv/${user}`, {
           headers: {
             "x-access-token": cookies.token
           }
         });
         try {
           console.log(users.data)
           var link = `https://chinelab.tribusadv.com/export_fatture_${user}.csv`
           window.open(link, "_blank");
         } catch (e) {
           console.log(e)
         }
         setLoading(true)
       }
       fetch();

 }

    return (
        <>
            <SectionTitle title="STATISTICHE" subtitle="Esporta Fatture" />
                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                                    <Col xs={6}>
                                    <Form.Group  controlId="cliente" >
                                    <Form.Label>Seleziona Anno di Riferimento:</Form.Label>

                                    {loading ?
                                    <Select value={selectedOption} onChange={handleChange} options={allUsers} />
                                    : <center><Spinner size="sm" animation="border" variant="info" /></center>
                                    }
                                    <pre className="mt-2">Anno Selezionato: {selectedOptionLabel} </pre>


                                    </Form.Group>
                                    </Col>


                                </Form.Row>

                                <p  className="my-2" style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>

                                {loading3 ? <Button variant="info" size="md" type="submit" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    Esporta Fatture
                                </Button> : null}


                                    </Form>
                                    {loading2 ? <Button onClick={scarica} variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600', marginTop: '15px'}}>
                                        Scarica Export Fatture
                                    </Button> : null}
                        </div>

                                </Col>

                            </Row>



                        </Container>

        </>
    )
}
export default Export
