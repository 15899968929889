import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";


const AddApp = () => {

    const editor = useRef(null)


    //Setto gli state
    const [allUsers, viewUsers] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [isCreated, setCreated] = useState(false)
    const [showSucc, setSucc] = useState('')
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);


    const [selectedOption1, setSelectedOption1] = useState(null);
    const [selectedOptionLabel1, setSelectedOptionLabel1] = useState(null);

    const [selectedOption_2, setSelectedOption_2] = useState(null);





	  const [content, setContent] = useState('')

	  const config = {
		readonly: false,
    height: 'auto',
    placeholder: '',
    language: 'it' // all options from https://xdsoft.net/jodit/doc/
	  }


      //Recupero i dati dal db
    useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/appoiments/fetchCApp`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(users.data)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Controllo se siamo nel ora legale o solare
  function isDST(d) {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) != d.getTimezoneOffset();
  }


  function isDST2(d) {
  const datanuova = new Date(d);
  console.log(datanuova)
  let jan = new Date(datanuova.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(datanuova.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) != d.getTimezoneOffset();
  }

  //Controllo il fuso da impostare a seconda se siamo nell'ora legale o solare
  function isDSTFuso(d) {

  const controllo = isDST2(d)
  //Controllo se true o false
  if (controllo === true) {
  //Ora Legale
  return ":00+02:00"
  } else {
  //Ora Solare
  return ":00+01:00"
    }
  }


    //Setto opzioni per il select della Ricorrenza

    const ricorrenza = [{
      value: "settimanale",
      label: "Ripetizione Settimanale"
    },{
      value: "mensile",
      label: "Ripetizione Mensile ( Fissa l'appuntamento al giorno selezionato per lo stesso mese )"
    },{
      value: "2settimane",
      label: "Ripetizione Ogni 2 Settimane"
    },{
      value: "4settimane",
      label: "Ripetizione Ogni 4 Settimane"
    }]

    //Chiamata post per inserimento utente
    const onSubmit = data => {
        const codice_cliente = selectedOption;
        const startDate = data.startDate;
        const endDate = data.endDate;
        const trattamento = content;
        const tipoRipetizione = selectedOption1;
        const nRipetizione = data.nRipetizione;
        //Variabile aumento
        var dt = new Date(startDate);
        var dte = new Date(endDate);





        //Controllo se siamo nell'ora legale o solare

        const dataSelezionata = new Date(startDate);
        console.log(dataSelezionata)
        const controlloG = isDST(dataSelezionata)

        if (controlloG === true) {
        //Ora Legale
        const provas2 = dt.setHours(dt.getHours() + 2)
        const provae2 = dte.setHours(dte.getHours() + 2)
        var dt2 = new Date(provas2);
        var dte2 = new Date(provae2);

        } else {

        const provas2 = dt.setHours(dt.getHours() + 1)
        const provae2 = dte.setHours(dte.getHours() + 1)
        var dt2 = new Date(provas2);
        var dte2 = new Date(provae2);

        }


        const trattamento_html = trattamento.replace(/<[^>]*>?/gm, '');
        const trattamento_html_2 = trattamento_html.replace(/\&nbsp;/g, '');

        //Genero le date come servono a Google Calendar
        const dataSelezionata2 = new Date(startDate);

        const data_starDate_Google = startDate + isDSTFuso(dataSelezionata2)
        const data_endDate_Google = endDate + isDSTFuso(dataSelezionata2)


        //Controllo se il codice cliente è selezionato
        if (!codice_cliente) {
          return (
            setErr('Cliente non selezionato'),
            setTimeout(() => {setErr('')},3000)
            )
        }

        //Controllo se il codice cliente è selezionato
        if (!tipoRipetizione) {
          return (
            setErr('Tipo di ripetizione non selezionato'),
            setTimeout(() => {setErr('')},3000)
            )
        }

        //Controllo se il codice cliente è selezionato
        if (!nRipetizione) {
          return (
            setErr('Numero di ripetizione vuoto'),
            setTimeout(() => {setErr('')},3000)
            )
        }


        //Controllo se il trattamento è stato inserito
        if (!trattamento) {
          return (
            setErr('Si prega di inserire il trattamento'),
            setTimeout(() => {setErr('')},3000)
            )
        }

        //Controllo quante volte devo inserire l'appuntamento a seconda di tipo ricorrenza e n di ricorrenza

        //Settimanale

        if (tipoRipetizione === 'settimanale') {

        var appoint = []

        //Inserisco appuntamento di Oggi


        axios.get(`${window.$apiEndpoint}/calendar/create-event/${codice_cliente}/${trattamento_html_2}/${data_starDate_Google}/${data_endDate_Google}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(res => {
          axios.post(`${window.$apiEndpoint}/appoiments/create`, {
            codice_cliente: codice_cliente,
            startDate: startDate,
            endDate: endDate,
            trattamento: trattamento,
            IdGoogle: res.data.Id_Google

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })
              .then(res => {
                  setUser(res.data)
                  console.log(res.data)
              }).catch(err => {
                setErr('Errore - ' + err.message)
              })
        })




        //Sono in ripetizione settiminale
        for (var i = 0; i < nRipetizione; i++) {
        //Incremento le date
        const provas = dt2.setDate(dt2.getDate() + 7)
        var s = new Date(provas).toISOString('it-IT').replace(/:00.000Z/, "");

        console.log("Consolo Log data inzio generata ciclo " + s)

        //console.log(dt)
        const provae =  dte2.setDate(dte2.getDate() + 7)

        var e = new Date(provae).toISOString('it-IT').replace(/:00.000Z/, "");

        console.log("Consolo Log data fine  generata ciclo" + e)


        //console.log(dte)

        let obj = {
          codice_cliente: codice_cliente,
          startDate: s,
          endDate: e,
          trattamento: trattamento,
        }
        //console.log(startDate)
        appoint.push(obj)
        //Inserisco Ripetitivi su Google Calendar
        //Genero le date come servono a Google Calendar
        const dataSelezionata2 = new Date(s);
        const data_starDate_Google_2 = s + isDSTFuso(dataSelezionata2)
        const data_endDate_Google_2 = e + isDSTFuso(dataSelezionata2)

        console.log("Console Inzio Data" + data_starDate_Google_2)
        console.log("Console Fine Data" + data_endDate_Google_2)


        const calendar = async() =>{
        try
        {
          const calendar_create = await axios.get(`${window.$apiEndpoint}/calendar/create-event/${codice_cliente}/${trattamento_html_2}/${data_starDate_Google_2}/${data_endDate_Google_2}`, {
          headers: {
            "x-access-token": cookies.token
          }
        })

        const create = async() => {
          try {



            //Genero le date come servono a Google Calendar
            const data_starDate_Google_3 = data_starDate_Google_2.slice(0, 16);
            const data_endDate_Google_3 = data_endDate_Google_2.slice(0, 16);


            const create_app = await axios.post(`${window.$apiEndpoint}/appoiments/create`, {
            codice_cliente: codice_cliente,
            startDate: data_starDate_Google_3,
            endDate: data_endDate_Google_3,
            trattamento: trattamento,
            IdGoogle: calendar_create.data.Id_Google

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })

          setUser(create_app.data)
          console.log(create_app.data)
          setTimeout(() => {setCreated(true)},2500)
          setSucc('Appuntamenti creati con successo, a breve la pagina verrà ricaricata.')} catch (err) { setErr(err.message)}
        }
        create()} catch (err){
          setErr(err.message)
        }
        }
        calendar()

        }
        console.log(appoint)
      } else if (tipoRipetizione === 'mensile') {

        //Sono nel mensile eseguo ripetizione mensile

        console.log("Sono nel Mensile")

        //Inserisco appuntamento di Oggi


        axios.get(`${window.$apiEndpoint}/calendar/create-event/${codice_cliente}/${trattamento_html_2}/${data_starDate_Google}/${data_endDate_Google}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(res => {
          axios.post(`${window.$apiEndpoint}/appoiments/create`, {
            codice_cliente: codice_cliente,
            startDate: startDate,
            endDate: endDate,
            trattamento: trattamento,
            IdGoogle: res.data.Id_Google

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })
              .then(res => {
                  setUser(res.data)
                  console.log(res.data)
              }).catch(err => {
                setErr('Errore - ' + err.message)
              })
        })


        for (var i = 0; i < nRipetizione; i++) {
        //Incremento le date
        const dms = dt2.setMonth( dt2.getMonth() + 1);
        var dms2 = new Date(dms).toISOString('it-IT').replace(/:00.000Z/, "");

        //console.log(dt)
        const dme =  dte2.setMonth( dte2.getMonth() + 1);

        var dme2 = new Date(dme).toISOString('it-IT').replace(/:00.000Z/, "");

        console.log(dms2)
        console.log(dme2)


        //Inserisco Ripetitivi su Google Calendar
        //Genero le date come servono a Google Calendar
        const dataSelezionata2 = new Date(dms2);
        const data_starDate_Google_3 = dms2 + isDSTFuso(dataSelezionata2)
        const data_endDate_Google_3 = dme2 + isDSTFuso(dataSelezionata2)

        const calendar = async() =>{
        try
        {
          const calendar_create = await axios.get(`${window.$apiEndpoint}/calendar/create-event/${codice_cliente}/${trattamento_html_2}/${data_starDate_Google_3}/${data_endDate_Google_3}`, {
          headers: {
            "x-access-token": cookies.token
          }
        })

        const create = async() => {
          try {



            //Genero le date come servono a Google Calendar
            const data_starDate_Google_4 = data_starDate_Google_3.slice(0, 16);
            const data_endDate_Google_4 = data_endDate_Google_3.slice(0, 16);


            const create_app = await axios.post(`${window.$apiEndpoint}/appoiments/create`, {
            codice_cliente: codice_cliente,
            startDate: data_starDate_Google_4,
            endDate: data_endDate_Google_4,
            trattamento: trattamento,
            IdGoogle: calendar_create.data.Id_Google

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })

          setUser(create_app.data)
          console.log(create_app.data)
          setTimeout(() => {setCreated(true)},2500)
          setSucc('Appuntamenti creati con successo, a breve la pagina verrà ricaricata.')} catch (err) { setErr(err.message)}
        }
        create()} catch (err){
          setErr(err.message)
        }
        }
        calendar()

        }


      } else if (tipoRipetizione === '2settimane') {
        //Sono in ogni 2 settimane
        //Inserisco appuntamento di Oggi


        axios.get(`${window.$apiEndpoint}/calendar/create-event/${codice_cliente}/${trattamento_html_2}/${data_starDate_Google}/${data_endDate_Google}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(res => {
          axios.post(`${window.$apiEndpoint}/appoiments/create`, {
            codice_cliente: codice_cliente,
            startDate: startDate,
            endDate: endDate,
            trattamento: trattamento,
            IdGoogle: res.data.Id_Google

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })
              .then(res => {
                  setUser(res.data)
                  console.log(res.data)
              }).catch(err => {
                setErr('Errore - ' + err.message)
              })
        })


        for (var i = 0; i < nRipetizione; i++) {
        //Incremento le date
        const d2s = dt2.setDate(dt2.getDate() + 14)
        var d2s2 = new Date(d2s).toISOString('it-IT').replace(/:00.000Z/, "");

        //console.log(dt)
        const d2e =  dte2.setDate(dte2.getDate() + 14)

        var d2e2 = new Date(d2e).toISOString('it-IT').replace(/:00.000Z/, "");



        //Inserisco Ripetitivi su Google Calendar
        //Genero le date come servono a Google Calendar
        const dataSelezionata2 = new Date(d2s2);

        const data_starDate_Google_4 = d2s2 + isDSTFuso(dataSelezionata2)
        const data_endDate_Google_4 = d2e2 + isDSTFuso(dataSelezionata2)

        const calendar = async() =>{
        try
        {
          const calendar_create = await axios.get(`${window.$apiEndpoint}/calendar/create-event/${codice_cliente}/${trattamento_html_2}/${data_starDate_Google_4}/${data_endDate_Google_4}`, {
          headers: {
            "x-access-token": cookies.token
          }
        })

        const create = async() => {
          try {



            //Genero le date come servono a Google Calendar
            const data_starDate_Google_5 = data_starDate_Google_4.slice(0, 16);
            const data_endDate_Google_5 = data_endDate_Google_4.slice(0, 16);


            const create_app = await axios.post(`${window.$apiEndpoint}/appoiments/create`, {
            codice_cliente: codice_cliente,
            startDate: data_starDate_Google_5,
            endDate: data_endDate_Google_5,
            trattamento: trattamento,
            IdGoogle: calendar_create.data.Id_Google

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })

          setUser(create_app.data)
          console.log(create_app.data)
          setTimeout(() => {setCreated(true)},2500)
          setSucc('Appuntamenti creati con successo, a breve la pagina verrà ricaricata.')} catch (err) { setErr(err.message)}
        }
        create()} catch (err){
          setErr(err.message)
        }
        }
        calendar()

        }


      } else if (tipoRipetizione === '4settimane') {
        //Sono in ogni 4 settimane
        //Inserisco appuntamento di Oggi


        axios.get(`${window.$apiEndpoint}/calendar/create-event/${codice_cliente}/${trattamento_html_2}/${data_starDate_Google}/${data_endDate_Google}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(res => {
          axios.post(`${window.$apiEndpoint}/appoiments/create`, {
            codice_cliente: codice_cliente,
            startDate: startDate,
            endDate: endDate,
            trattamento: trattamento,
            IdGoogle: res.data.Id_Google

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })
              .then(res => {
                  setUser(res.data)
                  console.log(res.data)
              }).catch(err => {
                setErr('Errore - ' + err.message)
              })
        })


        for (var i = 0; i < nRipetizione; i++) {
        //Incremento le date
        const d4s = dt2.setDate(dt2.getDate() + 28)
        var d4s2 = new Date(d4s).toISOString('it-IT').replace(/:00.000Z/, "");

        //console.log(dt)
        const d4e =  dte2.setDate(dte2.getDate() + 28)

        var d4e2 = new Date(d4e).toISOString('it-IT').replace(/:00.000Z/, "");



        //Inserisco Ripetitivi su Google Calendar
        //Genero le date come servono a Google Calendar
        const dataSelezionata2 = new Date(d4s2);

        const data_starDate_Google_5 = d4s2 + isDSTFuso(dataSelezionata2)
        const data_endDate_Google_5 = d4e2 + isDSTFuso(dataSelezionata2)

        const calendar = async() =>{
        try
        {
          const calendar_create = await axios.get(`${window.$apiEndpoint}/calendar/create-event/${codice_cliente}/${trattamento_html_2}/${data_starDate_Google_5}/${data_endDate_Google_5}`, {
          headers: {
            "x-access-token": cookies.token
          }
        })

        const create = async() => {
          try {



            //Genero le date come servono a Google Calendar
            const data_starDate_Google_6 = data_starDate_Google_5.slice(0, 16);
            const data_endDate_Google_6 = data_endDate_Google_5.slice(0, 16);


            const create_app = await axios.post(`${window.$apiEndpoint}/appoiments/create`, {
            codice_cliente: codice_cliente,
            startDate: data_starDate_Google_6,
            endDate: data_endDate_Google_6,
            trattamento: trattamento,
            IdGoogle: calendar_create.data.Id_Google

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })

          setUser(create_app.data)
          console.log(create_app.data)
          setTimeout(() => {setCreated(true)},2500)
          setSucc('Appuntamenti creati con successo, a breve la pagina verrà ricaricata.')} catch (err) { setErr(err.message)}
        }
        create()} catch (err){
          setErr(err.message)
        }
        }
        calendar()

        }


      }

    }

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
        // redirect to home if signed up
        return <Redirect to={'/add-appointments'} />;
        //return window.location.reload();
    }

    //Quando Seleziono

   const handleChange = selectedOption => {
      setSelectedOption(selectedOption.value);
      setSelectedOptionLabel("| Cliente: " + selectedOption.label);
    };

    //Quando seleziono la ricorrenza
    const handleChange1 = selectedOption1 => {
       setSelectedOption1(selectedOption1.value);
       setSelectedOptionLabel1(selectedOption1.label);
     };

     const handleChange_2 = (value) => {
       function addHoursToDate(date, hours) {
         return new Date(new Date(date).setHours(date.getHours() + hours));
       }
        const dataSelezionata = new Date(value);

        const controllo = isDST(dataSelezionata)

        if (controllo === true) {
        //Siamo nell'ora legale
        const dataSelezionata = new Date(value);
        const dataPreselezionata = addHoursToDate(dataSelezionata, 3)
        setSelectedOption_2(dataPreselezionata.toISOString().slice(0,-8))
        } else {
        //Siamo nell'ora solare
        const dataSelezionata = new Date(value);
        const dataPreselezionata = addHoursToDate(dataSelezionata, 2)
        setSelectedOption_2(dataPreselezionata.toISOString().slice(0,-8))
        }

      };

    return (
        <>
            <SectionTitle title="Inserisci" subtitle="Crea nuovo appuntamento" />
                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                                    <Col xs={6}>
                                    <Form.Group  controlId="cliente" >
                                    <Form.Label>Seleziona Cliente:</Form.Label>

                                    {loading ?
                                    <Select value={selectedOption} onChange={handleChange} options={allUsers} />
                                    : <center><Spinner size="sm" animation="border" variant="info" /></center>
                                    }
                                    <pre className="mt-2">Codice Cliente Selezionato: <strong>{selectedOption}</strong> {selectedOptionLabel} </pre>

                                    </Form.Group>
                                    </Col>


                                    <Col>

                                    <Form.Group  controlId="startDate" >
                                    <Form.Label>Orario di Arrivo</Form.Label>
                                    <Form.Control type="datetime-local" onChange={(e) => handleChange_2(e.target.value)} name="startDate" ref={register({ required: true })}/>
                                    </Form.Group>

                                    </Col>


                                     <Col>

                                    <Form.Group  controlId="endDate" >
                                    <Form.Label>Orario di partenza</Form.Label>
                                    <Form.Control type="datetime-local" name="endDate" defaultValue={selectedOption_2} ref={register({ required: true })} />
                                    </Form.Group>

                                    </Col>

                                    </Form.Row>
                                    <Form.Row>
                                    <Col>
                                    <Form.Group controlId="tipo_ripetizione">
                                    <Form.Label>Tipo Ricorrenza</Form.Label>
                                    <Select value={selectedOption1} onChange={handleChange1} options={ricorrenza} />
                                    <pre className="mt-2">Ricorrenza Selezionata: {selectedOptionLabel1} </pre>

                                    </Form.Group>
                                    </Col>
                                    <Col>
                                    <Form.Group controlId="nRipetizione" >
                                    <Form.Label>Numero ripetizione</Form.Label>
                                    <Form.Control type="text" name="nRipetizione" ref={register({ required: true })}/>
                                    </Form.Group>



                                        </Col>
                                </Form.Row>

                                <Form.Row>


                                    <Form.Group  controlId="trattamento" as={Col} >
                                    <Form.Label>Trattamento</Form.Label>
                                    <JoditEditor  name="trattamento" value={content} config={config} ref={editor} tabIndex={1} onBlur={newContent => setContent(newContent.target.innerHTML)} onChange={newContent => {}}/>
                                    </Form.Group>



                                </Form.Row>
                                {errors.startDate && <p className="my-2" style={{  fontWeight: 'bold', color: '#f15a24' }}>Tutti i campi sono obbligatori</p>}
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>

                                <Button variant="info" size="md" type="submit" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    Crea Appuntamento Ripetitivo
                                </Button>
                                    </Form>
                        </div>

                                </Col>

                            </Row>



                        </Container>

        </>
    )
}
export default AddApp
