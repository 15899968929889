import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";


const DelApp = () => {

    const editor = useRef(null)


    //Setto gli state
    const [allUsers, viewUsers] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [isCreated, setCreated] = useState(false)
    const [showSucc, setSucc] = useState('')
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);
    const [selectedOptionTrat, setSelectedOptionTrat] = useState(null);
    const [selectedOptionDate, setSelectedOptionDate] = useState(null);
    const [selectedOptionTs, setSelectedOptionTs] = useState(null);
    const [selectedOptionTe, setSelectedOptionTe] = useState(null);








	  const [content, setContent] = useState('')

	  const config = {
		readonly: false,
    height: 'auto',
    placeholder: '',
    language: 'it' // all options from https://xdsoft.net/jodit/doc/
	  }


      //Recupero i dati dal db
    useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/appoiments/fetchEApp`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(users.data)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()



    //Chiamata post per inserimento utente
    const onSubmit = data => {
        const codice_cliente = selectedOption;


        //Controllo se il codice cliente è selezionato
        if (!codice_cliente) {
          return setErr('Appuntamento non selezionato')
        }


        axios.get(`${window.$apiEndpoint}/calendar/delete-event/${codice_cliente}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(res => {
        axios.post(`${window.$apiEndpoint}/appoiments/delete`, {
          codice_cliente: codice_cliente,

        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
            .then(res => {
                  setUser(res.data)
                  setSucc('Appuntamento cancellato con successo, a breve la pagina verrà ricaricata.')
                  setTimeout(() => {setCreated(true)},2500)

            }).catch(err => {
              setErr('Erore - ' + err.message)
            })
          })
    }



    //Quando Seleziono

   const handleChange = selectedOption => {
      setSelectedOption(selectedOption.value);
      setSelectedOptionLabel(selectedOption.title);
      setSelectedOptionTrat(selectedOption.trattamento);
      setSelectedOptionDate(selectedOption.date);
      setSelectedOptionTs(selectedOption.startDate);
      setSelectedOptionTe(selectedOption.startEnd);
    };

    const cancella = () => {
      const confirmation = window.confirm('Sei sicuro di voler cancellare l\'appuntamento selezionato?')
      if(confirmation === true) {
        //Se si Invio il Submit
        setTimeout(() => {onSubmit()},1000)

      }
    }

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
      // redirect to home if signed up
      return <Redirect to={'/add-appointments'} />;
      //return window.location.reload();
  }
    return (
        <>
            <SectionTitle title="Cancella" subtitle="Cancella appuntamento" />
                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">
                        <Form onSubmit={handleSubmit(cancella)}>
                        <Form.Row>
                                    <Col xs={12}>
                                    <Form.Group  controlId="cliente" >
                                    <Form.Label>Seleziona Appuntamento:</Form.Label>

                                    {loading ?
                                    <Select value={selectedOption} onChange={handleChange} options={allUsers} />
                                    : <center><Spinner size="sm" animation="border" variant="info" /></center>
                                    }
                                    <pre className="mt-2">Appuntamento Selezionato: {selectedOptionLabel} </pre>
                                    <pre className="mt-0">Data: {selectedOptionDate} </pre>
                                    <pre className="mt-0">Orario Arrivo: {selectedOptionTs} </pre>
                                    <pre className="mt-0">Orario Partenza: {selectedOptionTe} </pre>
                                    <pre className="mt-0">Trattamento: {selectedOptionTrat} </pre>


                                    </Form.Group>
                                    </Col>
                                </Form.Row>


                                {errors.startDate && <p className="my-2"  style={{ fontWeight: 'bold', color: '#f15a24' }}>Tutti i campi sono obbligatori</p>}
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>

                                <Button variant="info" size="md" type="submit" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    Cencalla Appuntamento
                                </Button>
                                    </Form>
                        </div>

                                </Col>

                            </Row>



                        </Container>

        </>
    )
}
export default DelApp
