import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";
import ReactTooltip from 'react-tooltip';
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus ,faFileInvoice, faCalendarWeek, faCalendarDay, faCalendarAlt, faCalendar, faSearch, faInfo, faCircle, faFileAlt, faWindowClose, faPaperPlane, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pdf from "react-to-pdf";
import {useLocation} from 'react-router-dom'
import Logo from '../dist/img/logo.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Toast from 'react-bootstrap/Toast';

import {Helmet} from "react-helmet";
import ScriptTag from 'react-script-tag';
import Card from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'


import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from '../components/utils'




const Bridge = (props) => {

  //Setto gli stati
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors, reset } = useForm();
  const { register: register2 , handleSubmit: handleSubmit2 , reset:reset2, formState: { errors: errors2 } } = useForm();
  const [loadingButton, setButton] = useState(true)
  const [loadingAllert, setAllert] = useState(false)
  const [allData, setData] = useState([{}])

  const [prewImp, setPrewImp] = useState('')
  const [prewTasse, setPrewTasse] = useState('')
  const [prewAll, setPrewAll] = useState('')
  const [prewAll2, setPrewAll2] = useState('')
  const [allApps, viewApps] = useState([])
  const [showErr, setErr] = useState('')
  const [showErr1, setErr1] = useState('')
  const [showSucc, setSucc] = useState('')
  const [allSettings, viewSettings] = useState('')
  const [showB, setShowB] = useState(false);
  const [showB2, setShowB2] = useState(false);
  const [loadingButtonProsegui, setButtonProsegui] = useState(true)
  const [loadingPagamento, setloadingPagamento] = useState(false)
  const [statoTransazione, setstatoTransazione] = useState('')
  //State per il pagamento
  const [cvc, setcvc] = useState('')
  const [expiry, setexpiry] = useState('')
  const [focused, setfocused] = useState('')
  const [name, setname] = useState('')
  const [number, setnumber] = useState('')
  const [issuer, setissuer] = useState('')
  const [formData, setformData] = useState(null)
  const [showButtonPayment, setButtonPayment] = useState(true);
  const [showCreditCard, setCreditCard] = useState(false);
  const [idPagamentoSumUp, setidPagamentoSumUp] = useState('')
  const [showErrPagamento, setErrPagamento] = useState('')
  const [showAppuntamenti, setAppuntamenti] = useState('')
  const history = useHistory()
  const [showEdit, setshowEdit] = useState(true);
  const [showFinal, setshowFinal] = useState(false);












  //Recupero Token di Accesso
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  //Eseguo il Controllo
  useEffect(() => {
    let mounted = true
    //Controllo che il token sia corretto
    if (token == `${process.env.REACT_APP_TOKEN}`) {
    //E' uguale utente autorizzato
    const fetch = async () => {
      const allSet = await axios.get(`${window.$apiEndpoint}/payment/fetch-aliquote-fatture`, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
        }
      });
      try {
        if(mounted){
          viewSettings(allSet.data[0])
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();




    } else {
    //Toekn Errato untente non autorizzato
    setLoading(false)

    }
    return () => {
      mounted = false
    }
  }, [])


  //Onsubmit Form
  const onSubmit = data => {
      const codice_fiscale = data.codice_fiscale.toUpperCase().trim( );

      //Richiamo Endpoint e Controllo se il Codice fiscale

      var config = {
      method: 'get',
      url: `${window.$apiEndpoint}/payment/fetch/${codice_fiscale}`,
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
      }
      };

      axios(config)
      .then(function (response) {


        if (response.data[0].status === true) {

          //Controllo se tutti i dati del cliente sono corretti

                        var config2 = {
                        method: 'get',
                        url: `${window.$apiEndpoint}/payment/fetchData/${response.data[0].codice_cliente}`,
                        headers: {
                          'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
                        },
                      };

                      axios(config2)
                      .then(function (response2) {
                        console.log(JSON.stringify(response2.data));

                        //Controllo la risposta

                        if (response2.data === true) {
                        //Dati clienti completi procedo
                        //Setto lo state
                        setButton(false)
                        setData(response.data[0])
                        setShowB(true)
                        } else {
                        //Dati clienti non completi
                        setshowEdit(false)
                        }

                      })
                      .catch(function (error2) {
                        console.log(error2);
                      });



        } else {
          //Setto lo state
          setButton(true)
          setAllert(true)
          setTimeout(() =>   setAllert(false), 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

      reset();

  }


  //Bottone tutti
  const tutti = (codice_c) => {

    setPrewImp('')
    setPrewTasse('')
    setPrewAll('')
    setPrewAll2('')

    const id = codice_c

    //Seleziono cliente

    axios.get(`${window.$apiEndpoint}/payment/fetch-apps-all/${id}`, {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_BARER}`
      }
    }).then(res => {
      viewApps(res.data)

      const risultati = res.data;

      console.log(risultati.length)

      //Se l'array e vuoto stampo notifica
      if (risultati.length > 0) {
      //Ci sono degli appuntamenti
      } else {
      //Non ci sono degli appuntamenti
      setAppuntamenti('REGOLARE. I pagamenti risultano regolari! Non ci sono altri appuntamenti da saladare. Grazie')
      }


      const prev_imponibile = [];
      const prev_tasse = [];
      //Creo la chiamata per recuperare la somma degli appuntamenti

      const app = risultati.map((item,index) => {
      //Setto le variabili sul map
      const IdAppuntamneto = item[5];


      axios.get(`${window.$apiEndpoint}/payment/fetch-un-single/${IdAppuntamneto}`, {
        headers: {
          "Authorization": `Bearer ${process.env.REACT_APP_BARER}`
        }
      }).then(risposta => {

        const aliquota_intera = (allSettings.aliquota)
        const aliquota = (allSettings.aliquota / 100)

        const imponibile = risposta.data[0].NonPagato
        const tasse = (risposta.data[0].NonPagato * aliquota)

        const imponibile_def = Math.round(imponibile * 100) / 100
        const tasse_def = Math.round(tasse * 100) / 100



        //Creo un array per imponibile e tasse
        //Eseguo la preview del totale

        //Eseguo il push dei risultati in un nuovo array
        prev_imponibile.push(imponibile_def)
        prev_tasse.push(tasse_def)

        //Sommo gli Array
        var sum_prev_imponibile = prev_imponibile.reduce(function(a, b){
        return a + b;
        }, 0);

        const sum_prev_imponibile_2 = Math.round(sum_prev_imponibile * 100) / 100

        var sum_prev_tasse = prev_tasse.reduce(function(a, b){
        return a + b;
        }, 0);

        const sum_prev_tasse_2 = Math.round(sum_prev_tasse * 100) / 100
        //Totale preventivo

        const totaleAllPrev = (sum_prev_imponibile_2 + sum_prev_tasse_2)



        setPrewImp(sum_prev_imponibile_2 + " €")
        setPrewTasse(sum_prev_tasse_2 + " €")
        setPrewAll(totaleAllPrev + " €")
        setPrewAll2(totaleAllPrev)
        setButtonProsegui(false)
        setShowB(false)




    });

    });

    })
  };

  //Funzione per inviare notifica via Telefram al canale di Chinelab
  const InviaNotifica = (testo) => {

    //Cambio lo state del toast
    setTimeout(() =>   setShowB(false), 500);

    axios.post(`${window.$apiEndpoint}/payment/sendNotify`, {
      testo: testo,

    }, {
        headers: {
              "Authorization": `Bearer ${process.env.REACT_APP_BARER}`
        }
    })
        .then(res => {
            setShowB2(true)
            setTimeout(() =>   setShowB2(false), 4000);
        }).catch(err => {
          setErr('Errore - ' + err.message)
        })

  }

  //Funzione per stampae la data per esteso del giorno odierno
  var options = {'weekday': 'long', 'month': '2-digit', 'day': '2-digit', 'year': '2-digit'};
  var date = new Date().toLocaleString('it-IT', options);


const NuovaData = (st) => {
  var options = {'month': '2-digit', 'day': '2-digit', 'year': '2-digit'};
  var date = new Date(st).toLocaleString('it-IT', options);

  return date

}

const EffettuaPagamento = () => {

  //Setto gli stati correttamente
  setloadingPagamento(true)
  //setButtonPayment(false)
  //setCreditCard(true)

}

const PagamentoConCarta = (totale, cod_cli, nome, cognome, arrAppunta) => {


  //Recupero e creo array con gli id degli appuntamenti corretti
  const arrayData = []
  const map = arrAppunta.map((item,index) => {
  //Recupero Orario dalla data in minuti
  const id_appuntamenti = item[5]
  arrayData.push(id_appuntamenti)
  })


  //Creo Ordine di CheckOut su SumUp e sul nostro databse
  axios.post(`${window.$apiEndpoint}/payment/createCheckOutSumUpDb`, {

    tipo_pagamento: 2,
    importo_pagamento: totale,
    arr_appuntamenti: `${JSON.stringify(arrayData)}`,
    codice_cliente: cod_cli,
    descrizione_pagamento: `(WebApp Planner) - Cliente: ${nome} ${cognome}`,

  }, {
      headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_BARER}`
      }
  })
      .then(res => {
          //Successo creto con successo
          console.log(res.data)
          setidPagamentoSumUp(res.data.id_pagamento)
      }).catch(err => {
        setErr('Errore - ' + err)
      })



  //Setto gli stati correttamente
  setButtonPayment(false)
  setCreditCard(true)

}



//Funzioni per dati di inserimento carta
const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setissuer({ issuer })
      console.log({ issuer })
    }
  }

const handleInputFocus = ({ target }) => {
    setfocused(target.name)
  }

const handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value)
      setnumber(target.value)
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value)
      setexpiry(target.value)
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value)
      setcvc(target.value)
    } else if (target.name === 'name') {
      setname(target.value)

    }

  }

//Elaboro il pagamento
const onSubmitPayment = (data) => {

    //Setto Variabili
    const nome_carta = data.name.toUpperCase();
    const number = data.number.trim( );
    const expiry = data.expiry;
    const cvc = data.cvc;
    const id_pag = data.id_pag;

    var expiry_mese = expiry.split('/')[0];
    var expiry_anno = expiry.split('/')[1];
    var n_carta = nome_carta.split(' ')[0];
    var c_carta = nome_carta.split(' ')[1];

    console.log(n_carta)
    console.log(c_carta)
    console.log(`${number}`.trim( ))
    console.log(expiry_mese)
    console.log(expiry_anno)

    console.log(id_pag)


    //Invio il pagamento al Backend

  axios.post(`${window.$apiEndpoint}/payment/processSumUpPaymentCard`, {
    id_pagamento: id_pag,
    nome_carta: n_carta,
    cognome_carta: c_carta,
    numero_carta: `${number}`.trim( ),
    expiry_year: expiry_anno,
    expiry_month: expiry_mese,
    cvv: cvc
  }, {
      headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_BARER}`
      }
  })
      .then(res => {
          //Successo creto con successo
          console.log(res.data)

          if (res.data.code === 404) {
          //Errore nel processo
          //Controllo errore
          let result = res.data.detail.includes("card");
          if (result) {
          setErrPagamento('Dati carta non corretti! Si prega di reinserire nuovamente. Grazie')
          } else {
          setErrPagamento(res.data.detail)
          }
          } else {
          //Processo di pagamento elaborato capisco se non in 3ds oppure no
          console.log(res.data.pagamento_3ds)
          //https://planner.chinelab.it/3ds_payment?id_pagamento=d5dae66e-200a-43e0-a9e7-862e9101e7c9
          //oppure non 3DS
          //https://planner.chinelab.it/checkout?checkout_id=d5dae66e-200a-43e0-a9e7-862e9101e7c9

          const checkTypeofPaymant = res.data.pagamento_3ds;

          if (checkTypeofPaymant === true) {
          //Pagamento 3DS invio a pagina per il pagmaento 3ds
          history.push(`/3ds_payment?id_pagamento=${res.data.id_pagamento}`);

          } else {
          //Pagamento Normale invio direttamaente a Checkout
            history.push(`/checkout?checkout_id=${res.data.id_pagamento}`);
          }

          }



      }).catch(error => {

        console.log("Errore Server" + error)
      })



    //Resetto il form
    reset2();

  }


  //Funzione per generazione randomica di stringhe
  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  //Generazione pagamento in contanti
  const PagamentoContante = (totale, cod_cli, nome, cognome, arrAppunta) => {

  //Inserisco pagamento nel databse
  //Recupero e creo array con gli id degli appuntamenti corretti
  const arrayData = []
  const map = arrAppunta.map((item,index) => {
  //Recupero Orario dalla data in minuti
  const id_appuntamenti = item[5]
  arrayData.push(id_appuntamenti)
  })


  //Creo Ordine di CheckOut su SumUp e sul nostro databse
  axios.post(`${window.$apiEndpoint}/payment/createPaymentContante`, {

    importo_pagamento: totale,
    arr_appuntamenti: `${JSON.stringify(arrayData)}`,
    codice_cliente: cod_cli,

  }, {
      headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_BARER}`
      }
  })
      .then(res => {
          //Successo creto con successo
          console.log(res.data)

          //Invio Notifica all'amministartore
          InviaNotifica(`Il cliente <strong>${nome} ${cognome}</strong>, ha richiesto il pagamento via contante (TOTALE: <strong>${totale} euro</strong>), si prega di prestare attenzione e avvallarre da gestionale dopo aver incassato! ATTENZIONE SE NON VIENE FATTO NON VERRA ESPLETATA LA FATTURA (cod.cliente: <b>${cod_cli}</b>)`)

          //Stampo Messaggio
          setButtonPayment(false)
          setshowFinal(true)

      }).catch(err => {
        setErr('Errore - ' + err)
      })

  }


  const PagamentoPosFisico = (totale, cod_cli, nome, cognome, arrAppunta) => {

  //Inserisco pagamento nel databse
  //Recupero e creo array con gli id degli appuntamenti corretti
  const arrayData = []
  const map = arrAppunta.map((item,index) => {
  //Recupero Orario dalla data in minuti
  const id_appuntamenti = item[5]
  arrayData.push(id_appuntamenti)
  })


  //Creo Ordine di CheckOut su SumUp e sul nostro databse
  axios.post(`${window.$apiEndpoint}/payment/createPaymentPOS`, {

    importo_pagamento: totale,
    arr_appuntamenti: `${JSON.stringify(arrayData)}`,
    codice_cliente: cod_cli,

  }, {
      headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_BARER}`
      }
  })
      .then(res => {
          //Successo creto con successo
          console.log(res.data)

          //Invio Notifica all'amministartore
          InviaNotifica(`Il cliente <strong>${nome} ${cognome}</strong>, ha richiesto il pagamento via POS Fisico (TOTALE: <strong>${totale} euro</strong>), si prega di prestare attenzione e avvallarre da gestionale dopo aver incassato! ATTENZIONE SE NON VIENE FATTO NON VERRA ESPLETATA LA FATTURA (cod.cliente: <b>${cod_cli}</b>)`)

          //Stampo Messaggio
          setButtonPayment(false)
          setshowFinal(true)

      }).catch(err => {
        setErr('Errore - ' + err)
      })

  }


  // ./Pagina Pagamenti Automatici
  return (
    <>
    {loading ?
      <>

      {loadingPagamento ?
      <>
      {/* Sezione pagamento*/}
      <Container fluid  style={{backgroundColor: 'rgb(0, 150, 136)', height: '100vh'}}>

      <div className="d-flex justify-content-end pt-4 pe-4 position-relative">

      <Toast show={showB2} animation={true}>
          <Toast.Header>
            <FontAwesomeIcon icon={faCircle} color="green"/>
            <strong className="me-auto ml-2"> Informazioni</strong>
          </Toast.Header>
          <Toast.Body>Segnalazione inviata con successo, si prega di attendere il gestore che vi raggiungerà il prima possibile!
          </Toast.Body>
        </Toast>
        </div>


      <Row style={{backgroundColor: 'rgb(0, 150, 136)'}}>
      <Col md={12} style={{backgroundColor: 'rgb(0, 150, 136)'}}>
      <div  className="card border-left-warning shadow p-4 px-3 mb-5" style={{marginTop: '2vh'}} >
      <center><img src={Logo} alt="Logo" className="mt-4 pb-2" /></center>
      {showFinal ? <> <center><p style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>Procedura automatica Conclusa, si prega di attendere l'arrivo dell'incaricato! Potete chiudere la seguente pagina. Grazie</p></center></> : null}

      {/* Scelta tipo di Pagamento */}

      {showButtonPayment ? <div className="flex flex-column justify-content-center">
      <center><Button className="btn btn-info btn-md mt-3" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} onClick={() =>  PagamentoContante(prewAll2, allData.codice_cliente, allData.nome, allData.cognome, allApps)}>
        Pagamento in cassa (Contanti)
      </Button></center>
      <center><p className="mt-2" style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>Scegliendo questo tipo di pagamento una notifica verrà inviata all'amministrazione che ti raggiungerà per incassare il pagamento, in seguito riceverai copia della fattura per Email!</p></center>

      <center><Button className="btn btn-info btn-md mt-3" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} onClick={() =>  PagamentoPosFisico(prewAll2, allData.codice_cliente, allData.nome, allData.cognome, allApps)}>
        Pagamento in cassa (POS)
      </Button></center>
      <center><p className="mt-2" style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>Scegliendo questo tipo di pagamento una notifica verrà inviata all'amministrazione che ti raggiungerà per incassare il pagamento, in seguito riceverai copia della fattura per Email!</p></center>


      <center><Button className="btn btn-info btn-md mt-3" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} onClick={() => PagamentoConCarta(prewAll2, allData.codice_cliente, allData.nome, allData.cognome, allApps)}>
        Pagamento Online (Carta di credito)
      </Button></center>

      <center><p className="mt-2" style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>Scegliendo questo tipo di pagamento ti verranno richiesti i dati della tua Carta per saldare in conto in totale autonomia, alla fine della procedura riceverai la copia della fattura per Email!</p></center>

      </div> :
      null
     }



      {/* Form di pagamento con carta */}

      { showCreditCard ?
        <>

        <center><p style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>Dettaglio Pagamento</p></center>

        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Totale Imponibile</th>
              <th>Totale Tasse</th>
              <th>Totale da Saldare</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><strong>{prewImp}</strong></td>
              <td><strong>{prewTasse}</strong></td>
              <td><strong>{prewAll}</strong></td>
            </tr>
          </tbody>
        </Table>

       <center><p style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>Inserisci i dati della tua carta e clicca su procedi con il pagamento</p></center>

        <div key='Payment'>
        <div className='App-payment'>
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={(e) => handleCallback(e)}
            locale={{ valid: 'SCADE IL' }}
            placeholders={{ name: 'NOME COGNOME' }}
          />
          <form onSubmit={handleSubmit2(onSubmitPayment)} >
            <div className='form-group mt-4'>
              <small>Nome sulla carta:</small>

              <input
                type='text'
                name='name'
                className='form-control'
                placeholder='Nome e Cognome'
                pattern='[a-z A-Z-]+'
                required
                onChange={(e) => handleInputChange(e)}
                onFocus={(e) => handleInputFocus(e)}
                ref={register2()}
              />
            </div>
            <div className='form-group'>
              <small>Numero carta:</small>

              <input
                type='tel'
                name='number'
                className='form-control'
                placeholder='Numero Carta'
                pattern='[\d| ]{16,22}'
                maxLength='19'
                required
                onChange={(e) => handleInputChange(e)}
                onFocus={(e) => handleInputFocus(e)}
                ref={register2()}
              />
            </div>

            <div className='form-group'>
              <small>Scadenza:</small>

              <input
                type='tel'
                name='expiry'
                className='form-control'
                placeholder='Scade il'
                pattern='\d\d/\d\d'
                required
                onChange={(e) => handleInputChange(e)}
                onFocus={(e) => handleInputFocus(e)}
                ref={register2()}
              />
            </div>
            <div className='form-group'>
              <small>CVC (Codice di sicurezza):</small>

              <input
                type='tel'
                name='cvc'
                className='form-control'
                placeholder='CVC'
                pattern='\d{3}'
                required
                onChange={(e) => handleInputChange(e)}
                onFocus={(e) => handleInputFocus(e)}
                ref={register2()}
              />
            </div>
            <input type='hidden' name='issuer' value={issuer} />
            <input type='hidden' name='id_pag' value={idPagamentoSumUp} ref={register2()} />
            <center><Button className="btn btn-info btn-md mt-3 ml-2" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} type="submit">
              Procedi con il pagamento
            </Button></center>
          </form>
          <p style={{ marginTop: '2.5vh', fontWeight: 'bold', color: 'red' }}>{showErrPagamento}</p>

        </div>
      </div> </>: null }



      </div>
      </Col>
      </Row>
      </Container>


      </>



       : <> <Container fluid  style={{backgroundColor: 'rgb(0, 150, 136)', height: '100vh'}}>

      <div className="d-flex justify-content-end pt-4 pe-4 position-relative">

      <Toast show={showB} animation={true}>
          <Toast.Header>
            <FontAwesomeIcon icon={faCircle} color="green"/>
            <strong className="me-auto ml-2"> Informazioni</strong>
          </Toast.Header>
          <Toast.Body>Si prega di conttatare l'amministrazione cliccando il segunete bottone nel caso ci siano dati mancanti o non corretti!
          <center><Button className="btn btn-info btn-sm mt-3" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} onClick={() => InviaNotifica(`Il cliente <strong>${allData.nome} ${allData.cognome}</strong>, che sta effettuando la procedura per il pagamento automatico segnala una mancanza o una non correttezza dei suoi dati anagrafici, si prega di prestare assistenza! (cod.cliente: <b>${allData.codice_cliente}</b>)`)}>
            Invia notifica
          </Button></center>
          </Toast.Body>
        </Toast>
        </div>

        <div className="d-flex justify-content-end pt-4 pe-4 position-relative">

        <Toast show={showB2} animation={true}>
            <Toast.Header>
              <FontAwesomeIcon icon={faCircle} color="green"/>
              <strong className="me-auto ml-2"> Informazioni</strong>
            </Toast.Header>
            <Toast.Body>Segnalazione inviata con successo, si prega di attendere!
            </Toast.Body>
          </Toast>
          </div>


      <Row style={{backgroundColor: 'rgb(0, 150, 136)'}}>
      <Col md={12} style={{backgroundColor: 'rgb(0, 150, 136)'}}>
      <div  className="card border-left-warning shadow p-4 px-3 mb-5" style={{marginTop: '2vh'}} >
      <center><img src={Logo} alt="Logo" className="mt-4 pb-2" /></center>
      {loadingButton ? <center><strong style={{fontFamily: 'JosefinSans-SemiBold', fontSize: '3.1vh'}}>Benvenuto</strong><p style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>nella sezione dedicata ai pagamanti automatici, si prega di inserire il proprio Codice Fiscale. </p></center> : <center><strong style={{fontFamily: 'JosefinSans-SemiBold', fontSize: '3.1vh'}}>Informazioni</strong><p style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>controlla che tutte le informazioni siano corrette. </p></center>}


      {loadingButton ?
      <>
      {showEdit ?
      <>
      <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Label>CODICE FISCALE</Form.Label>
        <Form.Control type="text" name="codice_fiscale" ref={register()} placeholder="" required />
        <Form.Text className="text-muted">
          Inserisci il tuo codice fiscale.
        </Form.Text>
        {errors.codice_fiscale && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}

      </Form.Group>

      <center><Button className="btn btn-info btn-md" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} type="submit">
        Controlla
      </Button></center>
    </Form>
    </>
    :
    <>
    <center><p style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em', color: '#dc3545'}}>Spiacente alcuni dati presenti nella tua anagrafica non sono corretti. Invia una segnalazione all'incaricato per richiedere la modifica e in seguito riesegui il pagamento automatico!</p></center>
    <center><Button className="btn btn-info btn-md" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} onClick={() => InviaNotifica(`Il cliente che in questo momento sta effettuando la procedura per il pagamento automatico segnala una mancanza o una non correttezza dei suoi dati anagrafici, si prega di prestare assistenza!`)}>
     INVIA NOTIFICA DI SEGNALAZIONE
    </Button></center>
    </>
  }
    </>
    : null }

    {loadingButton ? null :
    <div>

    <Table responsive striped bordered hover>
      <thead>
        <tr>
          <th>Codice Cliente</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>{allData.codice_cliente}</strong></td>
        </tr>
      </tbody>
    </Table>

    <Table responsive striped bordered hover>
      <thead>
        <tr>
          <th>Nome Cognome</th>
          <th>Codice Fiscale</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{allData.nome} {allData.cognome}</td>
          <td><strong>{allData.codice_fiscale}</strong></td>
        </tr>
      </tbody>
    </Table>

    <Table responsive striped bordered hover>
      <thead>
        <tr>
          <th>Indirizzo</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{allData.indirizzo}</td>
        </tr>
      </tbody>
    </Table>
    <Table responsive striped bordered hover>
      <thead>
        <tr>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>{allData.email}</strong></td>
        </tr>
      </tbody>
    </Table>

    {loadingButtonProsegui ? <> <center><Button className="btn btn-info btn-md MT-3" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} onClick={() => tutti(allData.codice_cliente)}>
      PROSEGUI
    </Button></center>

    <center><p style={{ marginTop: '2.5vh', fontWeight: 'bold', color: 'green' }}>{showAppuntamenti}</p></center>


    </> : <center><p style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>Conteggi da saldare ad Oggi <strong>{date}</strong></p></center>}


    <p style={{ marginTop: '2.5vh', fontWeight: 'bold', color: 'red' }}>{showErr1}</p>
    <p style={{ marginTop: '2.5vh', fontWeight: 'bold', color: 'green' }}>{showSucc}</p>

    {loadingButtonProsegui ? null : <><Table responsive striped bordered hover>
      <thead>
        <tr>
          <th>Totale Imponibile</th>
          <th>Totale Tasse</th>
          <th>Totale da Saldare</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>{prewImp}</strong></td>
          <td><strong>{prewTasse}</strong></td>
          <td><strong>{prewAll}</strong></td>
        </tr>
      </tbody>
    </Table>

    <center><p style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>Lista appuntamenti</p></center>

    <Table responsive striped bordered hover>
    <thead>
      <tr>
        <th>Data App.</th>
        <th>Descrizione</th>
      </tr>
    </thead>

    {
      allApps.map((item, index) => {
            return(
              <>
              <tbody key={index+makeid(5)}>
              <tr key={index+makeid(5)}>
              <td key={index+makeid(5)}><strong>{NuovaData(item[0])} </strong> dalle {item[1]} alle {item[2]}</td>
              <td key={index+makeid(5)}><strong>{item[4]}</strong></td>
              </tr>
            </tbody>
              </>
            )
    })}

    </Table>

    <center><p style={{fontFamily: 'Raleway-Medium', fontSize: '1.1em'}}>Se qualcosa non è corretto invia notifica e attendi, se invece è tutto corretto procedi con il pagamento!</p></center>

    <div className="flex flex-row justify-content-center">
    <center><Button className="btn btn-info btn-md mt-3" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} onClick={() => InviaNotifica(`Il cliente <strong>${allData.nome} ${allData.cognome}</strong>, che sta effettuando la procedura per il pagamento automatico segnala una mancanza o una non correttezza dei suoi dati anagrafici o da saldare, si prega di prestare assistenza! (cod.cliente: <b>${allData.codice_cliente}</b>)`)}>
      Invia notifica
    </Button></center>

    <center><Button className="btn btn-info btn-md mt-3 ml-2" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} onClick={() => EffettuaPagamento()}>
      Prosegui con il pagamento
    </Button></center>

    </div>


    </>}


    </div>}


    {loadingAllert ? <><Alert  className="my-4" variant="warning"><strong>Codice Fiscale non trovato.</strong> Si prega di rivolgersi all'amministrazione!</Alert>
    <center><Button className="btn btn-info btn-sm mt-3" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} onClick={() => InviaNotifica(`Il cliente che sta effettuando la procedura per il pagamento automatico segnala la mancanza del proprio codice fiscale nel sistema, si prega di assistere!`)}>
      Invia notifica
    </Button></center></>

     : null }




      </div>
      </Col>
      </Row>
      </Container>
      </>
      }
      </>
     :
     <Container fluid className={'h-100'} style={{backgroundColor: 'rgb(0, 150, 136)'}}>
     <Row className="d-flex align-items-center">
     <Col md={12}>
     <center><img src={Logo} alt="Logo" className="mt-4 pb-2" /></center>
     <div  className="card border-left-warning mt-6 shadow h-100 p-4 px-3 ">
     <center><strong>Spiacente!!</strong> Non sei autorizzato ad accedere a questa pagina!</center>
     </div>
     </Col>
     </Row>
     </Container> }

    </>
  );
}

export default Bridge
