import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";
import ReactTooltip from 'react-tooltip';
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus ,faFileInvoice, faCalendarWeek, faCalendarDay, faCalendarAlt, faCalendar, faSearch, faInfo, faCircle, faFileAlt, faWindowClose, faPaperPlane, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pdf from "react-to-pdf";
import {useLocation} from 'react-router-dom'
import Logo from '../dist/img/logo.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Toast from 'react-bootstrap/Toast';

import {Helmet} from "react-helmet";
import ScriptTag from 'react-script-tag';
import Card from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from '../components/utils'




const Paymant_3DS = (props) => {

  //Setto gli stati
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors, reset } = useForm();
  const { register: register2 , handleSubmit: handleSubmit2 , reset:reset2, formState: { errors: errors2 } } = useForm();
  const [loadingButton, setButton] = useState(true)
  const [loadingAllert, setAllert] = useState(false)
  const [allData, setData] = useState([{}])
  const [allInput, setInput] = useState('')

  const [prewImp, setPrewImp] = useState('')
  const [prewTasse, setPrewTasse] = useState('')
  const [prewAll, setPrewAll] = useState('')
  const [prewAll2, setPrewAll2] = useState('')
  const [allApps, viewApps] = useState([])
  const [showErr, setErr] = useState('')
  const [showErr1, setErr1] = useState('')
  const [showSucc, setSucc] = useState('')
  const [allSettings, viewSettings] = useState('')
  const [showB, setShowB] = useState(false);
  const [showB2, setShowB2] = useState(false);
  const [loadingButtonProsegui, setButtonProsegui] = useState(true)
  const [loadingPagamento, setloadingPagamento] = useState(false)
  const [statoTransazione, setstatoTransazione] = useState('')
  //State per il pagamento
  const [cvc, setcvc] = useState('')
  const [expiry, setexpiry] = useState('')
  const [focused, setfocused] = useState('')
  const [name, setname] = useState('')
  const [number, setnumber] = useState('')
  const [issuer, setissuer] = useState('')
  const [formData, setformData] = useState(null)
  const [showButtonPayment, setButtonPayment] = useState(true);
  const [showCreditCard, setCreditCard] = useState(false);
  const [idPagamentoSumUp, setidPagamentoSumUp] = useState('')
  const [showErrPagamento, setErrPagamento] = useState('')
  const [showAppuntamenti, setAppuntamenti] = useState('')


  //Recupero Token di Accesso
  const search = useLocation().search;
  const id_Pagamento_Sumup = new URLSearchParams(search).get("id_pagamento");

  console.log(id_Pagamento_Sumup)

  //Eseguo il Controllo
  useEffect(() => {
    let mounted = true

    //Certo nel database i dati per creare il form dinamico per il pagamento 3DS
    //E' uguale utente autorizzato
    const fetch = async () => {
      const allSet = await axios.get(`${window.$apiEndpoint}/payment/fetch3dsPaymanet/${id_Pagamento_Sumup}`, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
        }
      });
      try {
        if(mounted){
          setData(allSet.data[0]);
          setInput(JSON.parse(allSet.data[0].payload));
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();

    return () => {
      mounted = false
    }
  }, [])


  //Funzione per generazione randomica di stringhe
  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // ./Pagina Pagamenti Automatici

  return (
    <>
    {loading ?
      <>

      <Container fluid className={'h-100'} style={{backgroundColor: 'rgb(0, 150, 136)'}}>
      <Row className="d-flex align-items-center">
      <Col md={12}>
      <center><img src={Logo} alt="Logo" className="mt-4 pb-2" /></center>
      <div  className="card border-left-warning mt-6 shadow h-100 p-4 px-3 ">

      {/* Genero il form dinamico per il pagamento 3ds e renvio l'untente */}

      <form
      name="autoSubmitForm3DS"
      action={allData.url}
      method={allData.method}
      >

      { //Mappo oggetto dinamico per creare il form con i dati di arrivo del pagamento
        Object.entries(allInput).map((item, index) => {
              return(
                <>
                <input
                  key={index}
                  type="hidden"
                  name={item[0]}
                  value={item[1]}
                />
                </>
              )
      })}

      <center>Si prega di attendere a breve verrai inoltrato sulla pagina della tua banca! Grazie</center>

      {/* Aggiungo l'invio automatico */}

      <ScriptTag type="text/javascript"
      dangerouslySetInnerHTML={{__html: `document.autoSubmitForm3DS.submit();`}}
      />

    </form>

      </div>
      </Col>
      </Row>
      </Container>

      </>
     :
     <Container fluid className={'h-100'} style={{backgroundColor: 'rgb(0, 150, 136)'}}>
     <Row className="d-flex align-items-center">
     <Col md={12}>
     <center><img src={Logo} alt="Logo" className="mt-4 pb-2" /></center>
     <div  className="card border-left-warning mt-6 shadow h-100 p-4 px-3 ">
     <center><strong>Spiacente!!</strong> Id pagamento non corretto o non presente!</center>
     </div>
     </Col>
     </Row>
     </Container> }

    </>
  );
}

export default Paymant_3DS
