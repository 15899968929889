import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form, Dropdown, Table } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";
import ReactTooltip from 'react-tooltip';
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus ,faFileInvoice, faCalendarWeek, faCalendarDay, faCalendarAlt, faCalendar, faSearch, faInfo, faFileAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pdf from "react-to-pdf";
import Logo from '../dist/img/logo.png'


const GenPdfAss = (props) => {



 //GET DATA FROM BACKEND - SETTING STATES
 const [key, setKey] = useState('details');
 const [allUsers, viewUsers] = useState({})
 const [allInvoice, viewInvoice] = useState([{}])
 const [allApp, viewApp] = useState([{}])
 const [dataCreated, viewdataCreated] = useState('')
 const [allImporto, viewImporto] = useState('')
 const [allTasse, viewTasse] = useState('')
 const [allTotale, viewTotale] = useState('')
 const [DFattura, setDFattura] = useState('')





 const [loading, setLoading] = useState(true)
 const [avatar, setAvatar] = useState('')
 const [deleted, setDeleted] = useState(false)
 const { register, handleSubmit, errors } = useForm();
 const cookies = parseCookies()
 const [showErr, setErr] = useState('')
 const [showSucc, setSucc] = useState('')
 const [showErr1, setErr1] = useState('')
 const [showSucc1, setSucc1] = useState('')
 const [tipologia, setTipologia] = useState(null)




 const ref = React.createRef();

 const options = {
  orientation: 'portrait',
  unit: 'pt',
  format: 'A4',
  scale: '4.1666'
};

 const anno = new Date().getFullYear();

 const arrayData = []


 var sumArray = function() {
  // Use one adding function rather than create a new one each
  // time sumArray is called
  function add(a, b) {
      return a + b;
  }

  return function(arr) {
      return arr.reduce(add);
  };
}();

var creoArray = function(str) {
  arrayData.push(str)
  const Totale = sumArray(arrayData)
  console.log(Totale)
  return (
    Totale
  )

}

 //Converto data in formato italiano
 function convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat)
  return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
}

//Recupero i dati dal db per paramentri Clienti
useEffect(() => {
  let mounted = true
  const id = props.match.params.id
  const fetch = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/appoiments/fetch-client/${id}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
      if(mounted){
        //Controllo se il cleinte è un cliente privato oppure un cliente aziendale
        let tipologia_cliente = users.data[0].tipo_cliente;
        if (tipologia_cliente === 0) {
          //Privato
        viewUsers(users.data[0])
        setTipologia(0)
      } else if (tipologia_cliente === 1){
        //Azienda
        viewUsers(users.data[0])
        setTipologia(1)

      } else {
        //Niente
      }


      }
    } catch (e) {
      console.log(e)
    }
    setLoading(true)
  }
  fetch();
  return () => {
    mounted = false
  }
}, [cookies.token])


//Recupero i dati dal db per paramentri Clienti
useEffect(() => {




  let mounted = true
  const id = props.match.params.id
  const n_fattura = props.match.params.n_fattura
  const anno_riferimento = props.match.params.anno_riferimento

  const fetch = async () => {
    const invoice = await axios.get(`${window.$apiEndpoint}/invoice/get-all-invoice-v2/${id}/${n_fattura}/${anno_riferimento}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
      if(mounted){

        viewApp(invoice.data)
        console.log(invoice.data)
        const data_fattura = invoice.data[0].created
        var data_fattura2 = data_fattura.slice(0, -14);
        setDFattura(invoice.data[0].descrizione_fattura)

        viewdataCreated(convertDate(data_fattura2))

        const items = [];
        const noOfItems=  invoice.data.length;

        for (let i=0; i<noOfItems;i++)
        {
          items.push(invoice.data[i].importo_netto)
        }

        const totale_importo_netto = sumArray(items)
        const totale_importo_netto_decimale = Math.round(totale_importo_netto * 100) / 100


        viewImporto(totale_importo_netto_decimale)


        const items2 = [];
        const noOfItems2=  invoice.data.length;

        for (let i=0; i<noOfItems2;i++)
        {
          items2.push(invoice.data[i].importo_tasse)
        }

        const totale_importo_tasse = sumArray(items2)
        const totale_importo_tasse_2 = Math.round(totale_importo_tasse * 100) / 100


        viewTasse(totale_importo_tasse_2)

        const totaleAll = (totale_importo_netto + totale_importo_tasse_2)

        const totale_fattura = Math.round(totaleAll * 100) / 100

        viewTotale(totale_fattura)


      }
    } catch (e) {
      console.log(e)
    }
    setLoading(true)
  }
  fetch();
  return () => {
    mounted = false
  }
}, [cookies.token])

  return (

    <>

    <SectionTitle title="Dettagli" subtitle="Fattura" />
    <center><pre style={{marginTop: '-2vh', fontSize: '1.3vh', width: '300px'}}><center>{allUsers.fname}_{allUsers.lname}_FATTURA_NUMERO_{allApp[0].n_fattura}</center></pre></center>

      { tipologia == 0 ?  <Container style={{marginTop: '-3vh', marginBottom: '7vh'}}>
      <Row className="justify-content-center">
      <Col md={9}>
      <div  className="card border-left-warning mt-6 shadow h-100 py-4 px-3">

      <div className="ml-3">
      <div  dpi={300} ref={ref} style={{fontFamily: "'Montserrat', sans-serif"}}>
      <div style={{height: '30px', marginTop: '1.9vh'}}></div>
      <div style={{height: '2.3px', backgroundColor: '#424242', width: '500px', marginBottom: '1vh', marginLeft:'4vh'}}></div>
      <Row>
      <Col xs={2}><img style={{ marginBottom: '1vh', marginLeft:'6vh'}} src={Logo} width="40%" alt="Chinelab"/></Col>
      <Col><span style={{ marginBottom: '1vh', marginLeft:'-3vh', position: 'absolute', marginTop:'-1.1vh'}} > <br/>dott. Gianluca Costa</span></Col>
      </Row>
      <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '1.5vh', fontSize: '1.1vh',}} >Chinesiologo Proffessionista di cui alla Legge4/2013</p>
      <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '0.9vh',}} >ISCRITTO ALL'UNIONE NAZIONALE CHINESIOLOGI CON IL N° 11927</p>

      <p style={{ marginBottom: '1vh', marginLeft:'43vh', marginTop: '-11vh'}} >Cliente:</p>
      <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} ><strong>{allUsers.fname} {allUsers.lname}</strong></p>
      <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} >{allUsers.strada}, {allUsers.civico} </p>
      <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} >{allUsers.comune} {allUsers.cap} ({allUsers.provincia}) </p>
      <p style={{ marginBottom: '0.2vh', marginLeft:'43vh'}} >CF: {allUsers.codice_fiscale}</p>



      <p  style={{ marginLeft:'43vh', marginTop: '2vh', }} ><strong>FATTURA N.</strong></p>

      <div className="mt-1" style={{height: '2.3px', backgroundColor: '#424242', width: '280px', marginBottom: '1vh', marginLeft:'43vh'}}></div>

      <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} >{allApp[0].n_fattura}/{anno}<span  style={{ marginLeft:'16vh'}}>{dataCreated}</span></p>


      <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '3vh'}} ><strong>DETTAGLIO FATTURA</strong></p>


      <div className="mt-1" style={{height: '2.3px', backgroundColor: '#424242', width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}></div>


      <div className="mt-1" style={{ width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}>


      <p style={{ marginBottom: '1vh', marginTop: '0.5vh', fontSize: '1.2vh'}} >{DFattura}</p>




                                          </div>



                                          <div className="mt-1" style={{ width: '300px', marginBottom: '1vh', marginLeft:'41vh'}}>


                                          <p style={{ marginBottom: '1vh', marginTop: '2.5vh'}} >Imponibile<span> <strong style={{ float:'right'}}>{allImporto} €</strong></span> </p>
                                          <p style={{ marginBottom: '1vh'}} >{allApp[0].descrizione_aliquota}  {allApp[0].aliquota} %<span> <strong style={{ float:'right'}}>{allTasse} €</strong></span> </p>

                                          <p style={{ marginBottom: '1vh', marginTop: '2vh'}} >Totale EUR:<strong style={{ float:'right'}}> {allTotale} €</strong></p>

                                          </div>




                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '8.5vh', fontSize: '1vh'}} ></p>
                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-1vh', fontSize: '1vh'}} ><br/></p>
                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-1vh',fontSize: '1vh'}} ></p>

                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1vh', marginTop: '1vh'}} ><strong>Coordinate Bancarie </strong></p>
                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1.2vh'}} >IT 55H 03062 34210 00000 10728 03</p>
                                          <div className="mt-3" style={{height: '2.3px', backgroundColor: '#424242', width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}></div>

                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1.1vh', marginTop: '1vh'}} >Gianluca Costa - Via Revello, 38/L - 12037 Saluzzo (CN) - Codice Fiscale: CSTGLC88S14I470T - Partita IVA: 03458770041</p>
                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '1.5vh', fontSize: '1vh'}} >Trattiamo i Vostri dati per fini amministrativi e contabili. Li tuteliamo rispetto la Privacy e a richiesta rimettiamo Informativa Completa <br/> ai sensi degli art. 13-14-15-16-17-18-19-20-21-22 del Regolamento UE 2016/679</p>



                                           </div>




      <Col className="mb-4">
      <center>
      <Pdf targetRef={ref}  options={options} filename={`${allUsers.fname}_${allUsers.lname}_FATTURA_NUMERO_${allApp[0].n_fattura}`}>
        {({ toPdf }) => <Button onClick={toPdf} className="mt-4 ml-4" variant="info" size="lg"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
       Scarica Fattura
       </Button>

        }
      </Pdf>

      </center>
      <center><a href={`/user-profile/${allUsers.codice_cliente}`}><Button variant="info" size="lg" className="mt-2 ml-4" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
      Ritorna al cliente
    </Button></a></center>
      </Col>

      </div>
      </div>

              </Col>

          </Row>

    </Container> : tipologia == 1 ?
    <Container style={{marginTop: '-3vh', marginBottom: '7vh'}}>
    <Row className="justify-content-center">
    <Col md={9}>
    <div  className="card border-left-warning mt-6 shadow h-100 py-4 px-3">

    <div className="ml-3">
    <div  dpi={300} ref={ref} style={{fontFamily: "'Montserrat', sans-serif"}}>
    <div style={{height: '30px', marginTop: '1.9vh'}}></div>
    <div style={{height: '2.3px', backgroundColor: '#424242', width: '500px', marginBottom: '1vh', marginLeft:'4vh'}}></div>
    <Row>
    <Col xs={2}><img style={{ marginBottom: '1vh', marginLeft:'6vh'}} src={Logo} width="40%" alt="Chinelab"/></Col>
    <Col><span style={{ marginBottom: '1vh', marginLeft:'-3vh', position: 'absolute', marginTop:'-1.1vh'}} > <br/>dott. Gianluca Costa</span></Col>
    </Row>
    <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '1.5vh', fontSize: '1.1vh',}} >Chinesiologo Proffessionista di cui alla Legge4/2013</p>
    <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '0.9vh',}} >ISCRITTO ALL'UNIONE NAZIONALE CHINESIOLOGI CON IL N° 11927</p>

    <p style={{ marginBottom: '1vh', marginLeft:'43vh', marginTop: '-11vh'}} >Cliente:</p>
    <p style={{ marginBottom: '0.2vh', marginLeft:'43vh'}} ><strong>{allUsers.fname} {allUsers.lname}</strong></p>
    <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} ><strong>{allUsers.ragione_sociale}</strong></p>
    <p style={{ marginBottom: '0.2vh', marginLeft:'43vh'}} >{allUsers.strada}, {allUsers.civico} </p>
    <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} >{allUsers.comune} {allUsers.cap} ({allUsers.provincia}) </p>
    <p style={{ marginBottom: '0.2vh', marginLeft:'43vh'}} >Partita Iva: <strong>{allUsers.p_iva} </strong>| SDI: <strong>{allUsers.codice_sdi}</strong></p>
    <p style={{ marginBottom: '0.2vh', marginLeft:'43vh'}} >Indirizzo Pec: <strong>{allUsers.pec}</strong></p>



    <p  style={{ marginLeft:'43vh', marginTop: '2vh', }} ><strong>FATTURA N.</strong></p>

    <div className="mt-1" style={{height: '2.3px', backgroundColor: '#424242', width: '280px', marginBottom: '1vh', marginLeft:'43vh'}}></div>

    <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} >{allApp[0].n_fattura}/{anno}<span  style={{ marginLeft:'16vh'}}>{dataCreated}</span></p>


    <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-2.3vh'}} ><strong>DETTAGLIO</strong></p>


    <div className="mt-1" style={{height: '2.3px', backgroundColor: '#424242', width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}></div>


    <div className="mt-1" style={{ width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}>
                                        <Table table-sm>

                                          <tbody>
                                          {allApp.map((item,index) => {
                                          return (

                                            <tr>
                                              <td>{item.trattamento}</td>
                                              <td>{convertDate(item.data_giorno)}</td>
                                              <td>{item.startDate}</td>
                                              <td>{item.endDate}</td>
                                            </tr>
                                          )
                                        })}


                                          </tbody>
                                        </Table>
                                        </div>

                                        <div className="mt-1" style={{ width: '300px', marginBottom: '1vh', marginLeft:'41vh'}}>


                                        <p style={{ marginBottom: '1vh', marginTop: '2.5vh'}} >Imponibile<span> <strong style={{ float:'right'}}>{allImporto} €</strong></span> </p>
                                        <p style={{ marginBottom: '1vh'}} >{allApp[0].descrizione_aliquota}  {allApp[0].aliquota} %<span> <strong style={{ float:'right'}}>{allTasse} €</strong></span> </p>

                                        <p style={{ marginBottom: '1vh', marginTop: '2vh'}} >Totale EUR:<strong style={{ float:'right'}}> {allTotale} €</strong></p>

                                        </div>




                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '3.5vh', fontSize: '1vh'}} ></p>
                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-1vh', fontSize: '1vh'}} ><br/></p>
                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-1vh',fontSize: '1vh'}} ></p>

                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1vh', marginTop: '1vh'}} ><strong>Coordinate Bancarie </strong></p>
                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1.2vh'}} >IT 55H 03062 34210 00000 10728 03</p>
                                        <div className="mt-3" style={{height: '2.3px', backgroundColor: '#424242', width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}></div>

                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1.1vh', marginTop: '1vh'}} >Gianluca Costa - C.so XXVII Aprile, 8 - 12037 Saluzzo (CN) - Codice Fiscale: CSTGLC88S14I470T - Partita IVA: 03458770041</p>
                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '1.5vh', fontSize: '1vh'}} >Trattiamo i Vostri dati per fini amministrativi e contabili. Li tuteliamo rispetto la Privacy e a richiesta rimettiamo Informativa Completa <br/> ai sensi degli art. 13-14-15-16-17-18-19-20-21-22 del Regolamento UE 2016/679</p>



                                         </div>




    <Col className="mb-4">
    <center>
    <Pdf targetRef={ref}  options={options} filename={`${allUsers.fname}_${allUsers.lname}_FATTURA_NUMERO_${allApp[0].n_fattura}`}>
      {({ toPdf }) => <Button onClick={toPdf} className="mt-4 ml-4" variant="info" size="lg"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
     Scarica Fattura
     </Button>

      }
    </Pdf>

    </center>
    <center><a href={`/user-profile/${allUsers.codice_cliente}`}><Button variant="info" size="lg" className="mt-2 ml-4" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
    Ritorna al cliente
  </Button></a></center>
    </Col>

    </div>
    </div>

            </Col>

        </Row>

  </Container>

    :
    null
    }
  </>
)

}
export default GenPdfAss;
