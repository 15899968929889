import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";
import ReactTooltip from 'react-tooltip';
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus ,faFileInvoice, faCalendarWeek, faCalendarDay, faCalendarAlt, faCalendar, faSearch, faInfo, faFileAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from 'chart.js';






const Stats = () => {

  //GET DATA FROM BACKEND - SETTING STATES
  const [key, setKey] = useState('details');
  const [allUsers, viewUsers] = useState({})
  const [allApps, viewApps] = useState([{}])
  const [allAppsUn, viewAppsUn] = useState([{}])
  const [allInvoice, viewInvoice] = useState([{}])


  const history = useHistory()

  const [allUnInvoice, viewUnInvoice] = useState({})
  const [allPaInvoice, viewPaInvoice] = useState({})
  const [allMPaInvoice, viewMPaInvoice] = useState({})
  const [allLabel, viewLabel] = useState([])
  const [allRevenue, viewRevenue] = useState([])
  const [allTasse, viewTasse] = useState([])

  const [allPrewImp, setAllPrewImp] = useState([])
  const [allPrewMese, setAllPrewMese] = useState([])
  const [allPrewImpTasse, setAllPrewImpTasse] = useState([])
  const [allPrewMeseTasse, setAllPrewMeseTasse] = useState([])









  const [allSettings, viewSettings] = useState('')




  const [loading, setLoading] = useState(true)
  const cookies = parseCookies()
  const [showErr, setErr] = useState('')
  const [showSucc, setSucc] = useState('')
  const [showErr1, setErr1] = useState('')
  const [showSucc1, setSucc1] = useState('')



  //Recupero i dati dal db per le impostazioni settate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const allPreSet = await axios.get(`${window.$apiEndpoint}/invoice/fetch-paid-totale-preventivi`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllPrewImp(allPreSet.data[0].TotaleImponibile)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Recupero i dati dal db per le impostazioni settate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const allPreSetTasse = await axios.get(`${window.$apiEndpoint}/invoice/fetch-paid-totale-preventivi-tasse`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllPrewImpTasse(allPreSetTasse.data[0].TotaleImponibile)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Recupero i dati dal db per le impostazioni settate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const allPreSetMese = await axios.get(`${window.$apiEndpoint}/invoice/fetch-paid-totale-preventivi-mese`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllPrewMese(allPreSetMese.data[0].TotaleImponibile)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Recupero i dati dal db per le impostazioni settate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const allPreSetMeseTasse = await axios.get(`${window.$apiEndpoint}/invoice/fetch-paid-totale-preventivi-mese-tasse`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllPrewMeseTasse(allPreSetMeseTasse.data[0].TotaleImponibile)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])



  //Recupero i dati dal db per le impostazioni settate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const allSet = await axios.get(`${window.$apiEndpoint}/invoice/fetch-aliquote-fatture`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewSettings(allSet.data[0])
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])


  //Recupero i dati dal db per paramentri Fatture non pagate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const unInvoice = await axios.get(`${window.$apiEndpoint}/invoice/fetch-un-totale`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUnInvoice(unInvoice.data[0])

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Recupero i dati dal db per paramentri Fatture pagate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const paInvoice = await axios.get(`${window.$apiEndpoint}/invoice/fetch-paid-totale`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewPaInvoice(paInvoice.data[0])

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Conto le tasse sul pagato e non per sommare


  const aliquota_settata= (allSettings.aliquota / 100)
  const tasse_sul_pagato = (allPaInvoice.Pagato * aliquota_settata)
  const Totale_Pagato = (allPaInvoice.Pagato + tasse_sul_pagato)
  const Totale_Pagato_Decimali  = Math.round(Totale_Pagato * 100) / 100
  const tasse_sul_pagato_decimale  = Math.round(tasse_sul_pagato * 100) / 100

  const tasse_sul_non_pagato = (allUnInvoice.NonPagato * aliquota_settata)
  const Totale_Non_Pagato = (allUnInvoice.NonPagato + tasse_sul_non_pagato)
  const Totale_Non_Pagato_Decimali  = Math.round(Totale_Non_Pagato * 100) / 100
  const tasse_sul_non_pagato_decimale  = Math.round(tasse_sul_non_pagato * 100) / 100

  //Recupero i dati dal db per paramentri Fatture pagate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
    const unMInvoice = await axios.get(`${window.$apiEndpoint}/invoice/fetch-un-totale-mese`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewMPaInvoice(unMInvoice.data[0])

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()


  //Recupero i dati dal db per paramentri Fatture pagate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
    const label = await axios.get(`${window.$apiEndpoint}/invoice/stats-label`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewLabel(label.data)

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Recupero i dati dal db per paramentri Fatture pagate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
    const label = await axios.get(`${window.$apiEndpoint}/invoice/stats-revenue`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewRevenue(label.data)

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Recupero i dati dal db per paramentri Fatture pagate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
    const label = await axios.get(`${window.$apiEndpoint}/invoice/stats-tasse`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewTasse(label.data)

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()



  const tasse_sul_non_pagato_mensile = (allMPaInvoice.NonPagato * aliquota_settata)
  const Totale_Non_Pagato_mensile = (allMPaInvoice.NonPagato + tasse_sul_non_pagato_mensile)
  const Totale_Non_Pagato_Decimali_mensile  = Math.round(Totale_Non_Pagato_mensile * 100) / 100
  const tasse_sul_non_pagato_decimale_mensile  = Math.round(tasse_sul_non_pagato_mensile * 100) / 100

  const chartRef = useRef(null);

    useEffect(() => {
      if (chartRef.current) {
        const myChart = new Chart(chartRef.current, {
          type: "line",
          data: {
            labels: allLabel,
            order: 1,
            datasets: [
              {
                label: "Trend Fatturato",
                data: allRevenue,
                backgroundColor: [
                  "rgba(0, 150, 136, 0.4)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  "rgba(255, 159, 64, 0.2)"
                ],
                borderColor: [
                  "rgba(0, 150, 136, 0.9)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                  "rgba(255, 159, 64, 1)"
                ],
                borderWidth: 2
              },
              {
           label: 'Trend Fatturato Tasse',
           data: allTasse,
           type: 'line',
           // this dataset is drawn on top
           order: 2,
           backgroundColor: [
             "RGBA(253,118,118,0.8)",
             "RGBA(253,118,118,0.8)",
             "RGBA(253,118,118,0.8)",
             "RGBA(253,118,118,0.8)",
             "RGBA(253,118,118,0.8)",
             "RGBA(253,118,118,0.8)"
           ],
           borderColor: [
             "RGBA(253,118,118,1)",
             "RGBA(253,118,118,1)",
             "RGBA(253,118,118,1)",
             "RGBA(253,118,118,1)",
             "RGBA(253,118,118,1)",
             "RGBA(253,118,118,1)"
           ],
           borderWidth: 2
       }
            ]
          },
          options: {
            tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                let label = "Importo: " || '';

                label += Math.round(tooltipItem.yLabel * 100) / 100 + " €";
                return label;



              }
            }
          },
                  scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
            }
          }
        });
      }
    });

    return (
        <>
              <ReactTooltip />
            <SectionTitle title="Report" subtitle="Statistiche" />
                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">


                        <Row>

                        <Col><center>

                        <h6>Totale Fatturato ( <strong>{new Date().getFullYear()}</strong> )</h6></center>
                        <br />
                        <p>Totale Imponibile:  <span style={{color: 'green'}}> <strong> {allPaInvoice.Pagato} € </strong></span></p>
                        <p>Totale Tasse:  <span style={{color: 'red'}}> <strong> {tasse_sul_pagato_decimale} €</strong></span></p>



                        </Col>
                        <Col><center>

                        <h6>Totale Preventivi ( <strong>{new Date().getFullYear()}</strong> )</h6></center>
                        <br />
                        <p>Totale Imponibile:  <span style={{color: 'green'}}> <strong> {allPrewImp} € </strong></span></p>
                        <p>Totale Tasse:  <span style={{color: 'red'}}> <strong> {allPrewImpTasse} €</strong></span></p>



                        </Col>
                        <Col><center>

                        <h6><p>Totale da Fatturare ( <strong>{new Date().getFullYear()}</strong> )</p></h6></center>
                        <br />

                        <p>Totale Imponibile:  <span style={{color: 'green'}}> <strong> {allUnInvoice.NonPagato} €</strong></span></p>
                        <p>Totale Tasse:  <span style={{color: 'red'}}> <strong> {tasse_sul_non_pagato_decimale} €</strong></span></p>


                        </Col>
                        <Col><center>


                        <h6><p>Appuntamenti da fatturare (  <strong> { new Date().getMonth() + 1} / { new Date().getFullYear()}</strong> )</p></h6></center>
                        <br />

                        <p>Totale Imponibile:  <span style={{color: 'green'}}> <strong> {allMPaInvoice.NonPagato} €</strong></span></p>
                        <p>Totale Tasse:  <span style={{color: 'red'}}> <strong>{ tasse_sul_non_pagato_decimale_mensile } €</strong></span></p>



                      </Col>

                      <Col><center>


                      <h6><p>Appuntamenti preventivati (  <strong> { new Date().getMonth() + 1} / { new Date().getFullYear()}</strong> )</p></h6></center>
                      <br />

                      <p>Totale Imponibile:  <span style={{color: 'green'}}> <strong> {allPrewMese} €</strong></span></p>
                      <p>Totale Tasse:  <span style={{color: 'red'}}> <strong>{ allPrewMeseTasse } €</strong></span></p>



                    </Col>


                        </Row>

                        <Row className="mt-3">
                        <Col xs={12}>
                        <center><canvas height={80} ref={chartRef} /></center>
                        </Col>
                        </Row>



                        </div>

                                </Col>

                            </Row>



                        </Container>

        </>
    )
}
export default Stats
