import React, { useState, useEffect } from 'react'
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import SpringCalendar from '../lib';
import { faPlus, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';









const Addappointments = () => {

    //Setto gli state
    const [allEvents, viewEvents] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [isCreated, setCreated] = useState(false)
    const [showSucc, setSucc] = useState('')
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()

      //Recupero i dati dal db
    useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const events = await axios.get(`${window.$apiEndpoint}/appoiments/fetch`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewEvents(events.data)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])


    return (
        <>
            <SectionTitle title="Gestisci" subtitle="Calendario" />
            <ReactTooltip html={true} />

                <Container fluid >
                <Row style={{marginTop: '-7vh',}}className="mb-6">
                        <Col xs={12} >
                        <a href="/del-app" ><Button data-tip="Cancella Appuntamento"style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} className="float-right" variant="info" ><FontAwesomeIcon icon={faTrashAlt} /></Button></a>
                        <a href="/edit-app" ><Button data-tip="Modifica Appuntamento" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} className="float-right mx-2" variant="info" ><FontAwesomeIcon icon={faEdit} /></Button></a>
                        <a href="/add-app" ><Button data-tip="Aggiungi Appuntamento" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} className="float-right" variant="info" ><FontAwesomeIcon icon={faPlus} /> </Button></a>

                        </Col>

                            </Row>

                        <Row>
                        <Col xs={12}>
                        {loading ? <SpringCalendar locale="it" events={allEvents} color="#009688" defaultType="day"/> : <center><Spinner animation="border" /></center>}
                        </Col>

                            </Row>

         </Container>


        </>
    )
}
export default Addappointments
