import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";
import ReactTooltip from 'react-tooltip';
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus ,faFileInvoice, faCalendarWeek, faCalendarDay, faCalendarAlt, faCalendar, faSearch, faInfo, faCircle, faFileAlt, faWindowClose, faPaperPlane, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pdf from "react-to-pdf";
import {useLocation} from 'react-router-dom'
import Logo from '../dist/img/logo.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Toast from 'react-bootstrap/Toast';

import {Helmet} from "react-helmet";
import ScriptTag from 'react-script-tag';
import Card from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'


import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from '../components/utils'




const Checkout = (props) => {

  //Setto gli stati
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors, reset } = useForm();
  const { register: register2 , handleSubmit: handleSubmit2 , reset:reset2, formState: { errors: errors2 } } = useForm();
  const [loadingButton, setButton] = useState(true)
  const [loadingAllert, setAllert] = useState(false)
  const [allData, setData] = useState([{}])
  const [allInput, setInput] = useState('')

  const [prewImp, setPrewImp] = useState('')
  const [prewTasse, setPrewTasse] = useState('')
  const [prewAll, setPrewAll] = useState('')
  const [prewAll2, setPrewAll2] = useState('')
  const [allApps, viewApps] = useState([])
  const [showErr, setErr] = useState('')
  const [showErr1, setErr1] = useState('')
  const [showSucc, setSucc] = useState('')
  const [allSettings, viewSettings] = useState('')
  const [showB, setShowB] = useState(false);
  const [showB2, setShowB2] = useState(false);
  const [loadingButtonProsegui, setButtonProsegui] = useState(true)
  const [loadingPagamento, setloadingPagamento] = useState(false)
  const [statoTransazione, setstatoTransazione] = useState('')
  //State per il pagamento
  const [cvc, setcvc] = useState('')
  const [expiry, setexpiry] = useState('')
  const [focused, setfocused] = useState('')
  const [name, setname] = useState('')
  const [number, setnumber] = useState('')
  const [issuer, setissuer] = useState('')
  const [formData, setformData] = useState(null)
  const [showButtonPayment, setButtonPayment] = useState(true);
  const [showCreditCard, setCreditCard] = useState(false);
  const [idPagamentoSumUp, setidPagamentoSumUp] = useState('')
  const [showErrPagamento, setErrPagamento] = useState('')
  const [showAppuntamenti, setAppuntamenti] = useState('')
  const history = useHistory()


  //Recupero Token di Accesso
  const search = useLocation().search;
  const id_Pagamento_Sumup = new URLSearchParams(search).get("checkout_id");



  //Funzione per strippare stringa

function mysql_real_escape_string (str) {
  return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
      switch (char) {
          case "\0":
              return "\\0";
          case "\x08":
              return "\\b";
          case "\x09":
              return "\\t";
          case "\x1a":
              return "\\z";
          case "\n":
              return "\\n";
          case "\r":
              return "\\r";
          case "\"":
          case "'":
          case "\\":
          case "%":
              return "\\"+char; // prepends a backslash to backslash, percent,
                                // and double/single quotes
      }
  });
}

  //Eseguo il Controllo
  useEffect(() => {
    let mounted = true

    //Certo nel database i dati per creare il form dinamico per il pagamento 3DS
    //E' uguale utente autorizzato
    const fetch = async () => {
      const allSet = await axios.get(`${window.$apiEndpoint}/payment/fetchSumUpPaymanetStatus/${id_Pagamento_Sumup}`, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
        }
      });
      try {
        if(mounted){

          //Controllo se il pagamento è andato a buon fine
          console.log(allSet.data.check)
          const controllo = allSet.data.check;

          if (controllo === true) {

            InviaNotifica(`Notifica di avvenuto pagamento Online!`)

          //Pagamento confermato
          setShowB(true)



          //Procedo all'update degli appuntamenti e alla generazione della fattura e update pagamento nel Database | Valutare se inserire notifica avvenuto pagamento
          var config = {
          method: 'get',
          url: `${window.$apiEndpoint}/payment/updatePaymantSumUp/${id_Pagamento_Sumup}`,
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
          }
          };

         axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

        //Procedo nella elaborazione della fattura

                          var config3 = {
                          method: 'get',
                          url: `${window.$apiEndpoint}/payment/fetch-aliquote-fatture`,
                          headers: {
                              'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
                          },
                        };

                        axios(config3)
                        .then(function (response2) {
                          console.log(JSON.stringify(response2.data));




        //Recupero aliquota e numero fattura dai settaggi

        const numero_fattura_iniziale = response2.data[0].numero_fattura
        const aliquota_intera = (response2.data[0].aliquota)
        const aliquota = (response2.data[0].aliquota / 100)
        const descrizione_aliquota = mysql_real_escape_string(response2.data[0].descrizione_aliquota)

        //Creo numero di fattura incrementale

        const numero_fattura_incrementale = (numero_fattura_iniziale + 1)

        //Genero array id appuntamenti da Aggiornare come pagati nel database


        //Creo array di ID
        const arrayIdAppuntamenti = JSON.parse(response.data[0].arr_appuntamenti);


        //Aggiorno stato pagamento per Appuntamento pagato ciclo per tutti gli id presenti nella risposta (Ovvero quelli pagati)
        const app = arrayIdAppuntamenti.map((item,index) => {

        console.log(item)

        //Recupero non pagati

        axios.get(`${window.$apiEndpoint}/payment/fetch-un-single/${item}`, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
          }
        }).then(risposta => {

            //Setto Gli state per il non pagato
            const imponibile = risposta.data[0].NonPagato
            const tasse = (risposta.data[0].NonPagato * aliquota)




        axios.get(`${window.$apiEndpoint}/payment/update-app-invoice-New-Invoice/${item}`, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
          }
        }).then(res => {

            console.log('Appuntamenti Aggiornati con successo')

            console.log(res.data)

            const DatiFattura = res.data[0];

            //Recupero Risposta è Genero fattura

            console.log(imponibile)
            console.log(tasse)

            //Elabora dati per fattura
            var startDate = new Date(DatiFattura.startDate);
            var start = ((startDate.getHours() + ":" + startDate.getMinutes()))
            var endDate = new Date(DatiFattura.endDate);
            var end = ((endDate.getHours() + ":" + endDate.getMinutes()))


            //Ricavo la data
            var str1 = DatiFattura.startDate;
            var str2 = str1.slice(0, -6);


            const trat = DatiFattura.trattamento.replace(/<[^>]*>?/gm, '');
            const apps = [str2, start, end, item.tariffa, trat, item.id, item.codice_cliente]

            const dataGiorno = str2;
            const OrarioArrivo = start;
            const OrarioPartenza = end;
            const Tariffa = DatiFattura.tariffa;
            const Trattamento = trat;

            //Creo la fattura
            //Inserisco nel Databse tutti i dati per la fattura

            axios.get(`${window.$apiEndpoint}/payment/insert-app-invoice-SumUp/${numero_fattura_iniziale}/${imponibile}/${tasse}/${DatiFattura.codice_cliente}/${item}/${aliquota_intera}/${dataGiorno}/${Trattamento}/${OrarioArrivo}/${OrarioPartenza}/${descrizione_aliquota}/${id_Pagamento_Sumup}`, {
              headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
              }
            }).then(res => {

              //Fattura creata con successo
              console.log('FATTURA CREATA CON SUCCESSO')

              //Aggiorno numero Fattura nel Database

              //Eseguo update su tabella settings per id fattura

              axios.get(`${window.$apiEndpoint}/payment/update-settings-invoice/${numero_fattura_incrementale}`, {
                headers: {
                  'Authorization': `Bearer ${process.env.REACT_APP_BARER}`
                }
              }).then(res => {
               console.log('NUMERO FATTURA AGGIONRATO CON SUCCESSO')

               const annoFattura2 = new Date(`${DatiFattura.startDate}`);
               const annoFattura = annoFattura2.getFullYear();





              })
            })




        })

          })

        });



        })
        .catch(function (error) {
          console.log(error);
        });

      })
      .catch(function (error) {
        console.log(error);
      });



          } else {
          //Pagamento non confermato reinvio alla schermata di pagamento
          setShowB(false)
          }

          //Setto il per il pagamneto
          setLoading(true)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();

    return () => {
      mounted = false
    }
  }, [])


  //Funzione per generazione randomica di stringhe
  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // ./Pagina Pagamenti Automatici

  const riprova = () => {
    history.push(`/bridge?token=05fglqdGE?PU%22KU%3E_?irVju~`);
  }

  //Funzione per inviare notifica via Telefram al canale di Chinelab
  const InviaNotifica = (testo) => {


    axios.post(`${window.$apiEndpoint}/payment/sendNotify`, {
      testo: testo,

    }, {
        headers: {
              "Authorization": `Bearer ${process.env.REACT_APP_BARER}`
        }
    })
        .then(res => {
        }).catch(err => {
          setErr('Errore - ' + err.message)
        })

  }

  return (
    <>
    {loading ?
      <>

      {showB ?
      <>
      <Container fluid className={'h-100'} style={{backgroundColor: 'rgb(0, 150, 136)'}}>
      <Row className="d-flex align-items-center">
      <Col md={12}>
      <center><img src={Logo} alt="Logo" className="mt-4 pb-2" /></center>
      <div  className="card border-left-warning mt-6 shadow h-100 p-4 px-3 ">


      <center><strong>Congratulazioni!!</strong> Il pagamento è andato a buon fine! Grazie. Si prega di chiudere la finestra.</center>



      </div>
      </Col>
      </Row>
      </Container>
      </>
      :
      <>
      <Container fluid className={'h-100'} style={{backgroundColor: 'rgb(0, 150, 136)'}}>
      <Row className="d-flex align-items-center">
      <Col md={12}>
      <center><img src={Logo} alt="Logo" className="mt-4 pb-2" /></center>
      <div  className="card border-left-warning mt-6 shadow h-100 p-4 px-3 ">


      <center><strong>Spiacente!!</strong> Il pagamento non è andato a buon fine si prega di riprovare cliccando sul seguente bottone!</center>

      <center><Button className="btn btn-info btn-md MT-3" style={{backgrounCcolor: 'rgb(0, 150, 136)', borderColor: 'rgb(0, 150, 136)', fontWeight: 600}} onClick={() => riprova()}>
      RIPROVA
      </Button></center>



      </div>
      </Col>
      </Row>
      </Container>
      </>
      }

      </>
     :
     <Container fluid className={'h-100'} style={{backgroundColor: 'rgb(0, 150, 136)'}}>
     <Row className="d-flex align-items-center">
     <Col md={12}>
     <center><img src={Logo} alt="Logo" className="mt-4 pb-2" /></center>
     <div  className="card border-left-warning mt-6 shadow h-100 p-4 px-3 ">
     <center><strong>Spiacente!!</strong> Id pagamento non corretto o non presente!</center>
     </div>
     </Col>
     </Row>
     </Container> }

    </>
  );
}

export default Checkout
