import React, { useState, useEffect } from 'react'
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";



const AddUserAdmin = () => {

    //Setto gli state
    const [allUsers, viewUsers] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [isCreated, setCreated] = useState(false)
    const [showSucc, setSucc] = useState('')
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()

      //Recupero i dati dal db
    useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/users/fetch`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(users.data)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()



    //Chiamata post per inserimento utente
    const onSubmit = data => {
        const fname = data.fname.toUpperCase().trim();
        const lname = data.lname.toUpperCase().trim();
        const email = data.email.toLowerCase().trim( );
        const password = data.password;


        axios.post(`${window.$apiEndpoint}/auth/register`, {
            fname: fname,
            lname: lname,
            email: email,
            password: password,
        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
            .then(res => {
                setUser(res.data)
                setTimeout(() => {setCreated(true)},3000)
                setSucc('Utente creato con successo, a breve la pagina verrà ricaricata.')
            }).catch(err => {
              setErr('L\'indirizzo email risulta essere già esistente - ' + err.message)
            })
    }

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
        // redirect to home if signed up
        //return <Redirect to={'/'} />;
        return window.location.reload();
    }

    //Configuro le colonne della tabella
    const columns = ["Nome", "Cognome", "Email", "Ruolo"];
    const options = {
        filter: false,
        selectableRows: false,
        filterType: "dropdown",
        responsive: "stacked",
        rowsPerPage: 4,
        textLabels: {
            body: {
              noMatch: "Spiacenti, nessun record corrispondente trovato",
              toolTip: "Ordinare",
              columnHeaderTooltip: column => `Ordinare per ${column.label}`
            },
            pagination: {
                next: "Pagina successiva",
                previous: "Pagina Precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "of",
              },
              toolbar: {
                search: "Cerca",
                downloadCsv: "Scarica CSV",
                print: "Stampa",
                viewColumns: "Vedi Collonne",
                filterTable: "Tabella dei filtri",
              },
              filter: {
                all: "Tutto",
                title: "FILTRI",
                reset: "RIPRISTINA",
              },
              viewColumns: {
                title: "Mostra colonne",
                titleAria: "Mostra / Nascondi colonne tabella",
              },
        } 
    };

    const theme = createMuiTheme({
        overrides: {
                    MUIDataTableHeadCell:{
                  root: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '200',
          
                  },
                },
                MuiIconButton: {
                  root: {
                    color: '#424242',
          
                  },
                },
                MuiTableCell:{
                  body: {
                      backgroundColor: '#00968817',
            
                    },
                },
                MUIDataTableHeadCell: {
                  fixedHeader: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '200',
                    backgroundColor: ' white',
                  },
                
                }, 
          
                MuiButton: {
                  root: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '200',
                    backgroundColor: ' white',
                  },
                
                }, 
                MuiTypography: {
                  body2: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '200',
                    backgroundColor: ' white',
                  },
                
                }, 
                MuiMenuItem: {
                  root: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '200',
                    backgroundColor: ' white',
                  },
                
                }, 
                MuiTableCell: {
                  root: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '400',  
                }, 
              },
              MUIDataTableToolbar: {
                hover: {
                  color: '#616161',  
              }, 
            },
              },
      });

  

    return (
        <>
            <SectionTitle title="Inserisci" subtitle="Crea utente di Sistema" />
                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                                    
                                    

                                    <Form.Group  controlId="fname" as={Col} >
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control type="text" name="fname" ref={register({ required: true })} placeholder="Inserisci Nome" />
                                    {errors.fname && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                    <Form.Group  controlId="lname" as={Col} >
                                    <Form.Label>Cognome</Form.Label>
                                    <Form.Control type="text" name="lname" ref={register({ required: true })} placeholder="Inserisci Cognome" />
                                    {errors.fname && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                    
                                </Form.Row>

                                <Form.Row>

                                   
                                    <Form.Group  controlId="email" as={Col} >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name="email" ref={register({ required: true })} placeholder="Inserisci Email" />
                                    {errors.email && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                    <Form.Group  controlId="password" as={Col} >
                                    <Form.Label>Paaword</Form.Label>
                                    <Form.Control type="password" name="password" ref={register({ required: true })} placeholder="Inserisci Password" />
                                    {errors.password && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>
                                    
                                </Form.Row>
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>


                                <Button variant="info" size="md" type="submit" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    Crea Utente
                                </Button>
                               
                                    </Form>
                        </div>

                                </Col>

                            </Row>

                        </Container>

                      <Container fluid>
                      <Row>
                      <Col xs={12}>
                      <div className="card border-left-warning mt-2 shadow h-100 py-2 px-4">

                            <span style={{ fontSize: "2vh", fontWeight: "400", marginTop: '2vh',}}>Lista Utenti</span>
                            
                            <div  className="overflow-x-scroll lg:overflow-hidden">
                            {loading ? <MuiThemeProvider theme={theme}> 
                            <MUIDataTable 
                            title={""} 
                            data={allUsers} 
                            columns={columns} 
                            options={options} 
                        /> </MuiThemeProvider>: <center><Spinner animation="border" /></center>}
                        </div>
                              </div>

                              </Col>

                          </Row>
                        
                      </Container>

        </>
    )
}
export default AddUserAdmin
