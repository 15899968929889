import React, { useState, useEffect } from 'react'
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Select, { components } from 'react-select';
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';




const Settings = () => {



    //Setto gli state
    const [allAliquote, viewAliquote] = useState([{}])
    const [allAliquoteTable, viewAliquoteTable] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [isCreated, setCreated] = useState(false)
    const [showSucc, setSucc] = useState('')
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);
    
      //Recupero i dati dal db
      useEffect(() => {
        let mounted = true
        const fetch = async () => {
          const aliquote = await axios.get(`${window.$apiEndpoint}/invoice/fetchAliquote`, {
            headers: {
              "x-access-token": cookies.token
            }
          });
          try {
            if(mounted){
              viewAliquote(aliquote.data)
            }
          } catch (e) {
            console.log(e)
          }
          setLoading(true)
        }
        fetch();
        return () => {
          mounted = false
        }
      }, [cookies.token])
    
      console.log()

      //Recupero i dati dal db
      useEffect(() => {
        let mounted = true
        const fetch = async () => {
          const aliquote = await axios.get(`${window.$apiEndpoint}/invoice/fetch-aliquote-tabella`, {
            headers: {
              "x-access-token": cookies.token
            }
          });
          try {
            if(mounted){
              viewAliquoteTable(aliquote.data)
            }
          } catch (e) {
            console.log(e)
          }
          setLoading(true)
        }
        fetch();
        return () => {
          mounted = false
        }
      }, [cookies.token])
    
      console.log()



    //Chiamata post per inserimento utente
    const onSubmit = data => {
        const n_invoice = data.n_invoice;
        const aliquota_numero = selectedOption
        
        //Controllo se il codice cliente è selezionato
      if (!n_invoice) {   
        return (
          setErr('Numero fattura non selezionato'),
          setTimeout(() => {setErr('')},3000)
          )
      }

      if (!aliquota_numero) {   
        return (
          setErr('Aliquota non selezionata'),
          setTimeout(() => {setErr('')},3000)
          )
      }

        axios.post(`${window.$apiEndpoint}/invoice/set`, {
          n_invoice: n_invoice,
          aliquota_numero: aliquota_numero,
            
        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })

        axios.post(`${window.$apiEndpoint}/invoice/set-2`, {
          n_invoice: n_invoice,
          aliquota_numero: aliquota_numero,
            
        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
            .then(res => {
                setUser(res.data)
                setTimeout(() => {setCreated(true)},3000)
                setSucc('Impostazioni settate con successo, a breve la pagina verrà ricaricata.')
            }).catch(err => {
              setErr('Errore - ' + err.message)
            })
    }

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
        // redirect to home if signed up
        //return <Redirect to={'/'} />;
        return window.location.reload();
    }

    //Configuro le colonne della tabella
    const columns = ["Numero Fattura", "Aliquota %", "Descrizione Aliquota"];
    const options = {
        filter: false,
        selectableRows: false,
        filterType: "dropdown",
        rowsPerPage: 4,
        textLabels: {
            body: {
              noMatch: "Spiacenti, nessun record corrispondente trovato",
              toolTip: "Ordinare",
              columnHeaderTooltip: column => `Ordinare per ${column.label}`
            },
            pagination: {
                next: "Pagina successiva",
                previous: "Pagina Precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "of",
              },
              toolbar: {
                search: "Cerca",
                downloadCsv: "Scarica CSV",
                print: "Stampa",
                viewColumns: "Vedi Collonne",
                filterTable: "Tabella dei filtri",
              },
              filter: {
                all: "Tutto",
                title: "FILTRI",
                reset: "RIPRISTINA",
              },
              viewColumns: {
                title: "Mostra colonne",
                titleAria: "Mostra / Nascondi colonne tabella",
              },
        } 
    };

    const theme = createMuiTheme({
        overrides: {
                    MUIDataTableHeadCell:{
                  root: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '200',
          
                  },
                },
                MuiIconButton: {
                  root: {
                    color: '#424242',
          
                  },
                },
                MuiTableCell:{
                  body: {
                      backgroundColor: '#00968817',
            
                    },
                },
                MUIDataTableHeadCell: {
                  fixedHeader: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '200',
                    backgroundColor: ' white',
                  },
                
                }, 
          
                MuiButton: {
                  root: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '200',
                    backgroundColor: ' white',
                  },
                
                }, 
                MuiTypography: {
                  body2: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '200',
                    backgroundColor: ' white',
                  },
                
                }, 
                MuiMenuItem: {
                  root: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '200',
                    backgroundColor: ' white',
                  },
                
                }, 
                MuiTableCell: {
                  root: {
                    fontFamily: "'Raleway', sans-serif",
                    fontWeight: '400',  
                }, 
              },
              MUIDataTableToolbar: {
                hover: {
                  color: '#616161',  
              }, 
            },
              },
      });

   //Quando Seleziono

   const handleChange = selectedOption => {
    setSelectedOption(selectedOption.value);
    setSelectedOptionLabel(selectedOption.label);
    
  };

  const cencellaReport = () => {

  axios.delete(`${window.$apiEndpoint}/invoice/delete-report`, {
    headers: {
      "x-access-token": cookies.token
    }
  }).then(res => {
    return (
      setSucc('Appuntamenti cancellati con successo'),
      setTimeout(() => {setSucc('')},3000)
      )
  })

}


    return (
      
        <>
            <ReactTooltip />

            <SectionTitle title="Setta" subtitle="Impostazioni" />
                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Button className="mt-2 mb-3" data-tip="Per aggiungere nuove aliquote contattare l'amministratore di sistema" variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>

                        <FontAwesomeIcon className="mx-1" icon={faInfo} />
                        </Button>
                        <Button className="mt-2 mb-3 mx-1" data-tip="Attenzione, la modifica del numero di fattura farà ripartire la fatturazione" variant="info" size="md" style={{ backgroundColor: "#e57373", borderColor: "#e57373", fontWeight: '600'}}>
                        <FontAwesomeIcon className="mx-1" icon={faInfo} />
                        </Button>

                        <Button className="mt-2 mb-3 mx-1" onClick={() => cencellaReport()} data-tip="Cencalla Report" variant="info" size="md" style={{ backgroundColor: "#e57373", borderColor: "#e57373", fontWeight: '600'}}>
                        <FontAwesomeIcon className="mx-1" icon={faInfo} /> Cancella Report
                        </Button>

                        <Form.Row>
                                    

                                    <Form.Group  controlId="n_invoice" as={Col} >
                                    <Form.Label>Numero Fattura</Form.Label>
                                    <Form.Control type="text" name="n_invoice" ref={register({ required: true })} placeholder="Inserisci Numero Fattura" />
                                    <small style={{ marginTop: '1vh'}} >Inserire il numero fattura per iniziare a fatturare</small>
                                    {errors.n_invoice && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                    <Form.Group  controlId="aliquota" as={Col} >
                                    <Form.Label>Seleziona Aliquota</Form.Label>
                                    {loading ? 
                                    <Select value={selectedOption} onChange={handleChange} options={allAliquote} /> 
                                    : <center><Spinner size="sm" animation="border" variant="info" /></center>
                                    }
                                    <pre className="mt-2">Aliquota Selezionata: {selectedOptionLabel} </pre>
                                    </Form.Group>

                                    
                                </Form.Row>
                                <p style={{ marginTop: '1vh', fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                <p style={{ marginTop: '1vh', fontWeight: 'bold', color: 'green' }}>{showSucc}</p>


                                <Button variant="info" size="md" type="submit" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    Setta Impostazioni
                                </Button>
                               
                                    </Form>
                        </div>

                                </Col>

                            </Row>

                        </Container>

                      <Container fluid>
                      <Row>
                      <Col xs={12}>
                      <div className="card border-left-warning mt-2 shadow h-100 py-2 px-4">

                            <span style={{ fontSize: "2vh", fontWeight: "400", marginTop: '2vh',}}>Impostazioni attuali</span>
                            
                            <div  className="overflow-x-scroll lg:overflow-hidden">
                            {loading ? <MuiThemeProvider theme={theme}> 
                            <MUIDataTable 
                            title={""} 
                            data={allAliquoteTable} 
                            columns={columns} 
                            options={options} 
                        /> </MuiThemeProvider>: <center><Spinner animation="border" /></center>}
                        </div>
                              </div>

                              </Col>

                          </Row>
                        
                      </Container>

                      <Container fluid className="mb-4">
                      <Row>
                      <Col xs={12}>
                      <div className="card border-left-warning mt-6 mb-7 shadow h-100 py-2 px-4">

                            <span style={{ fontSize: "2vh", fontWeight: "400", marginTop: '2vh',}}>Paramentri Sincronizzazione Calendario</span>
                            
                            <h6 className="mt-4">Indirizzo Email</h6>
                            <p className="mt-2"> chinelab.calendar.project@gmail.com	</p>
                            <h6 className="mt-3">Password</h6>
                            <p className="mt-2">FXeapbqCQ5</p>
                            <h6 className="mt-3">Id Calendario</h6>
                            <p className="mt-2">chinelab.calendar.project@gmail.com</p>

                              </div>

                              </Col>

                          </Row>
                        
                      </Container>

        </>
    )
}
export default Settings
