import React, {useState, useEffect, useRef} from 'react'
import { useHistory } from 'react-router-dom'
import Section from '../components/dashboard/section'
import Select, { components } from 'react-select';
import {Spinner, Alert, Modal, Toast as ToastContainer, Toast, Dropdown, Tooltip, Popover, Collapse} from 'react-bootstrap'
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import MUIDataTable from 'mui-datatables';
import axios from 'axios'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Bar, Line } from 'react-chartjs-2'
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus, faFileInvoice, faPaperPlane, faInfoCircle, faEdit, faRedo, faWallet, faCreditCard, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button , Container, Row, Col} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { Redirect } from 'react-router-dom'
// import the react-json-view component
import ReactJson from 'react-json-view'
import 'react-alert-confirm/lib/style.css';
import AlertConfirm from 'react-alert-confirm';

const bodyScrollLock = require('body-scroll-lock');





const { parseCookies } = require('nookies');





const Pagamenti = (props) => {

  //Recupero anno da Url se è già impostato
  const annoSessione = props.match.params.id

  //Setto gli state
  const [allInvoice, viewInvoice] = useState([{}])
  const [allYears, setAllYears] = useState([{}])
  const [loading, setLoading] = useState(false)
  const [allCostumers, viewCostumers] = useState('')
  const [allUsersC, viewUsersC] = useState('')
  const [allApp, viewApp] = useState('')
  const cookies = parseCookies()
  const history = useHistory()
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);
  const [loadingAnno, setLoadingAnno] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [messaggioSDI, setmessaggioSDI] = useState([{}])
  const [modalShow1, setModalShow1] = useState(false);
  const [messaggioFatturaSDI, setmessaggioFatturaSDI] = useState([{}])
  const [modalShow2, setModalShow2] = useState(false);
  const [messaggioCopia, setmessaggioCopia] = useState([{}])
  const [allSettings, viewSettings] = useState('')

  const [ndaInviare, setndaInviare] = useState([{}])
  const [nInviate, setnInviate] = useState([{}])
  const [nInviateErrore, setnInviateErrore] = useState([{}])

  const [showToast, setToast] = useState(false)
  const [allToast, viewToast] = useState([{}])

  const [loop, setLoop] = useState();




//Setto Anno di defaut inserito in url

useEffect(() => {
  let mounted = true
  setSelectedOption(annoSessione);
  setSelectedOptionLabel(`Anno ${annoSessione}`);

  //Carico Dati
  const fetch = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/invoice/getNDigitali/${annoSessione}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        setndaInviare(users.data[0])

    } catch (e) {
      console.log(e)
    }
  }
  fetch();

  //Setto lo vista
  setLoadingAnno(true)

  return () => {
    mounted = false
  }
}, [cookies.token])

useEffect(() => {
  let mounted = true
  setSelectedOption(annoSessione);
  setSelectedOptionLabel(`Anno ${annoSessione}`);

  //Carico Dati
  const fetch = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/invoice/getNContanti/${annoSessione}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        setnInviate(users.data[0])

    } catch (e) {
      console.log(e)
    }
  }
  fetch();

  //Setto lo vista
  setLoadingAnno(true)

  return () => {
    mounted = false
  }
}, [cookies.token])


  //Recupero i dati dal db
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/invoice/fetchPagamentiAll/${annoSessione}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewInvoice(users.data)
          setLoading(true)
          //Setto lo vista
          setLoadingAnno(true)
        }
      } catch (e) {
        console.log(e)
      }

    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Recupero i dati dal db per appuntamenti totali
useEffect(() => {
  let mounted = true
  const fetch = async () => {
    const allSet = await axios.get(`${window.$apiEndpoint}/invoice/fetch-aliquote-fatture`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
      if(mounted){
        viewSettings(allSet.data[0])
      }
    } catch (e) {
      console.log(e)
    }
    setLoading(true)
  }
  fetch();
  return () => {
    mounted = false
  }
}, [cookies.token])

  //Recupero i dati dal db per i clienti totali
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const costumers = await axios.get(`${window.$apiEndpoint}/users/fetchCclients`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewCostumers(costumers.data[0].NumberCostumers)
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])






  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  disableBodyScroll(Pagamenti);
  console.log()

  //Configuro le colonne della tabella
  const columns = ["Codice Cliente", "Nome", "Cognome", "Codice Fiscale", {
    label: "Tipologia",
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              <ReactTooltip html={true} />
              {value == 1 ?
                <Alert data-tip="Pagamento via Contanti" key={value} variant="secondary">
                <center><FontAwesomeIcon style={{ color: '#dfb43c',}} className='fa-1x text-gray-300' icon={faWallet} >
              </FontAwesomeIcon>  Contanti!</center>
                </Alert>
              : value == 2 ?
              <>
              <ReactTooltip html={true} />
              <Alert data-tip="Pagamento Online con Carta di Credito" key={value} variant="primary">
              <center><FontAwesomeIcon style={{ color: '#1e6f79'}} className='fa-1x text-gray-300' icon={faCreditCard} >
              </FontAwesomeIcon>  Online (SumUP)!</center>
              </Alert>
              </>
              : value == 3 ?
              <>
              <ReactTooltip html={true} />
              <Alert data-tip="Pagamento Fisico con Carta di Credito via POS" key={value} variant="success">
              <center><FontAwesomeIcon style={{ color: 'green'}} className='fa-1x text-gray-300' icon={faCreditCard} >
              </FontAwesomeIcon>  POS Fisico!</center>
              </Alert>
              </>
              :
              null
              }
              </>
            )
        }
    }
}, {
    label: "Importo Pagamento",
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              Pagamento di <strong>{value}</strong> €
              </>
            )
        }
    }
}, "Data Creazione", {
    label: "Stato Pagamento",
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              <ReactTooltip html={true} />
              {value == 0 ?
                <Alert data-tip="Pagamento in fase di Pending" key={value} variant="warning">
                <center><FontAwesomeIcon style={{ color: '#dfb43c',}} className='fa-1x text-gray-300' icon={faInfoCircle} >
              </FontAwesomeIcon>  In attesa!</center>
                </Alert>
              : value == 1 ?
              <Alert data-tip="Pagamento Elaborato con successo" key={value} variant="success">
              <center><FontAwesomeIcon style={{ color: 'green',}} className='fa-1x text-gray-300' icon={faInfoCircle} >
              </FontAwesomeIcon>  Pagato!</center>
              </Alert>
              :
              null
              }
              </>
            )
        }
    }
},{
    label: "Azioni",
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //Mettere qui la funzione per disabilitare il tasto quando lo stato è gia successo

          const myPath = value.split('/', 6)
          const TipologiaPagamento = myPath[0]
          const IdPagamento = myPath[1]
          const StatoPagamento = myPath[2]
          const AnnoFattura = myPath[3]
          const IDFattura = myPath[4]
          const CodiceCli = myPath[5]

            return (
              <>
              {/* Controllo lo stato e se necessario disabilito bottone per l'invio */}

              <ReactTooltip html={true} />

              {TipologiaPagamento == 1 && StatoPagamento == 0 ? <Button data-tip="Avvalla il pagamento in contante"  onClick={() => openConfirm2(IdPagamento) } variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faPaperPlane} />
              </Button>
              : TipologiaPagamento == 2?
              <Button data-tip="Pagamento eseguito Online via carta di credito" onClick={() => openConfirm2(IdPagamento) } variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} disabled>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faPaperPlane} />
              </Button>
              : TipologiaPagamento == 1 && StatoPagamento == 1 ?
              <Button data-tip="Pagamento eseguito via Contanti" onClick={() => openConfirm2(IdPagamento) } variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} disabled>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faPaperPlane} />
              </Button>
              :
              TipologiaPagamento == 3 && StatoPagamento == 0 ? <Button data-tip="Avvalla il pagamento via POS"  onClick={() => openConfirm2(IdPagamento) } variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faPaperPlane} />
              </Button>
              :
              TipologiaPagamento == 3 && StatoPagamento == 1 ?
              <Button data-tip="Pagamento eseguito via carta di credito POS Fisico" onClick={() => openConfirm2(IdPagamento) } variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} disabled>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faPaperPlane} />
              </Button>
              :
              null
              }

              {StatoPagamento == 0 ?   <Button data-tip="Cancella il pagamento" onClick={() => cancellaPagamento(IdPagamento) } variant="info" size="md" style={{ backgroundColor: "red", borderColor: "red", fontWeight: '600', marginLeft: 10}}>
                  <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faTrash} />
                </Button>
              : StatoPagamento == 1 ?
              <Button data-tip="Cancella il pagamento" onClick={() => cancellaPagamento(IdPagamento) } variant="info" size="md" style={{ backgroundColor: "red", borderColor: "red", fontWeight: '600', marginLeft: 10}} disabled>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faTrash} />
              </Button>
              :
              null
              }

              {TipologiaPagamento == 2 && StatoPagamento == 1 ? <Button data-tip="Vedi l'id della transazione </br> per ritrovarlo sul tuo pannello di SumUp" onClick={() => openmodaleRicezioneFattura(IdPagamento) } variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600', marginLeft: 10}}>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faInfoCircle} />
              </Button>
              :
              null
              }

              {IDFattura == 0 ?
              null
              :
              <Button data-tip="Clicca sul segunete bottone </br> per vedere la fattura del cliente" onClick={() => apriFattura(CodiceCli,AnnoFattura, IDFattura) } variant="info" size="md" style={{ backgroundColor: "#eaa214", borderColor: "#eaa214", fontWeight: '600', marginLeft: 10}}>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faFileInvoice} />
              </Button>
              }


              </>
            )
        }
    }
}];
  const options = {
    filter: false,
    selectableRows: false,
    sortOrder: {
    name: 'Numero Fattura',
    direction: 'desc'
    },
    filterType: "dropdown",
    rowsPerPage: 5,
    /*onRowClick:( rowData, rowState)  => {
      console.log(rowData[3])
      history.push('/user-profile/'+rowData[3])
    },*/
    textLabels: {
      body: {
        noMatch: "Spiacenti, nessun record corrispondente trovato",
        toolTip: "Ordinare",
        columnHeaderTooltip: column => `Ordinare per ${column.label}`
      },
      pagination: {
          next: "Pagina successiva",
          previous: "Pagina Precedente",
          rowsPerPage: "Righe per pagina:",
          displayRows: "of",
        },
        toolbar: {
          search: "Cerca",
          downloadCsv: "Scarica CSV",
          print: "Stampa",
          viewColumns: "Vedi Collonne",
          filterTable: "Tabella dei filtri",
        },
        filter: {
          all: "Tutto",
          title: "FILTRI",
          reset: "RIPRISTINA",
        },
        viewColumns: {
          title: "Mostra colonne",
          titleAria: "Mostra / Nascondi colonne tabella",
        },
  }
  };

  const theme = createMuiTheme({
    overrides: {
      MUIDataTableHeadCell:{
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',

        },
      },
      MuiIconButton: {
        root: {
          color: 'grey',

        },
      },
      MuiTableCell:{
        body: {
            backgroundColor: '#00968817',

          },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },

      MuiButton: {
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },
      MuiTypography: {
        body2: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },
      MuiMenuItem: {
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },
      MuiTableCell: {
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '400',
      },
    },
    MUIDataTableToolbar: {
      hover: {
        color: '#616161',
    },
  },
    },
  });

  const ErrorChart = ({ data }) => {
    return (
      <div>
        <Bar data={data}>
        </Bar>
      </div>
    );

  }


  //Recupero i dati dal db
useEffect(() => {
let mounted = true
const fetch = async () => {
  const users = await axios.get(`${window.$apiEndpoint}/invoice/fetchYearsInvoice`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {
    if(mounted){
      setAllYears(users.data)

    }
  } catch (e) {
    console.log(e)
  }
}
fetch();
return () => {
  mounted = false
}
}, [cookies.token])



//Funzione per Generare la fattura elettronica
const creaFatturaElettronica = (path) => {

  //Ricavo il codice cliente dalla stringa

  const myPath = path.split('/', 4)
  const codiceClienteStrip = myPath[0]
  const NumeroFatturaStrip = myPath[1]
  const NumeroAnnoStrip = myPath[2]

//Invio alla pagina per la fatturazione Elettronica
history.push(`/gen-invoiceE/${codiceClienteStrip}/${NumeroFatturaStrip}/${NumeroAnnoStrip}`);

}

//Chiedo Confermo nell'avvollo e nella generazione della fattura
const openConfirm2 = (id_pga) => {
  AlertConfirm({
    title: 'Attenzione!',
    desc: 'Cliccando su Avalla Pagamento confermi il pagamento in contante e prosegui nel generare la fattura.',
    okText: 'Avvalla Pagamento',
    cancelText: 'Annulla',
    onOk: () => {
      avvallaPagamento(id_pga);
    },
    onCancel: () => {
      console.log('cancel');
    }
  });
};


//Funzione per aprore la fattura
const apriFattura = (CodiceCli, AnnoFattura, IDFattura) => {

//Reinderizzo cliente alla pagina
history.push(`/gen-pdf/${CodiceCli}/${IDFattura}/${AnnoFattura}`)

}


//Avvalla pagamento in contante
const avvallaPagamento = (id_pagt) => {


  //Funzione per strippare stringa

function mysql_real_escape_string (str) {
  return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
      switch (char) {
          case "\0":
              return "\\0";
          case "\x08":
              return "\\b";
          case "\x09":
              return "\\t";
          case "\x1a":
              return "\\z";
          case "\n":
              return "\\n";
          case "\r":
              return "\\r";
          case "\"":
          case "'":
          case "\\":
          case "%":
              return "\\"+char; // prepends a backslash to backslash, percent,
                                // and double/single quotes
      }
  });
}

  const fetch = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/invoice/updatePagamentoContante/${id_pagt}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {

    //Successo


    //Proseguo a generare fattura

    //Recupero aliquota e numero fattura dai settaggi

    const numero_fattura_iniziale = allSettings.numero_fattura
    const aliquota_intera = (allSettings.aliquota)
    const aliquota = (allSettings.aliquota / 100)
    const descrizione_aliquota = mysql_real_escape_string(allSettings.descrizione_aliquota)

    //Creo numero di fattura incrementale

    const numero_fattura_incrementale = (numero_fattura_iniziale + 1)

    //Genero array id appuntamenti da Aggiornare come pagati nel database


    //Creo array di ID
    const arrayIdAppuntamenti = JSON.parse(users.data[0].arr_appuntamenti);

    //Aggiorno stato pagamento per Appuntamento pagato ciclo per tutti gli id presenti nella risposta (Ovvero quelli pagati)
    const app = arrayIdAppuntamenti.map((item,index) => {

    console.log(item)

    //Recupero non pagati

    axios.get(`${window.$apiEndpoint}/invoice/fetch-un-single/${item}`, {
      headers: {
        "x-access-token": cookies.token
      }
    }).then(risposta => {

        //Setto Gli state per il non pagato
        const imponibile = risposta.data[0].NonPagato
        const tasse = (risposta.data[0].NonPagato * aliquota)




    axios.get(`${window.$apiEndpoint}/invoice/update-app-invoice-New-Invoice/${item}`, {
      headers: {
        "x-access-token": cookies.token
      }
    }).then(res => {

        console.log('Appuntamenti Aggiornati con successo')

        console.log(res.data)

        const DatiFattura = res.data[0];

        //Recupero Risposta è Genero fattura

        console.log(imponibile)
        console.log(tasse)

        //Elabora dati per fattura
        var startDate = new Date(DatiFattura.startDate);
        var start = ((startDate.getHours() + ":" + startDate.getMinutes()))
        var endDate = new Date(DatiFattura.endDate);
        var end = ((endDate.getHours() + ":" + endDate.getMinutes()))


        //Ricavo la data
        var str1 = DatiFattura.startDate;
        var str2 = str1.slice(0, -6);


        const trat = DatiFattura.trattamento.replace(/<[^>]*>?/gm, '');
        const apps = [str2, start, end, item.tariffa, trat, item.id, item.codice_cliente]

        const dataGiorno = str2;
        const OrarioArrivo = start;
        const OrarioPartenza = end;
        const Tariffa = DatiFattura.tariffa;
        const Trattamento = trat;

        //Creo la fattura
        //Inserisco nel Databse tutti i dati per la fattura

        axios.get(`${window.$apiEndpoint}/invoice/insert-app-invoice-Contante/${numero_fattura_iniziale}/${imponibile}/${tasse}/${DatiFattura.codice_cliente}/${item}/${aliquota_intera}/${dataGiorno}/${Trattamento}/${OrarioArrivo}/${OrarioPartenza}/${descrizione_aliquota}/${id_pagt}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(res => {

          //Fattura creata con successo
          console.log('FATTURA CREATA CON SUCCESSO')

          //Aggiorno numero Fattura nel Database

          //Eseguo update su tabella settings per id fattura

          axios.get(`${window.$apiEndpoint}/invoice/update-settings-invoice/${numero_fattura_incrementale}`, {
            headers: {
              "x-access-token": cookies.token
            }
          }).then(res => {
           console.log('NUMERO FATTURA AGGIONRATO CON SUCCESSO')

           const annoFattura2 = new Date(`${DatiFattura.startDate}`);
           const annoFattura = annoFattura2.getFullYear();


           //Ricarico la pagina
           //Reinderizzo cliente alla pagina
           history.push(`/pagamenti/${annoFattura}`)


          })
        })




    })

      })

    });


    //Eseguo Refresh dei dati nella tabella
    const fetch2 = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/invoice/fetchPagamentiAll/${annoSessione}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {

          viewInvoice(users.data)
          setLoading(true)
          //Setto lo vista
          setLoadingAnno(true)

      } catch (e) {
        console.log(e)
      }

    }
    fetch2();


    //Carico Dati
    const fetch3 = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/invoice/getNContanti/${annoSessione}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
          setnInviate(users.data[0])

      } catch (e) {
        console.log(e)
      }
    }
    fetch3();


    const fetch4 = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/invoice/getNDigitali/${annoSessione}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
          setndaInviare(users.data[0])

      } catch (e) {
        console.log(e)
      }
    }
    fetch4();



    } catch (e) {
      console.log(e)
    }
  }
  fetch();
}


//Funzione per inviare alla scheda Cliente per procedere alla modifica dei dati
const cancellaPagamento = (id_pagt) => {


    //Funzione per strippare stringa

  function mysql_real_escape_string (str) {
    return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
        switch (char) {
            case "\0":
                return "\\0";
            case "\x08":
                return "\\b";
            case "\x09":
                return "\\t";
            case "\x1a":
                return "\\z";
            case "\n":
                return "\\n";
            case "\r":
                return "\\r";
            case "\"":
            case "'":
            case "\\":
            case "%":
                return "\\"+char; // prepends a backslash to backslash, percent,
                                  // and double/single quotes
        }
    });
  }

//Ricavo il codice cliente dalla stringa

const fetch = async () => {
  const users = await axios.delete(`${window.$apiEndpoint}/invoice/delete-payment/${id_pagt}`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {

  //Cancello Appuntamenti e genero report
  //Recupero aliquota e numero fattura dai settaggi

  const numero_fattura_iniziale = allSettings.numero_fattura
  const aliquota_intera = (allSettings.aliquota)
  const aliquota = (allSettings.aliquota / 100)
  const descrizione_aliquota = mysql_real_escape_string(allSettings.descrizione_aliquota)

  //Creo numero di fattura incrementale

  const numero_fattura_incrementale = (numero_fattura_iniziale + 1)

  //Genero array id appuntamenti da Aggiornare come pagati nel database


  //Creo array di ID
  const arrayIdAppuntamenti = JSON.parse(users.data[0].arr_appuntamenti);


  //Aggiorno REPORT - CANCELLO APPUNTAMTNI
  const app = arrayIdAppuntamenti.map((item,index) => {

  console.log(item)

  //Cancello Appuntamento da Google Calendar

  axios.get(`${window.$apiEndpoint}/calendar/delete-event/${item}`, {
    headers: {
      "x-access-token": cookies.token
    }
  }).then(res => {
    console.log('Appuntamento Cancellato')
  })

  //Recupero non pagati

  axios.get(`${window.$apiEndpoint}/invoice/fetch-un-single/${item}`, {
    headers: {
      "x-access-token": cookies.token
    }
  }).then(risposta => {

      //Setto Gli state per il non pagato
      const imponibile = risposta.data[0].NonPagato
      const tasse = (risposta.data[0].NonPagato * aliquota)




  axios.get(`${window.$apiEndpoint}/invoice/update-app-invoice-2/${item}`, {
    headers: {
      "x-access-token": cookies.token
    }
  }).then(res => {

      console.log('Appuntamenti Aggiornati con successo')

      console.log(res.data)

      const DatiFattura = res.data[0];

      //Recupero Risposta è Genero fattura

      console.log(imponibile)
      console.log(tasse)

      //Elabora dati per fattura
      var startDate = new Date(DatiFattura.startDate);
      var start = ((startDate.getHours() + ":" + startDate.getMinutes()))
      var endDate = new Date(DatiFattura.endDate);
      var end = ((endDate.getHours() + ":" + endDate.getMinutes()))


      //Ricavo la data
      var str1 = DatiFattura.startDate;
      var str2 = str1.slice(0, -6);


      const trat = DatiFattura.trattamento.replace(/<[^>]*>?/gm, '');
      const apps = [str2, start, end, item.tariffa, trat, item.id, item.codice_cliente]

      const dataGiorno = str2;
      const OrarioArrivo = start;
      const OrarioPartenza = end;
      const Tariffa = DatiFattura.tariffa;
      const Trattamento = trat;

      //Creo la fattura
      //Inserisco nel Databse tutti i dati per la fattura

      axios.get(`${window.$apiEndpoint}/invoice/insert-app-report/${numero_fattura_iniziale}/${imponibile}/${tasse}/${DatiFattura.codice_cliente}/${item}/${aliquota_intera}/${dataGiorno}/${Trattamento}/${OrarioArrivo}/${OrarioPartenza}/${descrizione_aliquota}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {

        //Fattura creata con successo
        console.log('REPORT CREATO CON SUCCESSO')

      })




  })

    })

  }); //Fine Ciclo

  //Procedo a cancellare gli appuntamenti selzionati
  setTimeout(() => {
  axios.delete(`${window.$apiEndpoint}/invoice/delete-app-id`, {
    headers: {
      "x-access-token": cookies.token
    }
  }).then(res => {
    console.log('APPUNTAMENTI CANCELLATI CON SUCCESSO')
  })

  },4500)


  //Successo

  const fetch2 = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/invoice/fetchPagamentiAll/${annoSessione}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {

        viewInvoice(users.data)
        setLoading(true)
        //Setto lo vista
        setLoadingAnno(true)

    } catch (e) {
      console.log(e)
    }

  }
  fetch2();


  //Carico Dati
  const fetch3 = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/invoice/getNContanti/${annoSessione}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        setnInviate(users.data[0])

    } catch (e) {
      console.log(e)
    }
  }
  fetch3();


  const fetch4 = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/invoice/getNDigitali/${annoSessione}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        setndaInviare(users.data[0])

    } catch (e) {
      console.log(e)
    }
  }
  fetch4();



  } catch (e) {
    console.log(e)
  }
}
fetch();
}


function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Messaggio di Risposta (SDI)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <small><strong>RC</strong> Ricevuta di consegna</small>
      <small style={{marginLeft: 10}}><strong>MC</strong> Notifica di mancata consegna</small>
      <small style={{marginLeft: 10}} ><strong>NS</strong> Notifica di scarto</small>
      <p style={{marginTop: 10}}>Quando la fattura è nello stato <strong>"Inviata in attesa"</strong> di seguito compare un errore perchè non esiste ancora alcuna notifica! Si prega di attendere..</p>
      <ReactJson style={{padding: 15, marginTop: 10}} theme="ocean" displayDataTypes={false} displayObjectSize={false} src={messaggioSDI} />
      </Modal.Body>
      <Modal.Footer>
      <Button style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} onClick={props.onHide}>Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal2(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Dettaglio - Transazione SumUp
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ReactJson style={{padding: 15}} theme="ocean" displayDataTypes={false} displayObjectSize={false} src={messaggioFatturaSDI} />
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} onClick={props.onHide}>Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal3(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Notifica di avvenuta conservazione sostitutiva
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ReactJson style={{padding: 15}} theme="ocean" displayDataTypes={false} displayObjectSize={false} src={messaggioCopia} />
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} onClick={props.onHide}>Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
}

//Funzione per recuperare e pubblicare messaggio SDI nel modale

const openmodaleRicezione = (uuid) => {

//Recupero Mesaggio dal Database
const fetch = async () => {
  const users = await axios.get(`${window.$apiEndpoint}/invoice/fecthMessageSDI/${uuid}`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {

    //Converto array
    const newArray = users.data;
    console.log(newArray)
    setmessaggioSDI(newArray)

    //Apro il modale
    setModalShow(true)



  } catch (e) {
    console.log(e)
  }
}
fetch();


}


//Funzione per recuperare e pubblicare messaggio corpo Fattura Inviata

const openmodaleRicezioneFattura = (uuid) => {

//Recupero Mesaggio dal Database
const fetch = async () => {
  const users = await axios.get(`${window.$apiEndpoint}/invoice/fectTransazioneSumUp/${uuid}`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {

    //Converto array
    const newArray = users.data;
    setmessaggioFatturaSDI(newArray)

    //Apro il modale
    setModalShow1(true)

  } catch (e) {
    console.log(e)
  }
}
fetch();


}

//Funzione per recuperare e pubblicare messaggio copia sostituiva

const openmodaleCopia = (uuid) => {

//Recupero Mesaggio dal Database
const fetch = async () => {
  const users = await axios.get(`${window.$apiEndpoint}/invoice/fecthMessageCopia/${uuid}`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {

    //Converto array
    const newArray = users.data;
    setmessaggioCopia(newArray)

    //Apro il modale
    setModalShow2(true)

  } catch (e) {
    console.log(e)
  }
}
fetch();


}


  return (
    <>


    {loadingAnno ?  <Container fluid>

    <span style={{ fontSize: "1.4vh", fontWeight: "400", marginTop: '2vh', paddingBottom: '0.3vh'}}>Situazione Anno <strong>{selectedOption}</strong></span>
    <Row className='mt-1 mb-2'>

      <Col md={6} className="my-2">

        <div className="card border-left-primary shadow h-100 py-2">
        <div className="card-body">
        <div className="row no-gutters align-items-center">
        <div className="col mr-2">
        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
        Pagamenti in Contante</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">{loading ?
        <p>{nInviate[0].fatture}</p> : <center><Spinner animation="border" /></center>}</div>
        </div>
        <div className="col-auto">
        <FontAwesomeIcon style={{ color: 'grey',}} className='fa-2x text-gray-300' icon={faWallet} />
        </div>
        </div>
        </div>
        </div>
      </Col>
      <Col md={6} className="my-2">

      <div className="card border-left-success shadow h-100 py-2">
      <div className="card-body">
      <div className="row no-gutters align-items-center">
      <div className="col mr-2">
      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
      Pagamenti Online Carta (SUMUP)</div>
      <div className="h5 mb-0 font-weight-bold text-gray-800">{loading ?
      <p>{ndaInviare[0].fatture}</p> : <center><Spinner animation="border" /></center>}</div>
      </div>
      <div className="col-auto">
      <FontAwesomeIcon style={{ color: 'grey',}} className='fa-2x text-gray-300' icon={faCreditCard} />
      </div>
      </div>
      </div>
      </div>
      </Col>


    </Row>
  </Container> : null}



    {loadingAnno ? <Container fluid>
      <Row>
      <Col xs={12}>
      <div className="card border-left-warning mt-6 shadow h-100 py-2 px-4">

      <span style={{ fontSize: "2vh", fontWeight: "400", marginTop: '2vh',}}>Lista Pagamenti</span>
      <span style={{ fontSize: "1.2vh", fontWeight: "200"}}>Di seguito trovate una lista di tutti i pagamenti (Digitali o Contanti) con il suo relativo stato</span>


              <div className="overflow-x-scroll lg:overflow-hidden">
                {loading ?
                <MuiThemeProvider theme={theme}>
                {" "}
                <MUIDataTable
                title={""}
                data={allInvoice}
                columns={columns}
                options={options}
                /></MuiThemeProvider>: <center><Spinner animation="border" /></center>}
              </div>
              </div>

              </Col>

          </Row>

    </Container> : null }


    <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <MyVerticallyCenteredModal2
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        />

        <MyVerticallyCenteredModal3
            show={modalShow2}
            onHide={() => setModalShow2(false)}
          />





    </>
  )
}
export default Pagamenti
