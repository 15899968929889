import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";
import ReactTooltip from 'react-tooltip';
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus ,faFileInvoice, faCalendarWeek, faCalendarDay, faCalendarAlt, faCalendar, faSearch, faInfo, faFileAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";





const AddInvoice = () => {

    const editor = useRef(null)


    //Setto gli state
    const [allUsers, viewUsers] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [isCreated, setCreated] = useState(false)
    const [isCreated1, setCreated1] = useState(false)

    const [showSucc, setSucc] = useState('')
    const [user, setUser] = useState(null)
    const [allSettings, viewSettings] = useState('')
    const [allReditectC, viewReditectC] = useState('')
    const [allReditectN, viewReditectN] = useState('')
    const [prewImp, setPrewImp] = useState('')
    const [prewTasse, setPrewTasse] = useState('')
    const [prewAll, setPrewAll] = useState('')
    const [annoFattura, setAnnoFattura] = useState('')





	  const [checkCostumers, viewCheck] = useState(false)


    const [showErr, setErr] = useState('')
    const [showErr1, setErr1] = useState('')

    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()
    const [allApps, viewApps] = useState([])

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);






	  const [content, setContent] = useState('')

	  const config = {
		readonly: false,
    height: 'auto',
    placeholder: '',
    language: 'it' // all options from https://xdsoft.net/jodit/doc/
	  }

    //Recupero i dati dal db per appuntamenti totali
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const allSet = await axios.get(`${window.$apiEndpoint}/invoice/fetch-aliquote-fatture`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewSettings(allSet.data[0])
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

    //Configuro le colonne della tabella
  const columns = [ "Data", "Orario di Arrivo", "Orario di Partenza", "Tariffa (€/ora)",  "Trattamento"];
  const options = {
    filter: false,
    selectableRows: false,
    filterType: "dropdown",
    rowsPerPage: 4,
    textLabels: {
        body: {
          noMatch: "Spiacenti, nessun record corrispondente trovato",
          toolTip: "Ordinare",
          columnHeaderTooltip: column => `Ordinare per ${column.label}`
        },
        pagination: {
            next: "Pagina successiva",
            previous: "Pagina Precedente",
            rowsPerPage: "Righe per pagina:",
            displayRows: "of",
          },
          toolbar: {
            search: "Cerca",
            downloadCsv: "Scarica CSV",
            print: "Stampa",
            viewColumns: "Vedi Collonne",
            filterTable: "Tabella dei filtri",
          },
          filter: {
            all: "Tutto",
            title: "FILTRI",
            reset: "RIPRISTINA",
          },
          viewColumns: {
            title: "Mostra colonne",
            titleAria: "Mostra / Nascondi colonne tabella",
          },
    }
};

const theme = createMuiTheme({
    overrides: {
                MUIDataTableHeadCell:{
              root: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',

              },
            },
            MuiIconButton: {
              root: {
                color: '#424242',

              },
            },
            MuiTableCell:{
              body: {
                  backgroundColor: '#00968817',

                },
            },
            MUIDataTableHeadCell: {
              fixedHeader: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',
                backgroundColor: ' white',
              },

            },

            MuiButton: {
              root: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',
                backgroundColor: ' white',
              },

            },

            MuiPaper: {
              root: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',
                boxShadow: 'none !important'
              },

            },
            MuiTypography: {
              body2: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',
                backgroundColor: ' white',
              },

            },
            MuiMenuItem: {
              root: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',
                backgroundColor: ' white',
              },

            },
            MuiTableCell: {
              root: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '400',
            },
          },
          MUIDataTableToolbar: {
            hover: {
              color: '#616161',
          },
        },
          },
  });


  console.log()

      //Recupero i dati dal db
    useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/appoiments/fetchCApp`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(users.data)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()


    //Quando Seleziono

   const handleChange = selectedOption => {
      setSelectedOption(selectedOption.value);
      setSelectedOptionLabel("| Cliente: " + selectedOption.label);
    };

    //Bottone settimana
    const week = () => {

      setPrewImp('')
      setPrewTasse('')
      setPrewAll('')

      const id = selectedOption

      //Controllo se il codice cliente è selezionato
      if (!id) {
        return (
          setErr1('Cliente non selezionato'),
          setTimeout(() => {setErr1('')},3000)
          )
      }

      //Seleziono cliente

      axios.get(`${window.$apiEndpoint}/appoiments/fetch-apps-week/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {
        viewApps(res.data)
        const risultati = res.data;

        const prev_imponibile = [];
        const prev_tasse = [];
        //Creo la chiamata per recuperare la somma degli appuntamenti

        const app = risultati.map((item,index) => {
        //Setto le variabili sul map
        const IdAppuntamneto = item[5];


        axios.get(`${window.$apiEndpoint}/invoice/fetch-un-single/${IdAppuntamneto}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(risposta => {

          const aliquota_intera = (allSettings.aliquota)
          const aliquota = (allSettings.aliquota / 100)

          const imponibile = risposta.data[0].NonPagato
          const tasse = (risposta.data[0].NonPagato * aliquota)

          const imponibile_def = Math.round(imponibile * 100) / 100
          const tasse_def = Math.round(tasse * 100) / 100

          console.log("Imponibile: " + imponibile_def)
          console.log("Tasse: " + tasse_def)

          //Creo un array per imponibile e tasse
          //Eseguo la preview del totale

          //Eseguo il push dei risultati in un nuovo array
          prev_imponibile.push(imponibile_def)
          prev_tasse.push(tasse_def)

          //Sommo gli Array
          var sum_prev_imponibile = prev_imponibile.reduce(function(a, b){
          return a + b;
          }, 0);

          const sum_prev_imponibile_2 = Math.round(sum_prev_imponibile * 100) / 100

          var sum_prev_tasse = prev_tasse.reduce(function(a, b){
          return a + b;
          }, 0);

          const sum_prev_tasse_2 = Math.round(sum_prev_tasse * 100) / 100
          //Totale preventivo

          const totaleAllPrev = (sum_prev_imponibile_2 + sum_prev_tasse_2)


          console.log(sum_prev_imponibile)
          console.log(sum_prev_tasse)
          console.log(totaleAllPrev)

          setPrewImp("Totale Imponibile: " + sum_prev_imponibile_2 + " €")
          setPrewTasse("Totale Tasse: " + sum_prev_tasse_2 + " €")
          setPrewAll("Totale Preventivo: " + totaleAllPrev + " €")






      });

      });
        //console.log(res.data)
      })
    };

    //Bottone mese
    const mese = () => {

      setPrewImp('')
      setPrewTasse('')
      setPrewAll('')

      const id = selectedOption

     //Controllo se il codice cliente è selezionato
     if (!id) {
      return (
        setErr1('Cliente non selezionato'),
        setTimeout(() => {setErr1('')},3000)
        )
    }

      //Seleziono cliente

      axios.get(`${window.$apiEndpoint}/appoiments/fetch-apps-mese/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {
        viewApps(res.data)
        //console.log(res.data)
        const risultati = res.data;

        const prev_imponibile = [];
        const prev_tasse = [];
        //Creo la chiamata per recuperare la somma degli appuntamenti

        const app = risultati.map((item,index) => {
        //Setto le variabili sul map
        const IdAppuntamneto = item[5];


        axios.get(`${window.$apiEndpoint}/invoice/fetch-un-single/${IdAppuntamneto}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(risposta => {

          const aliquota_intera = (allSettings.aliquota)
          const aliquota = (allSettings.aliquota / 100)

          const imponibile = risposta.data[0].NonPagato
          const tasse = (risposta.data[0].NonPagato * aliquota)

          const imponibile_def = Math.round(imponibile * 100) / 100
          const tasse_def = Math.round(tasse * 100) / 100

          console.log("Imponibile: " + imponibile_def)
          console.log("Tasse: " + tasse_def)

          //Creo un array per imponibile e tasse
          //Eseguo la preview del totale

          //Eseguo il push dei risultati in un nuovo array
          prev_imponibile.push(imponibile_def)
          prev_tasse.push(tasse_def)

          //Sommo gli Array
          var sum_prev_imponibile = prev_imponibile.reduce(function(a, b){
          return a + b;
          }, 0);

          const sum_prev_imponibile_2 = Math.round(sum_prev_imponibile * 100) / 100

          var sum_prev_tasse = prev_tasse.reduce(function(a, b){
          return a + b;
          }, 0);

          const sum_prev_tasse_2 = Math.round(sum_prev_tasse * 100) / 100
          //Totale preventivo

          const totaleAllPrev = (sum_prev_imponibile_2 + sum_prev_tasse_2)


          console.log(sum_prev_imponibile)
          console.log(sum_prev_tasse)
          console.log(totaleAllPrev)

          setPrewImp("Totale Imponibile: " + sum_prev_imponibile_2 + " €")
          setPrewTasse("Totale Tasse: " + sum_prev_tasse_2 + " €")
          setPrewAll("Totale Preventivo: " + totaleAllPrev + " €")






      });

      });
      })
    };

    //Bottone anno
    const anno = () => {


      setPrewImp('')
      setPrewTasse('')
      setPrewAll('')

      const id = selectedOption

      //Controllo se il codice cliente è selezionato
      if (!id) {
        return (
          setErr1('Cliente non selezionato'),
          setTimeout(() => {setErr1('')},3000)
          )
      }

      //Seleziono cliente

      axios.get(`${window.$apiEndpoint}/appoiments/fetch-apps-anno/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {
        viewApps(res.data)
        console.log(res.data)

        const risultati = res.data;

        const prev_imponibile = [];
        const prev_tasse = [];
        //Creo la chiamata per recuperare la somma degli appuntamenti

        const app = risultati.map((item,index) => {
        //Setto le variabili sul map
        const IdAppuntamneto = item[5];


        axios.get(`${window.$apiEndpoint}/invoice/fetch-un-single/${IdAppuntamneto}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(risposta => {

          const aliquota_intera = (allSettings.aliquota)
          const aliquota = (allSettings.aliquota / 100)

          const imponibile = risposta.data[0].NonPagato
          const tasse = (risposta.data[0].NonPagato * aliquota)

          const imponibile_def = Math.round(imponibile * 100) / 100
          const tasse_def = Math.round(tasse * 100) / 100

          console.log("Imponibile: " + imponibile_def)
          console.log("Tasse: " + tasse_def)

          //Creo un array per imponibile e tasse
          //Eseguo la preview del totale

          //Eseguo il push dei risultati in un nuovo array
          prev_imponibile.push(imponibile_def)
          prev_tasse.push(tasse_def)

          //Sommo gli Array
          var sum_prev_imponibile = prev_imponibile.reduce(function(a, b){
          return a + b;
          }, 0);

          const sum_prev_imponibile_2 = Math.round(sum_prev_imponibile * 100) / 100

          var sum_prev_tasse = prev_tasse.reduce(function(a, b){
          return a + b;
          }, 0);

          const sum_prev_tasse_2 = Math.round(sum_prev_tasse * 100) / 100
          //Totale preventivo

          const totaleAllPrev = (sum_prev_imponibile_2 + sum_prev_tasse_2)


          console.log(sum_prev_imponibile)
          console.log(sum_prev_tasse)
          console.log(totaleAllPrev)

          setPrewImp("Totale Imponibile: " + sum_prev_imponibile_2 + " €")
          setPrewTasse("Totale Tasse: " + sum_prev_tasse_2 + " €")
          setPrewAll("Totale Preventivo: " + totaleAllPrev + " €")






      });

      });
      })
    };

    //Bottone tutti
    const tutti = () => {

      setPrewImp('')
      setPrewTasse('')
      setPrewAll('')

      const id = selectedOption

      //Controllo se il codice cliente è selezionato
      if (!id) {
        return (
          setErr1('Cliente non selezionato'),
          setTimeout(() => {setErr1('')},3000)
          )
      }

      //Seleziono cliente

      axios.get(`${window.$apiEndpoint}/appoiments/fetch-apps-all/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {
        viewApps(res.data)
        console.log(res.data)

        const risultati = res.data;

        const prev_imponibile = [];
        const prev_tasse = [];
        //Creo la chiamata per recuperare la somma degli appuntamenti

        const app = risultati.map((item,index) => {
        //Setto le variabili sul map
        const IdAppuntamneto = item[5];


        axios.get(`${window.$apiEndpoint}/invoice/fetch-un-single/${IdAppuntamneto}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(risposta => {

          const aliquota_intera = (allSettings.aliquota)
          const aliquota = (allSettings.aliquota / 100)

          const imponibile = risposta.data[0].NonPagato
          const tasse = (risposta.data[0].NonPagato * aliquota)

          const imponibile_def = Math.round(imponibile * 100) / 100
          const tasse_def = Math.round(tasse * 100) / 100

          console.log("Imponibile: " + imponibile_def)
          console.log("Tasse: " + tasse_def)

          //Creo un array per imponibile e tasse
          //Eseguo la preview del totale

          //Eseguo il push dei risultati in un nuovo array
          prev_imponibile.push(imponibile_def)
          prev_tasse.push(tasse_def)

          //Sommo gli Array
          var sum_prev_imponibile = prev_imponibile.reduce(function(a, b){
          return a + b;
          }, 0);

          const sum_prev_imponibile_2 = Math.round(sum_prev_imponibile * 100) / 100

          var sum_prev_tasse = prev_tasse.reduce(function(a, b){
          return a + b;
          }, 0);

          const sum_prev_tasse_2 = Math.round(sum_prev_tasse * 100) / 100
          //Totale preventivo

          const totaleAllPrev = (sum_prev_imponibile_2 + sum_prev_tasse_2)


          console.log(sum_prev_imponibile)
          console.log(sum_prev_tasse)
          console.log(totaleAllPrev)

          setPrewImp("Totale Imponibile: " + sum_prev_imponibile_2 + " €")
          setPrewTasse("Totale Tasse: " + sum_prev_tasse_2 + " €")
          setPrewAll("Totale Preventivo: " + totaleAllPrev + " €")






      });

      });

      })
    };

    //Bottone tutti
    const day = () => {

      setPrewImp('')
      setPrewTasse('')
      setPrewAll('')


      const id = selectedOption

      //Controllo se il codice cliente è selezionato
      if (!id) {
        return (
          setErr1('Cliente non selezionato'),
          setTimeout(() => {setErr1('')},3000)
          )
      }

      //Seleziono cliente

      axios.get(`${window.$apiEndpoint}/appoiments/fetch-apps-today/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {
        viewApps(res.data)
        console.log(res.data)
        const risultati = res.data;



        const prev_imponibile = [];
        const prev_tasse = [];
        //Creo la chiamata per recuperare la somma degli appuntamenti

        const app = risultati.map((item,index) => {
        //Setto le variabili sul map
        const IdAppuntamneto = item[5];


        axios.get(`${window.$apiEndpoint}/invoice/fetch-un-single/${IdAppuntamneto}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(risposta => {

          const aliquota_intera = (allSettings.aliquota)
          const aliquota = (allSettings.aliquota / 100)

          const imponibile = risposta.data[0].NonPagato
          const tasse = (risposta.data[0].NonPagato * aliquota)

          const imponibile_def = Math.round(imponibile * 100) / 100
          const tasse_def = Math.round(tasse * 100) / 100

          console.log("Imponibile: " + imponibile_def)
          console.log("Tasse: " + tasse_def)

          //Creo un array per imponibile e tasse
          //Eseguo la preview del totale

          //Eseguo il push dei risultati in un nuovo array
          prev_imponibile.push(imponibile_def)
          prev_tasse.push(tasse_def)

          //Sommo gli Array
          var sum_prev_imponibile = prev_imponibile.reduce(function(a, b){
          return a + b;
          }, 0);

          const sum_prev_imponibile_2 = Math.round(sum_prev_imponibile * 100) / 100

          var sum_prev_tasse = prev_tasse.reduce(function(a, b){
          return a + b;
          }, 0);

          const sum_prev_tasse_2 = Math.round(sum_prev_tasse * 100) / 100
          //Totale preventivo

          const totaleAllPrev = (sum_prev_imponibile_2 + sum_prev_tasse_2)


          console.log(sum_prev_imponibile)
          console.log(sum_prev_tasse)
          console.log(totaleAllPrev)

          setPrewImp("Totale Imponibile: " + sum_prev_imponibile_2 + " €")
          setPrewTasse("Totale Tasse: " + sum_prev_tasse_2 + " €")
          setPrewAll("Totale Preventivo: " + totaleAllPrev + " €")






      });

      });
      })
    };


    //Bottone genera fattura
    const generaFattura = () => {

      //Verifico che l'arrray si pieno con dei dati se no stampo errore
      if (!Array.isArray(allApps) || !allApps.length) {
        // array does not exist, is not an array, or is empty
        // ⇒ do not attempt to process array
        return (
          setErr1('Appuntamenti non selezionati'),
          setTimeout(() => {setErr1('')},3000)
          )
        console.log('vuoto')
      }

    	//Controllo se posso fattura per questo cliente

      axios.get(`${window.$apiEndpoint}/users/fetchData/${selectedOption}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {
		      viewCheck(res.data)
      })


      if (checkCostumers === true ) {

      //Dati corretti procedo alla fatturazione

      //Funzione per strippare stringa

    function mysql_real_escape_string (str) {
      return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
          switch (char) {
              case "\0":
                  return "\\0";
              case "\x08":
                  return "\\b";
              case "\x09":
                  return "\\t";
              case "\x1a":
                  return "\\z";
              case "\n":
                  return "\\n";
              case "\r":
                  return "\\r";
              case "\"":
              case "'":
              case "\\":
              case "%":
                  return "\\"+char; // prepends a backslash to backslash, percent,
                                    // and double/single quotes
          }
      });
  }

      //Recupero aliquota e numero fattura dai settaggi

      const numero_fattura_iniziale = allSettings.numero_fattura
      const aliquota_intera = (allSettings.aliquota)
      const aliquota = (allSettings.aliquota / 100)
      const descrizione_aliquota = mysql_real_escape_string(allSettings.descrizione_aliquota)

      //Creo numero di fattura incrementale

      const numero_fattura_incrementale = (numero_fattura_iniziale + 1)

      //Chiedo conferma per proseguire
      const confirmation = window.confirm('Sei sicuro di voler procedere a generare la fattura per il cliente e gli appuntamenti selezionati?')
      if(confirmation === true) {


      const appuntamentiPerfattura = allApps


      //Verifico che l'arrray si pieno con dei dati se no stampo errore
      if (!Array.isArray(allApps) || !allApps.length) {
        // array does not exist, is not an array, or is empty
        // ⇒ do not attempt to process array
        return (
          setErr1('Appuntamenti non selezionati'),
          setTimeout(() => {setErr1('')},3000)
          )
        console.log('vuoto')
      }

      var sumArray = function() {
        // Use one adding function rather than create a new one each
        // time sumArray is called
        function add(a, b) {
            return a + b;
        }

        return function(arr) {
            return arr.reduce(add);
        };
    }();



      //Setto un ciclo

      const app = allApps.map((item,index) => {
      //Setto le variabili sul map
      const dataGiorno = item[0];
      const OrarioArrivo = item[1];
      const OrarioPartenza = item[2];
      const Tariffa = item[3];
      const Trattamento = item[4];
      const IdAppuntamneto = item[5];
      const CodiceCliente = item[6];


      //Procedo al'update per gli apputamenti selezionati nel Databse ciclando i risultati presenti nell'array filtrato

      axios.get(`${window.$apiEndpoint}/invoice/update-app-invoice/${IdAppuntamneto}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {
        return (
          setSucc('Appuntamenti aggiornati con successo'),
          setTimeout(() => {setSucc('')},3000)
          )
      })

      //Eseguo i contteggi dei totali e delle tasse
      //Recupero costi netti per ogni singolo appuntamento

      axios.get(`${window.$apiEndpoint}/invoice/fetch-un-single/${IdAppuntamneto}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(risposta => {

        const imponibile = risposta.data[0].NonPagato
        const tasse = (risposta.data[0].NonPagato * aliquota)

        console.log(CodiceCliente)

        //Setto gl istati per il codice ciente e il numero fattura
        viewReditectC(CodiceCliente)
        viewReditectN(numero_fattura_iniziale)
        //Creo la variabile per l'anno di riferimento sulla fattura selezionata
        const anno_riferimento_fattura = dataGiorno.replace(/\//g,'').slice(0, -6);
        setAnnoFattura(anno_riferimento_fattura)

        //Inserisco nel Databse tutti i dati per la fattura

        axios.get(`${window.$apiEndpoint}/invoice/insert-app-invoice/${numero_fattura_iniziale}/${imponibile}/${tasse}/${CodiceCliente}/${IdAppuntamneto}/${aliquota_intera}/${dataGiorno}/${Trattamento}/${OrarioArrivo}/${OrarioPartenza}/${descrizione_aliquota}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(res => {
          return (
            setSucc('Dati fattura aggiornati con successo'),
            setTimeout(() => {setSucc('')},3000)
            )
        })

      //Eseguo update su tabella settings per id fattura

      axios.get(`${window.$apiEndpoint}/invoice/update-settings-invoice/${numero_fattura_incrementale}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {
        return (
          setSucc('Numero fattura aggiornato con successo'),
          setTimeout(() => {setSucc('')},3000)
          )
      })

      //Eseguo update su tabella settings per id fattura

      axios.get(`${window.$apiEndpoint}/invoice/update-settings-invoice/${numero_fattura_incrementale}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {
        return (
          setSucc('Numero fattura aggiornato con successo'),
          setTimeout(() => {setSucc('')},3000)
          )
      })
      })



      //Procedo alla creazione del pdf

      }) //Fine del map

      //Svuoto la tabella
      viewApps([])

      //Esegup il refresh della pagina
      setTimeout(() => {setCreated(true)},3000)

    }

    } else {
      //Dati non corretti non eseguo la fatturazione
      setErr1('Per poter procedere nella fatturazione è necessario inserire tutti i dati nel modulo anagrafica, per il cliente selezionato.')
      setSucc('Se avete già inserito i dati nel anagrafica cliente ricliccate sul tasto Fattura!')

      setTimeout(() => {setErr1('')},8000)
    }

    };

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
      // redirect to home if signed up
      return <Redirect to = {`/gen-pdf/${allReditectC}/${allReditectN}/${annoFattura}`} />;
      //return window.location.reload();
      //console.log(annoFattura)
  }


  //Bottone tutti
  const generaReport = () => {

    //Verifico che l'arrray si pieno con dei dati se no stampo errore
    if (!Array.isArray(allApps) || !allApps.length) {
      // array does not exist, is not an array, or is empty
      // ⇒ do not attempt to process array
      return (
        setErr1('Appuntamenti non selezionati'),
        setTimeout(() => {setErr1('')},3000)
        )
      console.log('vuoto')
    }


    //Controllo se posso fattura per questo cliente
    axios.get(`${window.$apiEndpoint}/users/fetchData/${selectedOption}`, {
      headers: {
        "x-access-token": cookies.token
      }
    }).then(res => {
        viewCheck(res.data)
    })

    //Disabilito il controllo di tutti i dati per procedere alla fattura
    //if (checkCostumers === true ) {

    //I dati sono presenti

    //Funzione per strippare stringa

  function mysql_real_escape_string (str) {
    return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
        switch (char) {
            case "\0":
                return "\\0";
            case "\x08":
                return "\\b";
            case "\x09":
                return "\\t";
            case "\x1a":
                return "\\z";
            case "\n":
                return "\\n";
            case "\r":
                return "\\r";
            case "\"":
            case "'":
            case "\\":
            case "%":
                return "\\"+char; // prepends a backslash to backslash, percent,
                                  // and double/single quotes
        }
    });
}

    //Recupero aliquota e numero fattura dai settaggi

    const numero_fattura_iniziale = allSettings.numero_fattura
    const aliquota_intera = (allSettings.aliquota)
    const aliquota = (allSettings.aliquota / 100)
    const descrizione_aliquota = mysql_real_escape_string(allSettings.descrizione_aliquota)

    //Creo numero di fattura incrementale

    const numero_fattura_incrementale = (numero_fattura_iniziale + 1)

    //Chiedo conferma per proseguire
    const confirmation = window.confirm('Sei sicuro di voler procedere nel cacellare gli appuntamenti selezionati, questa opzione cancellerà tutti gli appuntamenti selezionati e procederà a generare un report?')
    if(confirmation === true) {


    const appuntamentiPerfattura = allApps


    //Verifico che l'arrray si pieno con dei dati se no stampo errore
    if (!Array.isArray(allApps) || !allApps.length) {
      // array does not exist, is not an array, or is empty
      // ⇒ do not attempt to process array
      return (
        setErr1('Appuntamenti non selezionati'),
        setTimeout(() => {setErr1('')},3000)
        )
      console.log('vuoto')
    }

    var sumArray = function() {
      // Use one adding function rather than create a new one each
      // time sumArray is called
      function add(a, b) {
          return a + b;
      }

      return function(arr) {
          return arr.reduce(add);
      };
  }();



    //Setto un ciclo

    const app = allApps.map((item,index) => {
    //Setto le variabili sul map
    const dataGiorno = item[0];
    const OrarioArrivo = item[1];
    const OrarioPartenza = item[2];
    const Tariffa = item[3];
    const Trattamento = item[4];
    const IdAppuntamneto = item[5];
    const CodiceCliente = item[6];

     //Procedo al'update per gli apputamenti selezionati nel Databse ciclando i risultati presenti nell'array filtrato

     axios.get(`${window.$apiEndpoint}/invoice/update-app-invoice-2/${IdAppuntamneto}`, {
      headers: {
        "x-access-token": cookies.token
      }
    }).then(res => {
      return (
        setSucc('Appuntamenti aggiornati con successo'),
        setTimeout(() => {setSucc('')},3000)
        )
    })

    //Cancello Appuntamento da Google Calendar

    axios.get(`${window.$apiEndpoint}/calendar/delete-event/${IdAppuntamneto}`, {
      headers: {
        "x-access-token": cookies.token
      }
    }).then(res => {
      return (
        setSucc('Appuntamenti cancellato con successo'),
        setTimeout(() => {setSucc('')},3000)
        )
    })


    //Eseguo i contteggi dei totali e delle tasse
    //Recupero costi netti per ogni singolo appuntamento

    axios.get(`${window.$apiEndpoint}/invoice/fetch-un-single/${IdAppuntamneto}`, {
      headers: {
        "x-access-token": cookies.token
      }
    }).then(risposta => {

      const imponibile = risposta.data[0].NonPagato
      const tasse = (risposta.data[0].NonPagato * aliquota)


      //Setto gl istati per il codice ciente e il numero fattura
      viewReditectC(CodiceCliente)
      viewReditectN(numero_fattura_iniziale)
      //Creo la variabile per l'anno di riferimento sulla fattura selezionata
      const anno_riferimento_fattura = dataGiorno.replace(/\//g,'').slice(0, -6);
      setAnnoFattura(anno_riferimento_fattura)

      //Inserisco nel Databse tutti i dati per la fattura

      axios.get(`${window.$apiEndpoint}/invoice/insert-app-report/${numero_fattura_iniziale}/${imponibile}/${tasse}/${CodiceCliente}/${IdAppuntamneto}/${aliquota_intera}/${dataGiorno}/${Trattamento}/${OrarioArrivo}/${OrarioPartenza}/${descrizione_aliquota}`, {
        headers: {
          "x-access-token": cookies.token
        }
      }).then(res => {
        return (
          setSucc('Dati report aggiornati con successo...Attendere Prego..'),
          setTimeout(() => {setSucc('')},3000)
          )
      })


    })


    //Procedo alla creazione del pdf

    }) //Fine del map

    //Procedo a cancellare gli appuntamenti selzionati
    setTimeout(() => {
    axios.delete(`${window.$apiEndpoint}/invoice/delete-app-id`, {
      headers: {
        "x-access-token": cookies.token
      }
    }).then(res => {
      return (
        setSucc('Appuntamenti cancellati con successo'),
        setTimeout(() => {setSucc('')},3000)
        )
    })

     //Svuoto la tabella
     viewApps([])

     //Esegup il refresh della pagina
     setTimeout(() => {setCreated1(true)},3000)


    },4500)

  }

    /*} else {

      //I dati non sono presenti

      //Dati non corretti non eseguo la fatturazione
      setErr1('Per poter procedere all generazione del report è necessario inserire tutti i dati nel modulo anagrafica, per il cliente selezionato.')
      setSucc('Se avete già inserito i dati nel anagrafica cliente ricliccate sul tasto Cancella per generare il Report!')
      //Dati
      setTimeout(() => {setErr1('')},8000)

    }*/


  };

  //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
  if (isCreated1) {
    // redirect to home if signed up
    return <Redirect to = {`/gen-report/${allReditectC}/${allReditectN}/${annoFattura}`} />;
    //return window.location.reload();
    }


    //Chiamata post per inserimento utente
    const onSubmit = data => {

      setPrewImp('')
      setPrewTasse('')
      setPrewAll('')


      const codice_cliente = selectedOption;
      const startDate = data.startDate;
      const endDate = data.endDate;

      //console.log(codice_cliente);
      //console.log(startDate);
      //console.log(endDate);

      if (!codice_cliente) {
        return (
          setErr1('Cliente non selezionato'),
          setTimeout(() => {setErr1('')},3000)
          )
      }

      if (!startDate) {
        return (
          setErr1('Data di arrivo non selezionata'),
          setTimeout(() => {setErr1('')},3000)
          )
      }

      if (!endDate) {
        return (
          setErr1('Data di partenza non selezionata'),
          setTimeout(() => {setErr1('')},3000)
          )
      }

      console.log(codice_cliente)

      console.log(startDate)
      console.log(endDate)

      axios.post(`${window.$apiEndpoint}/appoiments/fetch-apps-range`, {
        codice_cliente: codice_cliente,
        startDate: startDate,
        endDate: endDate,


      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })
          .then(res => {
            viewApps(res.data)
            console.log(res.data)

            const risultati = res.data;

            const prev_imponibile = [];
            const prev_tasse = [];
            //Creo la chiamata per recuperare la somma degli appuntamenti

            const app = risultati.map((item,index) => {
            //Setto le variabili sul map
            const IdAppuntamneto = item[5];


            axios.get(`${window.$apiEndpoint}/invoice/fetch-un-single/${IdAppuntamneto}`, {
              headers: {
                "x-access-token": cookies.token
              }
            }).then(risposta => {

              const aliquota_intera = (allSettings.aliquota)
              const aliquota = (allSettings.aliquota / 100)

              const imponibile = risposta.data[0].NonPagato
              const tasse = (risposta.data[0].NonPagato * aliquota)

              const imponibile_def = Math.round(imponibile * 100) / 100
              const tasse_def = Math.round(tasse * 100) / 100

              console.log("Imponibile: " + imponibile_def)
              console.log("Tasse: " + tasse_def)

              //Creo un array per imponibile e tasse
              //Eseguo la preview del totale

              //Eseguo il push dei risultati in un nuovo array
              prev_imponibile.push(imponibile_def)
              prev_tasse.push(tasse_def)

              //Sommo gli Array
              var sum_prev_imponibile = prev_imponibile.reduce(function(a, b){
              return a + b;
              }, 0);

              const sum_prev_imponibile_2 = Math.round(sum_prev_imponibile * 100) / 100

              var sum_prev_tasse = prev_tasse.reduce(function(a, b){
              return a + b;
              }, 0);

              const sum_prev_tasse_2 = Math.round(sum_prev_tasse * 100) / 100
              //Totale preventivo

              const totaleAllPrev = (sum_prev_imponibile_2 + sum_prev_tasse_2)


              console.log(sum_prev_imponibile)
              console.log(sum_prev_tasse)
              console.log(totaleAllPrev)

              setPrewImp("Totale Imponibile: " + sum_prev_imponibile_2 + " €")
              setPrewTasse("Totale Tasse: " + sum_prev_tasse_2 + " €")
              setPrewAll("Totale Preventivo: " + totaleAllPrev + " €")

              });

              });
              }).catch(err => {
              setErr1('Errore - ' + err.message)
              })
              }

    return (
        <>
              <ReactTooltip />
            <SectionTitle title="Inserisci" subtitle="Crea nuova fattura" />
                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">


                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Button className="mt-2 mb-3" data-tip="Per poter fatturare devi aver settato le impostazioni di fattura nell'apposita sezione" variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                        <FontAwesomeIcon className="mx-1" icon={faInfo} />
                        </Button>
                        <Form.Row>
                                    <Col xs={12}>
                                    <Form.Group  controlId="cliente" >
                                    <Form.Label>Seleziona Cliente:</Form.Label>

                                    {loading ?
                                    <Select value={selectedOption} onChange={handleChange} options={allUsers} />
                                    : <center><Spinner size="sm" animation="border" variant="info" /></center>
                                    }
                                    <pre className="mt-2">Codice Cliente Selezionato: <strong>{selectedOption}</strong> {selectedOptionLabel} </pre>

                                    </Form.Group>
                                    </Col>
                                    <Col className="mb-4">
                                    <center>
                                    <Button data-tip="Seleziona Giorno Corrente" onClick={() => day()} className="mt-4 ml-4" variant="info" size="md"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    <FontAwesomeIcon className="mx-1" icon={faCalendarDay} /> Giorno
                                    </Button>
                                    <Button data-tip="Seleziona Ultima Settimana" onClick={() => week()} className="mt-4 ml-4" variant="info" size="md"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    <FontAwesomeIcon className="mx-1" icon={faCalendarWeek} /> Settimana
                                    </Button>
                                    <Button data-tip="Seleziona Ultimi 30 Giorni" onClick={() => mese()} className="mt-4 ml-4" variant="info" size="md"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    <FontAwesomeIcon className="mx-1" icon={faCalendarDay} /> Mese
                                    </Button>
                                    <Button data-tip="Seleziona Ultimi 365" onClick={() => anno()} className="mt-4 ml-4" variant="info" size="md"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    <FontAwesomeIcon className="mx-1"  icon={faCalendarAlt} />  Anno
                                    </Button>
                                    <Button data-tip="Seleziona tutti gli appuntamenti da saldare" onClick={() => tutti()} className="mt-4 ml-4" variant="info" size="md"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    <FontAwesomeIcon className="mx-1" icon={faCalendar} />  Tutto
                                    </Button>
                                    <Button variant="info" size="md" type="submit" className="mt-4 ml-4" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    <FontAwesomeIcon className="mx-1" icon={faSearch} />  Cerca per date
                                    </Button>
                                    <p style={{ marginTop: '2.5vh', fontWeight: 'bold', color: 'red' }}>{showErr1}</p>
                                    <p style={{ marginTop: '2.5vh', fontWeight: 'bold', color: 'green' }}>{showSucc}</p>
                                    <p style={{ marginTop: '2.5vh', fontWeight: 'bold', color: 'grey', fontSize: '1.8vh'}}>{prewImp}</p>
                                    <p style={{ marginTop: '2.5vh', fontWeight: 'bold', color: 'grey', fontSize: '1.8vh'}}>{prewTasse}</p>
                                    <p style={{ marginTop: '2.5vh', fontWeight: 'bold', color: 'black', fontSize: '1.8vh'}}>{prewAll}</p>

                                    <p></p>
                                    </center>
                                    </Col>



                                    </Form.Row>
                                    <Form.Row>

                                    <Col>

                                    <Form.Group  className="mt-2" controlId="startDate" >
                                    <Form.Label>Data di Arrivo</Form.Label>
                                    <Form.Control type="date" name="startDate" ref={register()} />
                                    </Form.Group>

                                    </Col>


                                     <Col>

                                    <Form.Group className="mt-2"  controlId="endDate" >
                                    <Form.Label>Data di partenza</Form.Label>
                                    <Form.Control type="date" name="endDate" ref={register()} />
                                    </Form.Group>

                                    </Col>

                                </Form.Row>

                                {loading ? <MuiThemeProvider theme={theme}>
                                    <MUIDataTable
                                    title={""}
                                    data={allApps}
                                    columns={columns}
                                    options={options}
                                    /></MuiThemeProvider>: <center><Spinner animation="border" /></center>}




                                    </Form>


                                    <center><Button data-tip="Genera Fattura" className="mt-3" onClick={() => generaFattura()} className="mt-4 ml-4" variant="info" size="lg"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    <FontAwesomeIcon className="mx-1" icon={faFileAlt} />  Fattura
                                    </Button>
                                    <Button data-tip="Cancella" className="mt-3 mb-4" onClick={() => generaReport()} className="mt-4 ml-4" variant="info" size="lg"  style={{ backgroundColor: "#e57373", borderColor: "#e57373", fontWeight: '600'}}>
                                    <FontAwesomeIcon className="mx-1" icon={faWindowClose} />  CANCELLA
                                    </Button></center>
                        </div>

                                </Col>

                            </Row>



                        </Container>

        </>
    )
}
export default AddInvoice
