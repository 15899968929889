
import React, { useState, useEffect } from 'react'
import SectionTitle from '../components/section-title'
import { FaTrash, FaCheckCircle } from 'react-icons/fa'
import Widget from '../components/social-feed/widget'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { Tab, Tabs, Form, Button, Spinner, Container, Row, Col, } from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar'
import "react-colorful/dist/index.css";
import MUIDataTable from 'mui-datatables';

import ReactTooltip from 'react-tooltip';


const { parseCookies } = require('nookies');



const UserProfile = (props) => {

  //GET DATA FROM BACKEND - SETTING STATES
  const [key, setKey] = useState('details');
  const [allUsers, viewUsers] = useState({})
  const [allApps, viewApps] = useState([{}])
  const [allAppsUn, viewAppsUn] = useState([{}])
  const [allInvoice, viewInvoice] = useState([{}])
  const [allPreventivi, viewPreventivi] = useState([{}])


  const history = useHistory()

  const [allUnInvoice, viewUnInvoice] = useState({})
  const [allPaInvoice, viewPaInvoice] = useState({})

  const [allSettings, viewSettings] = useState('')


  const [loading, setLoading] = useState(true)
  const [avatar, setAvatar] = useState('')
  const [deleted, setDeleted] = useState(false)
  const { register, handleSubmit, errors } = useForm();
  const cookies = parseCookies()
  const [showErr, setErr] = useState('')
  const [showSucc, setSucc] = useState('')
  const [showErr1, setErr1] = useState('')
  const [showSucc1, setSucc1] = useState('')
  const [tipologia, setTipologia] = useState(null)



  //Recupero i dati dal db per paramentri Clienti
  useEffect(() => {
    let mounted = true
    const id = props.match.params.id
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/appoiments/fetch-client/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          //Controllo se il cleinte è un cliente privato oppure un cliente aziendale
          let tipologia_cliente = users.data[0].tipo_cliente;
          if (tipologia_cliente === 0) {
          //Cliente Privato
          viewUsers(users.data[0])
          let name = users.data[0].fname
          setAvatar(name.charAt(0))
          //Set Visibilità per tipologia
          setTipologia(0)
          } else if (tipologia_cliente === 1) {
          viewUsers(users.data[0])
          let name = users.data[0].ragione_sociale
          setAvatar(name.charAt(0))
          //Tipologia Azienda
          //Set Visibilità per tipologia
          setTipologia(1)
          } else {
          //Nessuna tipologia
          }

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()


  //Recupero le fatture
  useEffect(() => {
    let mounted = true
    const id = props.match.params.id
    const fetch = async () => {
      const invoice = await axios.get(`${window.$apiEndpoint}/invoice/get-all-invoice-user/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewInvoice(invoice.data)

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Recupero i report
  useEffect(() => {
    let mounted = true
    const id = props.match.params.id
    const fetch = async () => {
      const invoice = await axios.get(`${window.$apiEndpoint}/invoice/get-all-report-user/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewPreventivi(invoice.data)

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Recupero i dati dal db per le impostazioni settate
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const allSet = await axios.get(`${window.$apiEndpoint}/invoice/fetch-aliquote-fatture`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewSettings(allSet.data[0])
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Recupero i dati dal db per paramentri Clienti
  useEffect(() => {
    let mounted = true
    const id = props.match.params.id
    const fetch = async () => {
      const apps = await axios.get(`${window.$apiEndpoint}/appoiments/fetch-apps/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewApps(apps.data)

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Recupero i dati dal db per paramentri Clienti
  useEffect(() => {
    let mounted = true
    const id = props.match.params.id
    const fetch = async () => {
      const appsUn = await axios.get(`${window.$apiEndpoint}/appoiments/fetch-apps-un/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewAppsUn(appsUn.data)

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()


  //Configuro le colonne della tabella
  const columns1 = [ "Data Creazione", "Numero Fattura", "Codice Cliente"," Fattura Assicurativa"];
  const columns2 = [ "Data Creazione", "Numero Report", "Codice Cliente"];

  const options1 = {
    filter: false,
    selectableRows: false,
    filterType: "dropdown",
    rowsPerPage: 4,
    onRowClick:( rowData, rowState)  => {

      //Controlle se è una fattura assicurativa o normale e di conseguenza invio da una parte piuttosto che un altra
      if (rowData[3] == null) {
      //Fattura non assicurativa
      //Creo la variabile per l'anno di riferimento sulla fattura selezionata
      const anno_riferimento_fattura = rowData[0].replace(/\//g,'').slice(4);
      //Eseguo Redirect per la generazione della fattura
      history.push('/gen-pdf/'+rowData[2]+'/'+rowData[1]+'/'+anno_riferimento_fattura)

      } else {
      //Fattuara Assicurativa
      //Creo la variabile per l'anno di riferimento sulla fattura selezionata
      const anno_riferimento_fattura = rowData[0].replace(/\//g,'').slice(4);
      //Eseguo Redirect per la generazione della fattura
      history.push('/gen-pdf-ass/'+rowData[2]+'/'+rowData[1]+'/'+anno_riferimento_fattura)
    }


    },
    textLabels: {
        body: {
          noMatch: "Spiacenti, nessun record corrispondente trovato",
          toolTip: "Ordinare",
          columnHeaderTooltip: column => `Ordinare per ${column.label}`
        },
        pagination: {
            next: "Pagina successiva",
            previous: "Pagina Precedente",
            rowsPerPage: "Righe per pagina:",
            displayRows: "of",
          },
          toolbar: {
            search: "Cerca",
            downloadCsv: "Scarica CSV",
            print: "Stampa",
            viewColumns: "Vedi Collonne",
            filterTable: "Tabella dei filtri",
          },
          filter: {
            all: "Tutto",
            title: "FILTRI",
            reset: "RIPRISTINA",
          },
          viewColumns: {
            title: "Mostra colonne",
            titleAria: "Mostra / Nascondi colonne tabella",
          },
    }
};

const options2 = {
  filter: false,
  selectableRows: false,
  filterType: "dropdown",
  rowsPerPage: 4,
  onRowClick:( rowData, rowState)  => {

    //Creo la variabile per l'anno di riferimento sul preventivo selezionato
    const anno_riferimento_prev = rowData[0].replace(/\//g,'').slice(4);
    //Eseguo Redirect per la generazione del preventivo
    history.push('/gen-report/'+rowData[2]+'/'+rowData[1]+'/'+anno_riferimento_prev)
  },
  textLabels: {
      body: {
        noMatch: "Spiacenti, nessun record corrispondente trovato",
        toolTip: "Ordinare",
        columnHeaderTooltip: column => `Ordinare per ${column.label}`
      },
      pagination: {
          next: "Pagina successiva",
          previous: "Pagina Precedente",
          rowsPerPage: "Righe per pagina:",
          displayRows: "of",
        },
        toolbar: {
          search: "Cerca",
          downloadCsv: "Scarica CSV",
          print: "Stampa",
          viewColumns: "Vedi Collonne",
          filterTable: "Tabella dei filtri",
        },
        filter: {
          all: "Tutto",
          title: "FILTRI",
          reset: "RIPRISTINA",
        },
        viewColumns: {
          title: "Mostra colonne",
          titleAria: "Mostra / Nascondi colonne tabella",
        },
  }
};
const columns = [ "Data", "Orario di Arrivo", "Orario di Partenza", "Tariffa (€/ora)",  "Trattamento"];

  const options = {
    filter: false,
    selectableRows: false,
    filterType: "dropdown",
    rowsPerPage: 4,
    textLabels: {
        body: {
          noMatch: "Spiacenti, nessun record corrispondente trovato",
          toolTip: "Ordinare",
          columnHeaderTooltip: column => `Ordinare per ${column.label}`
        },
        pagination: {
            next: "Pagina successiva",
            previous: "Pagina Precedente",
            rowsPerPage: "Righe per pagina:",
            displayRows: "of",
          },
          toolbar: {
            search: "Cerca",
            downloadCsv: "Scarica CSV",
            print: "Stampa",
            viewColumns: "Vedi Collonne",
            filterTable: "Tabella dei filtri",
          },
          filter: {
            all: "Tutto",
            title: "FILTRI",
            reset: "RIPRISTINA",
          },
          viewColumns: {
            title: "Mostra colonne",
            titleAria: "Mostra / Nascondi colonne tabella",
          },
    }
};

const theme = createMuiTheme({
    overrides: {
                MUIDataTableHeadCell:{
              root: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',

              },
            },
            MuiIconButton: {
              root: {
                color: '#424242',

              },
            },
            MuiTableCell:{
              body: {
                  backgroundColor: '#00968817',

                },
            },
            MUIDataTableHeadCell: {
              fixedHeader: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',
                backgroundColor: ' white',
              },

            },

            MuiButton: {
              root: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',
                backgroundColor: ' white',
              },

            },

            MuiPaper: {
              root: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',
                boxShadow: 'none !important'
              },

            },
            MuiTypography: {
              body2: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',
                backgroundColor: ' white',
              },

            },
            MuiMenuItem: {
              root: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '200',
                backgroundColor: ' white',
              },

            },
            MuiTableCell: {
              root: {
                fontFamily: "'Raleway', sans-serif",
                fontWeight: '400',
            },
          },
          MUIDataTableToolbar: {
            hover: {
              color: '#616161',
          },
        },
          },
  });


  //Recupero i dati dal db per paramentri Fatture non pagate
  useEffect(() => {
    let mounted = true
    const id = props.match.params.id
    const fetch = async () => {
      const unInvoice = await axios.get(`${window.$apiEndpoint}/invoice/fetch-un/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUnInvoice(unInvoice.data[0])

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Recupero i dati dal db per paramentri Fatture pagate
  useEffect(() => {
    let mounted = true
    const id = props.match.params.id
    const fetch = async () => {
      const paInvoice = await axios.get(`${window.$apiEndpoint}/invoice/fetch-paid/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewPaInvoice(paInvoice.data[0])

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()


  //Gestisco il passaggio tra le tab
  const changeTab = (k) => {
    setKey(k)
  }

  //Elimino l'utente
  const deleteUser = () => {
    const cookies = parseCookies();
    const id = props.match.params.id
    const confirm = window.confirm('Questa azione cancellerà il cliente e tutti gli appuntamenti a lui connessi, sei sicuro di voler procedere?')
    if(confirm === true) {
      //Canello Cliente
      axios.delete(`${window.$apiEndpoint}/appoiments/delete-costumers/${id}`, {
        headers: {
          'x-access-token': cookies.token
        }

      },
      //Canello appuntamenenti associati per il cliente
      axios.delete(`${window.$apiEndpoint}/appoiments/delete-costumers-app/${id}`, {
        headers: {
          'x-access-token': cookies.token
        }

      })

      ).then(res => {
        setSucc('Cliente e appuntamenti cancellati con successo, a breve la pagina verrà ricaricata.')
        setTimeout(() => {setDeleted(true)},2000)

      }).catch(err => {
        setErr('Erore - ' + err.message)
        console.log(err)
      })
    }
  }


  //Chiamata post per modifca dati utente
  const onSubmit = data => {
        const codice_cliente = data.codice_cliente;
        const fname = data.fname;
        const lname = data.lname;
        const strada = data.strada.toUpperCase().trim();
        const civico = data.civico.toUpperCase().trim();
        const comune = data.comune.toUpperCase().trim();
        const provincia = data.provincia.toUpperCase().trim();
        const cap = data.cap.toUpperCase().trim();
        const codice_fiscale = data.codice_fiscale.toUpperCase().trim();
        const email = data.email;
        const tariffa = data.tariffa;



    //Controllo se il codice cliente è selezionato
    if (!codice_cliente) {
      return setErr1('Cliente non selezionato')
    }

    const confirmation = window.confirm('Sei sicuro di voler modificare il cliente selezionato?')
    if(confirmation === true) {

    axios.post(`${window.$apiEndpoint}/invoice/update`, {
      codice_cliente: codice_cliente,
      fname: fname,
      lname: lname,
      strada: strada,
      civico: civico,
      comune: comune,
      provincia: provincia,
      cap: cap,
      codice_fiscale: codice_fiscale,
      email: email,
      tariffa: tariffa,

    }, {
        headers: {
            'x-access-token' : cookies.token
        }
    },

    axios.post(`${window.$apiEndpoint}/invoice/update-C-App`, {
      codice_cliente: codice_cliente,
      fname: fname,
      lname: lname,

    }, {
        headers: {
            'x-access-token' : cookies.token
        }
    })

    )
        .then(res => {
              setSucc1('Cliente modifcato con successo, a breve la pagina verrà ricaricata.')
              setTimeout(() => {setDeleted(true)},2000)




        }).catch(err => {
          setErr1('Erore - ' + err.message)
        })
      }
}


//Chiamata post per modifca dati utente
const onSubmitIva = data => {
      const codice_cliente = data.codice_cliente;
      const fname = data.fname;
      const lname = data.lname;
      const pec = data.pec;
      const sdi = data.sdi;
      const strada = data.strada.toUpperCase().trim();
      const civico = data.civico.toUpperCase().trim();
      const comune = data.comune.toUpperCase().trim();
      const provincia = data.provincia.toUpperCase().trim();
      const cap = data.cap.toUpperCase().trim();
      const piva = data.piva.toUpperCase().trim();
      const email = data.email;
      const tariffa = data.tariffa;



  //Controllo se il codice cliente è selezionato
  if (!codice_cliente) {
    return setErr1('Cliente non selezionato')
  }

  const confirmation = window.confirm('Sei sicuro di voler modificare il cliente selezionato?')
  if(confirmation === true) {

  axios.post(`${window.$apiEndpoint}/invoice/updateIva`, {
    codice_cliente: codice_cliente,
    fname: fname,
    lname: lname,
    pec: pec,
    sdi: sdi,
    strada: strada,
    civico: civico,
    comune: comune,
    provincia: provincia,
    cap: cap,
    piva: piva,
    email: email,
    tariffa: tariffa,

  }, {
      headers: {
          'x-access-token' : cookies.token
      }
  },

  axios.post(`${window.$apiEndpoint}/invoice/update-C-App`, {
    codice_cliente: codice_cliente,
    fname: fname,
    lname: lname,

  }, {
      headers: {
          'x-access-token' : cookies.token
      }
  })

  )
      .then(res => {
            setSucc1('Cliente modifcato con successo, a breve la pagina verrà ricaricata.')
            setTimeout(() => {setDeleted(true)},2000)




      }).catch(err => {
        setErr1('Erore - ' + err.message)
      })
    }
}



  if(deleted) {
    return <Redirect to={`/clients`} />;
  }

  //Conto le tasse sul pagato e non per sommare


  const aliquota_settata= (allSettings.aliquota / 100)
  const tasse_sul_pagato = (allPaInvoice.Pagato * aliquota_settata)
  const Totale_Pagato = (allPaInvoice.Pagato + tasse_sul_pagato)
  const Totale_Pagato_Decimali  = Math.round(Totale_Pagato * 100) / 100
  const tasse_sul_pagato_decimale  = Math.round(tasse_sul_pagato * 100) / 100


  const tasse_sul_non_pagato = (allUnInvoice.NonPagato * aliquota_settata)
  const Totale_Non_Pagato = (allUnInvoice.NonPagato + tasse_sul_non_pagato)
  const Totale_Non_Pagato_Decimali  = Math.round(Totale_Non_Pagato * 100) / 100
  const tasse_sul_non_pagato_decimale  = Math.round(tasse_sul_non_pagato * 100) / 100

  return (

  <>

  {loading ? <>

    <SectionTitle title="DETTAGLI" subtitle="Profilo cliente" />

    <ReactTooltip />


    <Container fluid>
                        <Row>
                        <Col className="card border-left-warning  shadow h-100 py-6 px-4" xs={12}>
                        { tipologia == 0 ? <div className="flex flex-row items-center justify-start p-4">

                        <div className="flex-shrink-0 w-24">
                        <Avatar>{avatar}</Avatar>
                        </div>
                        <div className="py-2 px-2">
                        <p className="text-base font-semibold whitespace-no-wrap">{ allUsers.fname } {allUsers.lname}</p>
                        <p className="text-base font-light whitespace-no-wrap">Codice cliente: <span className="font-semibold">{ allUsers.codice_cliente }</span></p>
                        <p className="text-base font-light whitespace-no-wrap">Tariffa:  <span className="font-semibold">{ allUsers.tariffa } €</span></p>
                        <p className="text-base font-light whitespace-no-wrap">Tipologia Cliente: <span className="font-semibold">Privato</span></p>

                        </div>
                        <div className="flex-shrink-0 ml-auto">
                        <Avatar>€</Avatar>
                        </div>
                        <div className="py-2 px-5" >

                        <p className="text-base font-semibold whitespace-no-wrap">Pagamenti</p>
                        <p className="text-base font-light">Saldato ad Oggi: <span className="font-semibold">{ Totale_Pagato_Decimali } €</span></p>
                        <p> (Imponibile:  {allPaInvoice.Pagato} € )  (Tasse:  {tasse_sul_pagato_decimale} € ) </p>

                        <p className="text-base font-light whitespace-no-wrap">Da saldare ad Oggi:  <span className="font-semibold">{ Totale_Non_Pagato_Decimali } €</span></p>
                        <p> (Imponibile:  {allUnInvoice.NonPagato} € )  (Tasse:  {tasse_sul_non_pagato_decimale} € ) </p>
                        </div>
                        <div className="ml-auto flex-shrink-0 space-x-2 hidden lg:flex">
                            <p data-tip="Elimina cliente">
                            <Button
                            size="sm"
                            type="submit"
                            variant="danger"
                            style={{
                            borderRadius: '2rem',
                            paddingLeft: '1.2rem',
                            paddingRight: '1.2rem',
                            paddingTop: '0.7rem',
                            paddingBottom: '0.7rem',
                            }}
                            onClick={deleteUser}
                            >
                            <FaTrash></FaTrash>
                            </Button>
                            </p>

                          </div>
                          </div> : tipologia == 1 ?
                          <div className="flex flex-row items-center justify-start p-4">

                          <div className="flex-shrink-0 w-24">
                          <Avatar>{avatar}</Avatar>
                          </div>
                          <div className="py-2 px-2">
                          <p className="text-base font-semibold whitespace-no-wrap">{ allUsers.fname } {allUsers.lname} - <small> Azienda: { allUsers.ragione_sociale } </small></p>
                          <p className="text-base font-light whitespace-no-wrap">Codice cliente: <span className="font-semibold">{ allUsers.codice_cliente }</span></p>
                          <p className="text-base font-light whitespace-no-wrap">Tariffa:  <span className="font-semibold">{ allUsers.tariffa } €</span></p>
                          <p className="text-base font-light whitespace-no-wrap">Tipologia Cliente: <span className="font-semibold">Partita Iva (Azienda)</span></p>

                          </div>
                          <div className="flex-shrink-0 ml-auto">
                          <Avatar>€</Avatar>
                          </div>
                          <div className="py-2 px-5" >

                          <p className="text-base font-semibold whitespace-no-wrap">Pagamenti</p>
                          <p className="text-base font-light">Saldato ad Oggi: <span className="font-semibold">{ Totale_Pagato_Decimali } €</span></p>
                          <p> (Imponibile:  {allPaInvoice.Pagato} € )  (Tasse:  {tasse_sul_pagato_decimale} € ) </p>

                          <p className="text-base font-light whitespace-no-wrap">Da saldare ad Oggi:  <span className="font-semibold">{ Totale_Non_Pagato_Decimali } €</span></p>
                          <p> (Imponibile:  {allUnInvoice.NonPagato} € )  (Tasse:  {tasse_sul_non_pagato_decimale} € ) </p>
                          </div>
                          <div className="ml-auto flex-shrink-0 space-x-2 hidden lg:flex">
                              <p data-tip="Elimina cliente">
                              <Button
                              size="sm"
                              type="submit"
                              variant="danger"
                              style={{
                              borderRadius: '2rem',
                              paddingLeft: '1.2rem',
                              paddingRight: '1.2rem',
                              paddingTop: '0.7rem',
                              paddingBottom: '0.7rem',
                              }}
                              onClick={deleteUser}
                              >
                              <FaTrash></FaTrash>
                              </Button>
                              </p>

                            </div>
                            </div>
                          :
                          null
                          }

                      <div>
                      <p className="my-2" style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                      <p className="my-2" style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>
                        <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => changeTab(k)}>

                                    <Tab eventKey="details" title={<span style={{ color: '#424242'}}>Dati</span>}>
                                      <div className="py-12 w-full">

                                { tipologia == 0 ?      <Form onSubmit={handleSubmit(onSubmit)}>

                        <Form.Row>

                                   <Form.Control type="hidden" name="codice_cliente" ref={register({ required: true })} defaultValue={ allUsers.codice_cliente } />


                                    <Form.Group  controlId="fname" as={Col} >
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control type="text" name="fname" ref={register({ required: true })} defaultValue={ allUsers.fname } />
                                    {errors.fname && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                    <Form.Group  controlId="lname" as={Col} >
                                    <Form.Label>Cognome</Form.Label>
                                    <Form.Control type="text" name="lname" ref={register({ required: true })} defaultValue={ allUsers.lname } />
                                    {errors.lname && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>


                                </Form.Row>

                                <Form.Row>


                                    <Form.Group  controlId="strada" as={Col} >
                                    <Form.Label>Via</Form.Label>
                                    <Form.Control type="text" name="strada" ref={register({  })} defaultValue={ allUsers.strada } />
                                    {errors.strada && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                    <Form.Group  controlId="civico" as={Col} >
                                    <Form.Label>Civico</Form.Label>
                                    <Form.Control  type="text" name="civico" ref={register({  })} defaultValue={ allUsers.civico } />
                                    {errors.civico && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                </Form.Row>

                                <Form.Row>


                                    <Form.Group  controlId="comune" as={Col} >
                                    <Form.Label>Comune</Form.Label>
                                    <Form.Control type="text" name="comune" ref={register({  })} defaultValue={ allUsers.comune } />
                                    {errors.comune && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                    <Form.Group  controlId="provincia" as={Col} >
                                    <Form.Label>Provincia</Form.Label>
                                    <Form.Control  type="text" name="provincia" maxlength="2" ref={register({  })} defaultValue={ allUsers.provincia } />
                                    {errors.provincia && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                </Form.Row>

                                <Form.Row>


                                    <Form.Group  controlId="cap" as={Col} >
                                    <Form.Label>Cap</Form.Label>
                                    <Form.Control type="text" name="cap" ref={register({  })} defaultValue={ allUsers.cap } />
                                    {errors.cap && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                    <Form.Group  controlId="codice_fiscale" as={Col} >
                                    <Form.Label>Codice Fiscale</Form.Label>
                                    <Form.Control  type="text" name="codice_fiscale" ref={register({  })} defaultValue={ allUsers.codice_fiscale } />
                                    {errors.codice_fiscale && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                </Form.Row>

                                <Form.Row>


                                    <Form.Group  controlId="email" as={Col} >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name="email" ref={register({  })} defaultValue={ allUsers.email } />
                                    {errors.email && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                    <Form.Group  controlId="tariffa" as={Col} >
                                    <Form.Label>Tariffa (€/ora)</Form.Label>
                                    <Form.Control  type="text" name="tariffa" ref={register({ required: true })} defaultValue={ allUsers.tariffa } />
                                    <small> Inserire numero con il punto es. 39.50</small>
                                    {errors.tariffa && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                </Form.Row>
                                <p style={{ marginTop: '1vh', fontWeight: 'bold', color: 'red' }}>{showErr1}</p>
                                <p style={{ marginTop: '1vh', fontWeight: 'bold', color: 'green' }}>{showSucc1}</p>


                                <Button variant="info" size="md" type="submit" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    Modifica Utente
                                </Button>

                                    </Form> : tipologia == 1 ?
                                    <Form onSubmit={handleSubmit(onSubmitIva)}>

                            <Form.Row>

                                       <Form.Control type="hidden" name="codice_cliente" ref={register({ required: true })} defaultValue={ allUsers.codice_cliente } />


                                        <Form.Group  controlId="fname" as={Col} >
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" name="fname" ref={register({ required: true })} defaultValue={ allUsers.fname } />
                                        {errors.fname && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="lname" as={Col} >
                                        <Form.Label>Cognome</Form.Label>
                                        <Form.Control type="text" name="lname" ref={register({ required: true })} defaultValue={ allUsers.lname } />
                                        {errors.lname && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>


                                    </Form.Row>

                                    <Form.Row>


                                        <Form.Group  controlId="strada" as={Col} >
                                        <Form.Label>Via</Form.Label>
                                        <Form.Control type="text" name="strada" ref={register({  })} defaultValue={ allUsers.strada } />
                                        {errors.strada && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="civico" as={Col} >
                                        <Form.Label>Civico</Form.Label>
                                        <Form.Control  type="text" name="civico" ref={register({  })} defaultValue={ allUsers.civico } />
                                        {errors.civico && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                    </Form.Row>

                                    <Form.Row>


                                        <Form.Group  controlId="comune" as={Col} >
                                        <Form.Label>Comune</Form.Label>
                                        <Form.Control type="text" name="comune" ref={register({  })} defaultValue={ allUsers.comune } />
                                        {errors.comune && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="provincia" as={Col} >
                                        <Form.Label>Provincia</Form.Label>
                                        <Form.Control  type="text" name="provincia" maxlength="2" ref={register({  })} defaultValue={ allUsers.provincia } />
                                        {errors.provincia && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                    </Form.Row>

                                    <Form.Row>


                                        <Form.Group  controlId="cap" as={Col} >
                                        <Form.Label>Cap</Form.Label>
                                        <Form.Control type="text" name="cap" ref={register({  })} defaultValue={ allUsers.cap } />
                                        {errors.cap && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="piva" as={Col} >
                                        <Form.Label>Partita Iva</Form.Label>
                                        <Form.Control  type="text" name="piva" ref={register({  })} defaultValue={ allUsers.p_iva } />
                                        <small>Inserire solo numerazione senza prefisso internazionale ad esempio IT</small>
                                        {errors.piva && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                    </Form.Row>

                                    <Form.Row>


                                        <Form.Group  controlId="pec" as={Col} >
                                        <Form.Label>Indirzzo Pec</Form.Label>
                                        <Form.Control type="text" name="pec" ref={register({  })} defaultValue={ allUsers.pec } />
                                        {errors.pec && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="sdi" as={Col} >
                                        <Form.Label>Codice Destinatario</Form.Label>
                                        <Form.Control  type="text" name="sdi" ref={register({  })} defaultValue={ allUsers.codice_sdi } />
                                        {errors.sdi && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                    </Form.Row>

                                    <Form.Row>


                                        <Form.Group  controlId="email" as={Col} >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" name="email" ref={register({  })} defaultValue={ allUsers.email } />
                                        {errors.email && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="tariffa" as={Col} >
                                        <Form.Label>Tariffa (€/ora)</Form.Label>
                                        <Form.Control  type="text" name="tariffa" ref={register({ required: true })} defaultValue={ allUsers.tariffa } />
                                        <small> Inserire numero con il punto es. 39.50</small>
                                        {errors.tariffa && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                    </Form.Row>
                                    <p style={{ marginTop: '1vh', fontWeight: 'bold', color: 'red' }}>{showErr1}</p>
                                    <p style={{ marginTop: '1vh', fontWeight: 'bold', color: 'green' }}>{showSucc1}</p>


                                    <Button variant="info" size="md" type="submit" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                        Modifica Utente
                                    </Button>

                                        </Form>
                                    :
                                    null}

                                      </div>
                                     </Tab>

                                    <Tab eventKey="logs" title={<span style={{ color: '#424242'}}>Appuntamenti Pagati</span>}>


                                    {loading ? <MuiThemeProvider theme={theme}>
                                    <MUIDataTable
                                    title={""}
                                    data={allApps}
                                    columns={columns}
                                    options={options}
                                    /></MuiThemeProvider>: <center><Spinner animation="border" /></center>}

                                    </Tab>




                                    <Tab eventKey="app-no" title={<span style={{ color: '#424242'}}>Appuntamenti da Pagare</span>}>


                                    {loading ? <MuiThemeProvider theme={theme}>
                                    <MUIDataTable
                                    title={""}
                                    data={allAppsUn}
                                    columns={columns}
                                    options={options}
                                    /></MuiThemeProvider>: <center><Spinner animation="border" /></center>}

                                    </Tab>

                                    <Tab eventKey="invoice" title={<span style={{ color: '#424242'}}>Fatture</span>}>

                                    {loading ? <MuiThemeProvider theme={theme}>
                                    <MUIDataTable
                                    title={""}
                                    data={allInvoice}
                                    columns={columns1}
                                    options={options1}
                                    /></MuiThemeProvider>: <center><Spinner animation="border" /></center>}


                                    </Tab>


                                    <Tab eventKey="preventivi" title={<span style={{ color: '#424242'}}>Preventivi</span>}>

                                    {loading ? <MuiThemeProvider theme={theme}>
                                    <MUIDataTable
                                    title={""}
                                    data={allPreventivi}
                                    columns={columns2}
                                    options={options2}
                                    /></MuiThemeProvider>: <center><Spinner animation="border" /></center>}


                                    </Tab>

                                    </Tabs>


                      </div>




                          </Col>

                          </Row>



                        </Container>
      </> : <center><Spinner animation="border" /></center>}
  </>
)
}

export default UserProfile;
