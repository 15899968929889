import React, { useState } from 'react'
import {Link, Redirect} from 'react-router-dom'
import Layout from '../layouts/centered'
import CenteredForm from '../layouts/centered-form'
import axios from 'axios';
import { useAuth } from "../context/auth";
import { useForm } from 'react-hook-form'
import { setCookie } from 'nookies'

function Index(props) {

  // Managin login

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const { setAuthTokens } = useAuth();


        const { register, handleSubmit, errors } = useForm();
        const onSubmit = data => {
        const email = data.email;
        const password = data.password;

        axios.post(`${window.$apiEndpoint}/auth/login`, {
          email: email,
          password: password
        }).then(result => {
          if (result.data.auth === true) {
            setAuthTokens(result.data);
            setCookie(null, 'token', result.data.token , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            setCookie(null, 'fname', result.data.fname , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            setCookie(null, 'lname', result.data.lname , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            setCookie(null, 'email', result.data.email , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            setCookie(null, 'role', result.data.role , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            const expiration = new Date(new Date().getTime() + 1000 * 60 * 60 * 12);
            setCookie(null, 'expiration', expiration, {
              maxAge: 30 * 24 * 60 * 60,
            })
            setLoggedIn(true);
          } else {
            setIsError(true);
          }
        }).catch(e => {
          setIsError(true);
        });
    }

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  // ./Managing Login
  return (
    <Layout>
      <CenteredForm
        title="Accedi"
        subtitle="Inserisci il tuo nome utente e la password per accedere"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="font-bold block mb-3 h-5 w-full text-lg	">
            Username
          </label>
          <input
            size="sm"
            type="text"
            name="email"
            placeholder="Inserisci l'indirizzo email"
            ref={register({ required: true })}
            className="shadow-sm rounded-full block mb-3 w-full h-10 p-3 animate-pulse"
          />
          {errors.email && <p>Il campo è obbligatorio</p>}
          <label className="font-bold block mb-3 w-full h-5 text-lg	">
            Password
          </label>
          <input
            size="sm"
            type="password"
            name="password"
            placeholder="Inserire la password"
            ref={register({ required: true })}
            className="shadow-sm rounded-full block mb-3 w-full h-10 p-3	"
          />
          {errors.password && <p>Il campo è obbligatorio</p>}
          <button
            variant="primary"
            type="submit"
            className="shadow rounded-full px-8 py-3 font-bold block mb-3	w-full"
            style={{
              backgroundColor: "#0197F6",
              color: "#fff",
            }}
          >
            ACCEDI
          </button>
        </form>

        <br></br>
        {isError && (
          <p>Il nome utente o la password forniti non erano corretti!</p>
        )}
        <br />

        <div className="flex flex-row items-center justify-between w-full text-xs z-10">
          <div className="flex flex-row ml-auto">
            <div className="px-1">
            </div>
          </div>
        </div>
      </CenteredForm>
    </Layout>
  );
}

export default Index
