import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import Section from '../components/dashboard/section'
import Select, { components } from 'react-select';
import {Spinner, Alert, Modal} from 'react-bootstrap'
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import MUIDataTable from 'mui-datatables';
import axios from 'axios'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Bar, Line } from 'react-chartjs-2'
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus, faFileInvoice, faPaperPlane, faInfoCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button , Container, Row, Col} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { Redirect } from 'react-router-dom'
// import the react-json-view component
import ReactJson from 'react-json-view'


const bodyScrollLock = require('body-scroll-lock');





const { parseCookies } = require('nookies');





const Fatt_elettr = () => {
  //Setto gli state
  const [allInvoice, viewInvoice] = useState([{}])
  const [allYears, setAllYears] = useState([{}])
  const [loading, setLoading] = useState(false)
  const [allCostumers, viewCostumers] = useState('')
  const [allUsersC, viewUsersC] = useState('')
  const [allApp, viewApp] = useState('')
  const cookies = parseCookies()
  const history = useHistory()
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);
  const [loadingAnno, setLoadingAnno] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [messaggioSDI, setmessaggioSDI] = useState([{}])
  const [modalShow1, setModalShow1] = useState(false);
  const [messaggioFatturaSDI, setmessaggioFatturaSDI] = useState([{}])
  const [modalShow2, setModalShow2] = useState(false);
  const [messaggioCopia, setmessaggioCopia] = useState([{}])

  const [ndaInviare, setndaInviare] = useState([{}])
  const [nInviate, setnInviate] = useState([{}])
  const [nInviateErrore, setnInviateErrore] = useState([{}])



  //Quando Seleziono

 const handleChange = selectedOption => {
    setSelectedOption(selectedOption.value);
    setSelectedOptionLabel(selectedOption.label);

    //Carico Dati
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/invoice/fetchInvoicesEle/${selectedOption.value}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
          viewInvoice(users.data)
          setLoading(true)

      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();

    //Setto lo vista
    setLoadingAnno(true)

    //Conto
    contaInviateconSuccesso(selectedOption.value)

    //Conto

    contaInviateconErrore(selectedOption.value)

    //Controllo
    contaDaInviare(selectedOption.value)

}

  //Recupero i dati dal db
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/invoice/fetchInvoicesEle/${selectedOption}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewInvoice(users.data)
            setLoading(true)
        }
      } catch (e) {
        console.log(e)
      }

    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])



  //Recupero i dati dal db per i clienti totali
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const costumers = await axios.get(`${window.$apiEndpoint}/users/fetchCclients`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewCostumers(costumers.data[0].NumberCostumers)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Recupero i dati dal db per i utenti totali
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const usersC = await axios.get(`${window.$apiEndpoint}/users/fetchCusers`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsersC(usersC.data[0].NumberUsers)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Recupero i dati dal db per appuntamenti totali
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const usersAllApp = await axios.get(`${window.$apiEndpoint}/appoiments/fetchAllCount`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewApp(usersAllApp.data[0].NumberAppoinments)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])


  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  disableBodyScroll(Fatt_elettr);
  console.log()

  //Configuro le colonne della tabella
  const columns = ["Numero Fattura","Codice Cliente", "Aliquota", "Data Creazione", "Nome", "Cognome", "Tipologia", "Codice Fiscale/Partita Iva", {
    label: "Stato della Fattura Elettronica",
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              <ReactTooltip html={true} />
              {value == 0 ?
                <Alert data-tip="Fattura da Inviare al sistema di interscambio </br> per la generazione della </br> fatturazione elettronica!" key={value} variant="warning">
                <center><FontAwesomeIcon style={{ color: '#dfb43c',}} className='fa-1x text-gray-300' icon={faInfoCircle} >
              </FontAwesomeIcon>  Da Inviare!</center>
                </Alert>
              : value == 1 ?
              <Alert data-tip="Fattura Inviata e consegnata</br> con <strong>successo</strong> al sistema di </br>interscambio SDI!" key={value} variant="success">
              <center><FontAwesomeIcon style={{ color: 'green',}} className='fa-1x text-gray-300' icon={faInfoCircle} >
              </FontAwesomeIcon>  Inviata & Consegnata!</center>
              </Alert>
              : value == 2 ?

              <Alert data-tip="Fattura Inviata in attesa di conferma consegna!" key={value} variant="info">
              <center><FontAwesomeIcon style={{ color: '#055160',}} className='fa-1x text-gray-300' icon={faInfoCircle} >
              </FontAwesomeIcon> Inviata in attesa!</center>
              </Alert>
              : value == 3 ?
              <Alert data-tip="Fattura Inviata ma non consegnata perchè  </br> erano presenti degli errori o mancavano dei dati!" key={value} variant="danger">
              <center><FontAwesomeIcon style={{ color: 'red',}} className='fa-1x text-gray-300' icon={faInfoCircle} >
              </FontAwesomeIcon> Errore nell'Invio!</center>
              </Alert>
              :
              null
              }
              </>
            )
        }
    }
} , {
    label: "Azioni",
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //Mettere qui la funzione per disabilitare il tasto quando lo stato è gia successo

          const myPath = value.split('/', 4)
          const SatoFattura = myPath[3]


            return (
              <>
              {/* Controllo lo stato e se necessario disabilito bottone per l'invio */}

              <ReactTooltip html={true} />

              {SatoFattura == 0 ? <Button data-tip="Inizia la procedura per generare la fattura Elettronica" onClick={() => creaFatturaElettronica(value) } variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faPaperPlane} />
              </Button>
              : SatoFattura == 3 ?
              <Button data-tip="Accertati di aver corretto o aggiunto i dati mancati </br> e in seguito clicca per rinviare la fattura!" onClick={() => creaFatturaElettronica(value) } variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faPaperPlane} />
              </Button>
              : SatoFattura == 1 ?
              <Button data-tip="Inizia la procedura per generare la fattura Elettronica" onClick={() => creaFatturaElettronica(value) } variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} disabled>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faPaperPlane} />
              </Button>

              : SatoFattura == 2 ?
              <Button data-tip="Inizia la procedura per generare la fattura Elettronica" onClick={() => creaFatturaElettronica(value) } variant="info" size="md" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} disabled>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faPaperPlane} />
              </Button>
              :
              null
              }

              <Button data-tip="Modifica Informazioni Cliente" onClick={() => modificaCliente(value) } variant="info" size="md" style={{ backgroundColor: "#f1c31d", borderColor: "#f1c31d", fontWeight: '600', marginLeft: 10}}>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faEdit} />
              </Button>
              </>
            )
        }
    }
}, {
    label: "Messaggi SDI",
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //Mettere qui la funzione per disabilitare il tasto quando lo stato è gia successo


            return (
              <>
              {/* Controllo lo stato e se necessario disabilito bottone per l'invio */}



              <ReactTooltip html={true} />

              {value ? <Button data-tip="Leggi Messaggi di notifica SDI" onClick={() => openmodaleRicezione(value) } variant="info" size="md" style={{ backgroundColor: "#45ad17", borderColor: "#45ad17", fontWeight: '600', marginLeft: 10}}>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faInfoCircle} />
              </Button> :
              <Button data-tip="Leggi Messaggi di notifica SDI" onClick={() => openmodaleRicezione(value) } variant="info" size="md" style={{ backgroundColor: "#45ad17", borderColor: "#45ad17", fontWeight: '600', marginLeft: 10}} disabled>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faInfoCircle} />
              </Button>

              }

              {value ? <Button data-tip="Leggi il contenito della Fattura inviata allo SDI" onClick={() => openmodaleRicezioneFattura(value) } variant="info" size="md" style={{ backgroundColor: "#1f41a0", borderColor: "#1f41a0", fontWeight: '600', marginLeft: 10}}>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faInfoCircle} />
              </Button> :
              <Button data-tip="Leggi il contenito della Fattura inviata allo SDI" onClick={() => openmodaleRicezioneFattura(value) } variant="info" size="md" style={{ backgroundColor: "#1f41a0", borderColor: "#1f41a0", fontWeight: '600', marginLeft: 10}} disabled>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faInfoCircle} />
              </Button>
              }

              {value ? <Button data-tip="Leggi Ricevuta di avvenutata conservazione </br> sostitutiva" onClick={() => openmodaleCopia(value) } variant="info" size="md" style={{ backgroundColor: "#a83d00", borderColor: "#a83d00", fontWeight: '600', marginLeft: 10}}>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faInfoCircle} />
              </Button> :
              <Button data-tip="Leggi Ricevuta di avvenutata conservazione </br> sostitutiva" onClick={() => openmodaleCopia(value) } variant="info" size="md" style={{ backgroundColor: "#a83d00", borderColor: "#a83d00", fontWeight: '600', marginLeft: 10}} disabled>
                <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300' icon={faInfoCircle} />
              </Button>
              }
              </>
            )
        }
    }
}];
  const options = {
    filter: false,
    selectableRows: false,
    sortOrder: {
    name: 'Numero Fattura',
    direction: 'desc'
    },
    responsive: 'stacked',
    filterType: "dropdown",
    rowsPerPage: 5,
    /*onRowClick:( rowData, rowState)  => {
      console.log(rowData[3])
      history.push('/user-profile/'+rowData[3])
    },*/
    textLabels: {
      body: {
        noMatch: "Spiacenti, nessun record corrispondente trovato",
        toolTip: "Ordinare",
        columnHeaderTooltip: column => `Ordinare per ${column.label}`
      },
      pagination: {
          next: "Pagina successiva",
          previous: "Pagina Precedente",
          rowsPerPage: "Righe per pagina:",
          displayRows: "of",
        },
        toolbar: {
          search: "Cerca",
          downloadCsv: "Scarica CSV",
          print: "Stampa",
          viewColumns: "Vedi Collonne",
          filterTable: "Tabella dei filtri",
        },
        filter: {
          all: "Tutto",
          title: "FILTRI",
          reset: "RIPRISTINA",
        },
        viewColumns: {
          title: "Mostra colonne",
          titleAria: "Mostra / Nascondi colonne tabella",
        },
  }
  };

  const theme = createMuiTheme({
    overrides: {
      MUIDataTableHeadCell:{
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',

        },
      },
      MuiIconButton: {
        root: {
          color: 'grey',

        },
      },
      MuiTableCell:{
        body: {
            backgroundColor: '#00968817',

          },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },

      MuiButton: {
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },
      MuiTypography: {
        body2: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },
      MuiMenuItem: {
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },
      MuiTableCell: {
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '400',
      },
    },
    MUIDataTableToolbar: {
      hover: {
        color: '#616161',
    },
  },
    },
  });

  const ErrorChart = ({ data }) => {
    return (
      <div>
        <Bar data={data}>
        </Bar>
      </div>
    );

  }


  //Recupero i dati dal db
useEffect(() => {
let mounted = true
const fetch = async () => {
  const users = await axios.get(`${window.$apiEndpoint}/invoice/fetchYearsInvoice`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {
    if(mounted){
      setAllYears(users.data)
    }
  } catch (e) {
    console.log(e)
  }
  setLoading(true)
}
fetch();
return () => {
  mounted = false
}
}, [cookies.token])



//Funzione per Generare la fattura elettronica
const creaFatturaElettronica = (path) => {

  //Ricavo il codice cliente dalla stringa

  const myPath = path.split('/', 4)
  const codiceClienteStrip = myPath[0]
  const NumeroFatturaStrip = myPath[1]
  const NumeroAnnoStrip = myPath[2]

//Invio alla pagina per la fatturazione Elettronica
history.push(`/gen-invoiceE/${codiceClienteStrip}/${NumeroFatturaStrip}/${NumeroAnnoStrip}`);

}


//Funzione per inviare alla scheda Cliente per procedere alla modifica dei dati
const modificaCliente = (codice_cliente) => {

//Ricavo il codice cliente dalla stringa

const myPath = codice_cliente.split('/', 1)
const codiceClienteStrip = myPath[0]

//Invio a scheda Cliente
history.push(`/user-profile/${codiceClienteStrip}`);
}


function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Messaggio di Risposta (SDI)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <small><strong>RC</strong> Ricevuta di consegna</small>
      <small style={{marginLeft: 10}}><strong>MC</strong> Notifica di mancata consegna</small>
      <small style={{marginLeft: 10}} ><strong>NS</strong> Notifica di scarto</small>
      <p style={{marginTop: 10}}>Quando la fattura è nello stato <strong>"Inviato in attesa"</strong> di seguito compare un errore perchè non esiste ancora alcuna notifica! Si prega di attendere..</p>

      <ReactJson style={{padding: 15, marginTop: 10}} theme="ocean" displayDataTypes={false} displayObjectSize={false} src={messaggioSDI} />
      </Modal.Body>
      <Modal.Footer>
      <Button style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} onClick={props.onHide}>Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal2(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Intero contenuto fattura presentata al SDI
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ReactJson style={{padding: 15}} theme="ocean" displayDataTypes={false} displayObjectSize={false} src={messaggioFatturaSDI} />
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} onClick={props.onHide}>Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal3(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Notifica di avvenuta conservazione sostitutiva
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ReactJson style={{padding: 15}} theme="ocean" displayDataTypes={false} displayObjectSize={false} src={messaggioCopia} />
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}} onClick={props.onHide}>Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
}

//Funzione per recuperare e pubblicare messaggio SDI nel modale

const openmodaleRicezione = (uuid) => {

//Recupero Mesaggio dal Database
const fetch = async () => {
  const users = await axios.get(`${window.$apiEndpoint}/invoice/fecthMessageSDI/${uuid}`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {

    //Converto array
    const newArray = users.data
    setmessaggioSDI(newArray)

    //Apro il modale
    setModalShow(true)

  } catch (e) {
    console.log(e)
  }
  setLoading(true)
}
fetch();


}


//Funzione per recuperare e pubblicare messaggio corpo Fattura Inviata

const openmodaleRicezioneFattura = (uuid) => {

//Recupero Mesaggio dal Database
const fetch = async () => {
  const users = await axios.get(`${window.$apiEndpoint}/invoice/fecthMessageFatturaSDI/${uuid}`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {

    //Converto array
    const newArray = users.data;
    setmessaggioFatturaSDI(newArray)

    //Apro il modale
    setModalShow1(true)

  } catch (e) {
    console.log(e)
  }
  setLoading(true)
}
fetch();


}

//Funzione per recuperare e pubblicare messaggio copia sostituiva

const openmodaleCopia = (uuid) => {

//Recupero Mesaggio dal Database
const fetch = async () => {
  const users = await axios.get(`${window.$apiEndpoint}/invoice/fecthMessageCopia/${uuid}`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {

    //Converto array
    const newArray = users.data
    setmessaggioCopia(newArray)

    //Apro il modale
    setModalShow2(true)

  } catch (e) {
    console.log(e)
  }
  setLoading(true)
}
fetch();


}


const contaInviateconSuccesso = (anno) => {

  //Carico Dati
  const fetch = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/invoice/fattureInviateConSuccesso/${anno}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        setnInviate(users.data[0])
        setLoading(true)

    } catch (e) {
      console.log(e)
    }
  }
  fetch();

}


const contaInviateconErrore = (anno) => {

  //Carico Dati
  const fetch = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/invoice/fattureInviateConErrore/${anno}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        setnInviateErrore(users.data[0])

    } catch (e) {
      console.log(e)
    }
    setLoading(true)
  }
  fetch();


}


const contaDaInviare = (anno) => {

  //Carico Dati
  const fetch = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/invoice/fattureDaInviare/${anno}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        setndaInviare(users.data[0])
        console.log(users.data[0])
        setLoading(true)

    } catch (e) {
      console.log(e)
    }
    setLoading(true)
  }
  fetch();
}



  return (
    <>

    {loadingAnno ?  <Container fluid>
    <span style={{ fontSize: "1.4vh", fontWeight: "400", marginTop: '2vh', paddingBottom: '0.3vh'}}>Situazione Anno <strong>{selectedOption}</strong></span>
    <Row className='mt-1 mb-2'>

      <Col md={4} className="my-2">

        <div className="card border-left-primary shadow h-100 py-2">
        <div className="card-body">
        <div className="row no-gutters align-items-center">
        <div className="col mr-2">
        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
        Fatture Inviate con Successo</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">{loading ?
        <p>{nInviate[0].fatture}</p> : <center><Spinner animation="border" /></center>}</div>
        </div>
        <div className="col-auto">
        <FontAwesomeIcon style={{ color: 'grey',}} className='fa-2x text-gray-300' icon={faFileInvoice} />
        </div>
        </div>
        </div>
        </div>
      </Col>
      <Col md={4} className="my-2">

      <div className="card border-left-success shadow h-100 py-2">
      <div className="card-body">
      <div className="row no-gutters align-items-center">
      <div className="col mr-2">
      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
      FATTURE NON INVIATE (DA INVIARE)</div>
      <div className="h5 mb-0 font-weight-bold text-gray-800">{loading ?
      <p>{ndaInviare[0].fatture}</p> : <center><Spinner animation="border" /></center>}</div>
      </div>
      <div className="col-auto">
      <FontAwesomeIcon style={{ color: 'grey',}} className='fa-2x text-gray-300' icon={faFileInvoice} />
      </div>
      </div>
      </div>
      </div>
      </Col>

      <Col md={4} className="my-2">
      <div className="card border-left-warning shadow h-100 py-2">
      <div className="card-body">
      <div className="row no-gutters align-items-center">
      <div className="col mr-2">
      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Fatture inviate con errore (da gestire)</div>
      <div className="h5 mb-0 font-weight-bold text-gray-800">{loading ?
      <p>{nInviateErrore[0].fatture}</p> : <center><Spinner animation="border" /></center>}</div>
      </div>
      <div className="col-auto">
      <FontAwesomeIcon style={{ color: 'grey',}} className='fa-2x text-gray-300' icon={faFileInvoice} />
      </div>
      </div>
      </div>
      </div>
      </Col>
    </Row>
  </Container> : null}

    <Container fluid>
            <Row>
            <Col xs={12}>
            <span style={{ fontSize: "1.4vh", fontWeight: "400", marginTop: '2vh', paddingBottom: '1vh'}}>Seleziona Anno di Fatturazione </span>
            <p></p>
            {loading ?
            <Select value={selectedOption} onChange={handleChange} options={allYears} />
            : <center><Spinner size="sm" animation="border" variant="info" /></center>
            }
            <pre className="mt-2">Anno Selezionato: {selectedOptionLabel} </pre>
    </Col>
    </Row>
    </Container>


    {loadingAnno ? <Container fluid>
      <Row>
      <Col xs={12}>
      <div className="card border-left-warning mt-6 shadow h-100 py-2 px-4">

      <span style={{ fontSize: "2vh", fontWeight: "400", marginTop: '2vh',}}>Lista Fatture</span>
      <span style={{ fontSize: "1.2vh", fontWeight: "200"}}> Attenzione se avete appana inviato una fattura può volerci un po di tempo prima che il sistema si aggiorni con i nuovi dati</span>

              <div className="overflow-x-scroll lg:overflow-hidden">
                {loading ?
                <MuiThemeProvider theme={theme}>
                <MUIDataTable
                title={""}
                data={allInvoice}
                columns={columns}
                options={options}
                /></MuiThemeProvider>: <center><Spinner animation="border" /></center>}
              </div>
              </div>

              </Col>

          </Row>

    </Container> : null }


    <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <MyVerticallyCenteredModal2
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        />

        <MyVerticallyCenteredModal3
            show={modalShow2}
            onHide={() => setModalShow2(false)}
          />




    </>
  )
}
export default Fatt_elettr
