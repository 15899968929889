import React, { useState } from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Layouts from './layouts'
import Routes from './Routes'
import './css/tailwind.css'
import './css/main.css'
import './css/_components.css'
import './css/_layouts.css'
import './css/_palettes.css'
import 'bootstrap/dist/css/bootstrap.css';
import { AuthContext } from "./context/auth";
require('dotenv').config()
const dotenv=require('dotenv');


const Wrapper = ({children}) => {
  return <Layouts>{children}</Layouts>
}




const App = (props) => {

  // Managing auth
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }
  // ./Managing auth

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <Wrapper>
          <Routes />
        </Wrapper>
      </Router>
    </AuthContext.Provider>
  )
}
export default App
