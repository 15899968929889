import React, { useState } from 'react'
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies';

//Importo libreria per eseguire la selezione
import Select, { components } from 'react-select';


const AddUser = () => {
    //Setto gli state
    const [isCreated, setCreated] = useState(false)
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const [showSucc, setSucc] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()


    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);
    const [tipologia, setTipologia] = useState(null)

    //Chiamata post per inserimento cliente
    const onSubmit = data => {
        const email = data.email.toLowerCase().trim( );
        const fname = data.fname.toUpperCase().trim();
        const lname = data.lname.toUpperCase().trim();
        const strada = data.strada.toUpperCase().trim();
        const civico = data.civico.toUpperCase().trim();
        const comune = data.comune.toUpperCase().trim();
        const provincia = data.provincia.toUpperCase().trim();
        const cap = data.cap.toUpperCase().trim();
        const codicefiscale = data.codicefiscale.toUpperCase().trim();
        const tariffa = data.tariffa;

        axios.post(`${window.$apiEndpoint}/users/create`, {
            email: email,
            fname: fname,
            lname: lname,
            strada: strada,
            civico: civico,
            comune: comune,
            provincia: provincia,
            cap: cap,
            codicefiscale: codicefiscale,
            tariffa: tariffa,

        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
            .then(res => {
                setUser(res.data)
                //Setto un Timeout per la risposta
                setTimeout(() => {setCreated(true)},3000)
                setSucc('Cliente creato con successo, a breve verrete indirizzati sul profilo del cliente.')
            }).catch(err => {
                setErr('L\'indirizzo email risulta essere già esistente - ' + err.message)
            })
    }

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
        // redirect to home if signed up
        //Sleep 1
        return <Redirect to={`/user-profile/${user}`} />;
    }

    //Quando Seleziono

   const handleChange = selectedOption => {
      setSelectedOption(selectedOption.value);
      setSelectedOptionLabel(selectedOption.label);
      setTipologia(selectedOption.value)
      console.log(selectedOption.value)
      console.log(selectedOption.label)
    };

    //CreoArray per tipologi di cliente
    const arrayTipologiaCliente = [
    {
      value: 0,
      label: "Cliente Privato",
    },
    {
      value: 1,
      label: "Cliente Partita Iva (Azienda)",
    }]

    //Chiamata post per inserimento cliente Aziendale
    const onSubmitPiva = data => {
        const email = data.email.toLowerCase().trim( );
        const fname = data.fname.toUpperCase().trim();
        const lname = data.lname.toUpperCase().trim();
        const rsociale = data.rsociale.toUpperCase().trim();
        const sdi = data.sdi.toUpperCase().trim();
        const pec = data.pec.trim();
        const strada = data.strada.toUpperCase().trim();
        const civico = data.civico.toUpperCase().trim();
        const comune = data.comune.toUpperCase().trim();
        const provincia = data.provincia.toUpperCase().trim();
        const cap = data.cap.toUpperCase().trim();
        const piva = data.piva.toUpperCase().trim();
        const tariffa = data.tariffa;
        const tipo_cliente	= tipologia;

        axios.post(`${window.$apiEndpoint}/users/createPiva`, {
            email: email,
            fname: fname,
            lname: lname,
            rsociale: rsociale,
            sdi: sdi,
            pec: pec,
            strada: strada,
            civico: civico,
            comune: comune,
            provincia: provincia,
            cap: cap,
            piva: piva,
            tariffa: tariffa,
            tipo_cliente: tipo_cliente

        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
            .then(res => {
                setUser(res.data)
                //Setto un Timeout per la risposta
                setTimeout(() => {setCreated(true)},3000)
                setSucc('Cliente creato con successo, a breve verrete indirizzati sul profilo del cliente.')
            }).catch(err => {
                setErr('L\'indirizzo email risulta essere già esistente - ' + err.message)
            })
    }

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
        // redirect to home if signed up
        //Sleep 1
        return <Redirect to={`/user-profile/${user}`} />;
    }

    return (
        <>
            <SectionTitle title="Inserisci" subtitle="Crea nuovo cliente" />



                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">

                        {/*Eseguo la selezione della Tipologia di Cliente*/}

                        <Form.Label>Seleziona Tipologia Cliente:</Form.Label>
                        <Select value={selectedOption} onChange={handleChange} options={arrayTipologiaCliente} />
                        <pre className="mt-2">Tipologia Selezionata: {selectedOptionLabel} </pre>

                        { tipologia == 0 ? <Form onSubmit={handleSubmit(onSubmit)}>

                        {/* Cliente Privato */}
                                    <Form.Row>

                                        <Form.Group  controlId="email" as={Col} >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" name="email" ref={register()} placeholder="Inserisci Email" />
                                        {errors.email && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="fname" as={Col} >
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" name="fname" ref={register({ required: true })} placeholder="Inserisci Nome" />
                                        {errors.fname && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="lname" as={Col} >
                                        <Form.Label>Cognome</Form.Label>
                                        <Form.Control type="text" name="lname" ref={register({ required: true })} placeholder="Inserisci Cognome" />
                                        {errors.fname && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group  controlId="strada" as={Col} >
                                        <Form.Label>Via, Corso. Etc.</Form.Label>
                                        <Form.Control type="text" name="strada" ref={register()} placeholder="Es. Via Roma" />
                                        {errors.strada && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="civico" as={Col} >
                                        <Form.Label>Civico</Form.Label>
                                        <Form.Control type="text" name="civico" ref={register()} placeholder="Es. 100" />
                                        {errors.civico && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="comune" as={Col} >
                                        <Form.Label>Comune</Form.Label>
                                        <Form.Control type="text" name="comune" ref={register()} placeholder="Es. Cuneo" />
                                        {errors.comune && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="provincia" as={Col} >
                                        <Form.Label>Provincia</Form.Label>
                                        <Form.Control type="text" name="provincia" ref={register()} placeholder="Es. CN" />
                                        {errors.provincia && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="cap" as={Col} >
                                        <Form.Label>Cap</Form.Label>
                                        <Form.Control type="text" name="cap" ref={register()} placeholder="Es. 12023" />
                                        {errors.cap && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>

                                        <Form.Group  controlId="codicefiscale" as={Col} >
                                        <Form.Label>Codice Fiscale</Form.Label>
                                        <Form.Control type="text" name="codicefiscale" ref={register()} placeholder="Inserisci Codice Fiscale" />
                                        {errors.codicefiscale && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                        <Form.Group  controlId="tariffa" as={Col} >
                                        <Form.Label>Tariffa <strong style={{color: '#f55353'}}> (Inserire il punto come separatore numerico es. 39.50)</strong></Form.Label>
                                        <Form.Control type="text" name="tariffa" ref={register({ required: true })} placeholder="Inserisci Tariffa" />
                                        {errors.tariffa && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                    </Form.Row>
                                    <small>Il codice cliente verrá automaticamente generato in fase di creazione</small>
                                    <p className="my-2"  style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                    <p className="my-2"  style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>


                                    <Button variant="info" size="md" type="submit" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    Crea Cliente
                                </Button>
                                    </Form> : tipologia == 1 ?

                                    <Form onSubmit={handleSubmit(onSubmitPiva)}>

                                    {/* Cliente Partita Iva Azienda */}
                                                <Form.Row>

                                                    <Form.Group  controlId="email" as={Col} >
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" name="email" ref={register({ required: true })} placeholder="Inserisci Email" />
                                                    {errors.email && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                    <Form.Group  controlId="rsociale" as={Col} >
                                                    <Form.Label>Ragione Sociale</Form.Label>
                                                    <Form.Control type="text" name="rsociale" ref={register({ required: true })} placeholder="Inserisci Ragione Sociale" />
                                                    {errors.rsociale && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                    <Form.Group  controlId="sdi" as={Col} >
                                                    <Form.Label>Codice Destinatario</Form.Label>
                                                    <Form.Control type="text" name="sdi" ref={register({ required: true })} placeholder="Inserisci SDI" />
                                                    {errors.sdi && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                    <Form.Group  controlId="pec" as={Col} >
                                                    <Form.Label>Indirizzo Pec</Form.Label>
                                                    <Form.Control type="text" name="pec" ref={register({ required: true })} placeholder="Inserisci Indirizzo Pec" />
                                                    {errors.pec && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                    <Form.Group  controlId="fname" as={Col} >
                                                    <Form.Label>Nome</Form.Label>
                                                    <Form.Control type="text" name="fname" ref={register({ required: true })} placeholder="Inserisci Nome" />
                                                    {errors.fname && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                    <Form.Group  controlId="lname" as={Col} >
                                                    <Form.Label>Cognome</Form.Label>
                                                    <Form.Control type="text" name="lname" ref={register({ required: true })} placeholder="Inserisci Cognome" />
                                                    {errors.fname && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>


                                                </Form.Row>

                                                <Form.Row>
                                                    <Form.Group  controlId="strada" as={Col} >
                                                    <Form.Label>Via, Corso. Etc.</Form.Label>
                                                    <Form.Control type="text" name="strada" ref={register()} placeholder="Es. Via Roma" />
                                                    {errors.strada && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                    <Form.Group  controlId="civico" as={Col} >
                                                    <Form.Label>Civico</Form.Label>
                                                    <Form.Control type="text" name="civico" ref={register()} placeholder="Es. 100" />
                                                    {errors.civico && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                    <Form.Group  controlId="comune" as={Col} >
                                                    <Form.Label>Comune</Form.Label>
                                                    <Form.Control type="text" name="comune" ref={register()} placeholder="Es. Cuneo" />
                                                    {errors.comune && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                    <Form.Group  controlId="provincia" as={Col} >
                                                    <Form.Label>Provincia</Form.Label>
                                                    <Form.Control type="text" name="provincia" ref={register()} placeholder="Es. CN" />
                                                    {errors.provincia && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                    <Form.Group  controlId="cap" as={Col} >
                                                    <Form.Label>Cap</Form.Label>
                                                    <Form.Control type="text" name="cap" ref={register()} placeholder="Es. 12023" />
                                                    {errors.cap && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>

                                                    <Form.Group  controlId="piva" as={Col} >
                                                    <Form.Label>Partita Iva <strong style={{color: '#f55353'}}> (Inserire solo numerazione senza prefisso internazionale ad esempio IT)</strong></Form.Label>
                                                    <Form.Control type="text" name="piva" ref={register({ required: true })} placeholder="Inserisci Partita Iva" />
                                                    {errors.piva && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                    <Form.Group  controlId="tariffa" as={Col} >
                                                    <Form.Label>Tariffa <strong style={{color: '#f55353'}}> (Inserire il punto come separatore numerico es. 39.50)</strong></Form.Label>
                                                    <Form.Control type="text" name="tariffa" ref={register({ required: true })} placeholder="Inserisci Tariffa" />
                                                    {errors.tariffa && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                                    </Form.Group>

                                                </Form.Row>
                                                <small>Il codice cliente verrá automaticamente generato in fase di creazione</small>
                                                <p className="my-2"  style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                                <p className="my-2"  style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>


                                                <Button variant="info" size="md" type="submit" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                                Crea Cliente
                                            </Button>
                                                </Form>
                                                :
                                                null
                                                }
                        </div>

                                </Col>

                            </Row>

                        </Container>
        </>
    )
}
export default AddUser
