import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form, Dropdown, Table } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner, Alert} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";
import ReactTooltip from 'react-tooltip';
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus ,faFileInvoice, faCalendarWeek, faCalendarDay, faCalendarAlt, faCalendar, faSearch, faInfo, faFileAlt, faWindowClose, faPaperPlane, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pdf from "react-to-pdf";
import Logo from '../dist/img/logo.png'


const GeninvoiceE = (props) => {



 //GET DATA FROM BACKEND - SETTING STATES
 const [key, setKey] = useState('details');
 const [allUsers, viewUsers] = useState({})
 const [allInvoice, viewInvoice] = useState([{}])
 const [allApp, viewApp] = useState([{}])
 const [dataCreated, viewdataCreated] = useState('')
 const [allImporto, viewImporto] = useState('')
 const [allTasse, viewTasse] = useState('')
 const [allTotale, viewTotale] = useState('')


 const [showSucc, setSucc] = useState('')
 const [showErr, setErr] = useState('')


 const [loading, setLoading] = useState(true)
 const [avatar, setAvatar] = useState('')
 const [deleted, setDeleted] = useState(false)
 const { register, handleSubmit, errors } = useForm();
 const cookies = parseCookies()
 const [showErr1, setErr1] = useState('')
 const [showSucc1, setSucc1] = useState('')
 const [tipologia, setTipologia] = useState(null)
 const [TokenKeyApiCube, setTokenKeyApiCube] = useState('')
 const [quantitaApp, setquantitaApp] = useState('')
 const [prezzoUnitario, setprezzoUnitario] = useState('')
 const [isCreated, setCreated] = useState(false)




 const ref = React.createRef();

 const options = {
  orientation: 'portrait',
  unit: 'pt',
  format: 'A4',
  scale: '4.1666'
};

 const anno = new Date().getFullYear();

 const arrayData = []


 var sumArray = function() {
  // Use one adding function rather than create a new one each
  // time sumArray is called
  function add(a, b) {
      return a + b;
  }

  return function(arr) {
      return arr.reduce(add);
  };
}();

var creoArray = function(str) {
  arrayData.push(str)
  const Totale = sumArray(arrayData)
  console.log(Totale)
  return (
    Totale
  )

}

 //Converto data in formato italiano
 function convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat)
  return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
}

//Recupero i dati dal db per paramentri Clienti
useEffect(() => {
  let mounted = true
  const id = props.match.params.id
  const fetch = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/appoiments/fetch-client/${id}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
      if(mounted){
        //Controllo se il cleinte è un cliente privato oppure un cliente aziendale
        let tipologia_cliente = users.data[0].tipo_cliente;
        if (tipologia_cliente === 0) {
          //Privato
        viewUsers(users.data[0])
        setTipologia(0)
      } else if (tipologia_cliente === 1){
        //Azienda
        viewUsers(users.data[0])
        setTipologia(1)

      } else {
        //Niente
      }


      }
    } catch (e) {
      console.log(e)
    }
    setLoading(true)
  }
  fetch();
  return () => {
    mounted = false
  }
}, [cookies.token])

//Recupero Token ApiCube
useEffect(() => {
  let mounted = true

  console.log(process.env.REACT_APP_USERNAME_API_CUBE)
  console.log(process.env.REACT_APP_PASSWORD_API_CUBE)

  //Richiedo il Token al software di fatturazione elettronica
  var data = JSON.stringify({
    "email": `${process.env.REACT_APP_USERNAME_API_CUBE}`,
    "password": `${process.env.REACT_APP_PASSWORD_API_CUBE}`
  });
  var config = {
    method: 'post',
    url: `${window.$InvoiceEndpointLogin}/login`,
    headers: {
      'Content-Type': 'application/json'
    },
    data : data
  };

  axios(config)
  .then(function (response) {

  setTokenKeyApiCube(response.data.token);


  })


  .catch(function (error) {
    console.log(error);
  });

  return () => {
    mounted = false
  }
}, [cookies.token])


//Recupero i dati dal db per paramentri Clienti
useEffect(() => {


  let mounted = true
  const id = props.match.params.id
  const n_fattura = props.match.params.n_fattura
  const anno_riferimento = props.match.params.anno_riferimento

  const fetch = async () => {
    const invoice = await axios.get(`${window.$apiEndpoint}/invoice/get-all-invoice-v2/${id}/${n_fattura}/${anno_riferimento}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
      if(mounted){

        viewApp(invoice.data)
        //Conto i numeri di appuntamento
        let lengthApp = invoice.data.length;
        setquantitaApp(lengthApp)

        const data_fattura = invoice.data[0].created
        var data_fattura2 = data_fattura.slice(0, -14);

        viewdataCreated(convertDate(data_fattura2))

        const items = [];
        const noOfItems=  invoice.data.length;

        for (let i=0; i<noOfItems;i++)
        {
          items.push(invoice.data[i].importo_netto)
        }

        const totale_importo_netto = sumArray(items)
        const totale_importo_netto_decimale = Math.round(totale_importo_netto * 100) / 100


        viewImporto(totale_importo_netto_decimale)

        console.log(totale_importo_netto_decimale)

        //Conto il prezzo Unitario

        const PreUni = totale_importo_netto_decimale / lengthApp;
        const PrezzoUniArr = Math.round(PreUni * 100) / 100


        setprezzoUnitario(PrezzoUniArr)

        const items2 = [];
        const noOfItems2=  invoice.data.length;

        for (let i=0; i<noOfItems2;i++)
        {
          items2.push(invoice.data[i].importo_tasse)
        }

        //Ricavo tassee al decimale correzzione 04/05/2023
        const totale_importo_tasse = sumArray(items2)
        //const totale_importo_tasse_2 = Math.round(totale_importo_tasse * 100) / 100
        const totale_importo_tasse_2 = totale_importo_netto_decimale * 0.22

        console.log(totale_importo_tasse_2)
        console.log(totale_importo_netto_decimale * 0.22)


        viewTasse(totale_importo_netto_decimale * 0.22)

        const totaleAll = (totale_importo_netto + totale_importo_tasse_2)

        const totale_fattura = Math.round(totaleAll * 100) / 100

        viewTotale(totale_fattura)


      }
    } catch (e) {
      console.log(e)
    }
    setLoading(true)
  }
  fetch();
  return () => {
    mounted = false
  }
}, [cookies.token])


//Funzione per inviare la fattura Elettronica con Metodo carta
const inviaFatturaCartaPrivato = (n_fattura, codice_fiscale, fname, lname, strada, civico, comune, cap, provincia, trattamento, allImporto, allTasse, allTotale, qua, prezzoU) => {

//Definisco le variabili che mi servono
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

const descrizione = trattamento.toUpperCase();

const importoImpo = allImporto.toFixed(2);
const importTotDecimale = allTotale.toFixed(2);
const importTasseDecimale = allTasse.toFixed(2);
const importUniDecimale = prezzoU.toFixed(2);
const quantDec = qua.toFixed(2);

console.log(importTotDecimale)

//Procedo ad inviare la fattura
var data = JSON.stringify({
  "fattura_elettronica_header": {
    "dati_trasmissione": {
      "id_trasmittente": {
        "id_paese": "IT",
        "id_codice": "03458770041"
      },
      "progressivo_invio": `${n_fattura}`,
      "formato_trasmissione": "FPR12",
      "codice_destinatario": "0000000"
    },
    "cedente_prestatore": {
      "dati_anagrafici": {
        "id_fiscale_iva": {
          "id_paese": "IT",
          "id_codice": "03458770041"
        },
        "anagrafica": {
          "denominazione": "COSTA GIANLUCA"
        },
        "regime_fiscale": "RF01"
      },
      "sede": {
        "indirizzo": "VIA REVELLO 38/L",
        "cap": "12037",
        "comune": "SALUZZO",
        "provincia": "CN",
        "nazione": "IT"
      }
    },
    "cessionario_committente": {
      "dati_anagrafici": {
        "codice_fiscale": `${codice_fiscale}`,
        "anagrafica": {
          "nome": `${fname}`,
          "cognome": `${lname}`
        }
      },
      "sede": {
        "indirizzo": `${strada} ${civico}`,
        "cap": `${cap}`,
        "comune": `${comune}`,
        "provincia": `${provincia}`,
        "nazione": "IT"
      }
    }
  },
  "fattura_elettronica_body": [
    {
      "dati_generali": {
        "dati_generali_documento": {
          "tipo_documento": "TD01",
          "divisa": "EUR",
          "data": `${today}`,
          "numero": `${n_fattura}`,
          "causale": [
            `SESSIONI DI ${descrizione}`
          ]
        },
        "dati_ordine_acquisto": [
          {
            "riferimento_numero_linea": [
              1
            ],
            "id_documento": `${n_fattura}`,
            "num_item": "1"
          }
        ]
      },
      "dati_beni_servizi": {
        "dettaglio_linee": [
          {
            "numero_linea": 1,
            "descrizione": `${descrizione}`,
            "quantita": `${quantDec}`,
            "prezzo_unitario": `${importUniDecimale}`,
            "prezzo_totale": `${importoImpo}`,
            "aliquota_iva": "22.00"
          }
        ],
        "dati_riepilogo": [
          {
            "imponibile_importo": `${importoImpo}`,
            "imposta": `${importTasseDecimale}`,
            "aliquota_iva": "22.00"
          }
        ]
      },
      "dati_pagamento": [
        {
          "condizioni_pagamento": "TP02",
          "dettaglio_pagamento": [
            {
              "modalita_pagamento": "MP08",
              "data_scadenza_pagamento": `${today}`,
              "importo_pagamento": `${importTotDecimale}`
            }
          ]
        }
      ]
    }
  ]
});

console.log(data)

var config = {
  method: 'post',
  url: `${window.$InvoiceEndpointApi}/invoices`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${TokenKeyApiCube}`
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(response.data.uuid);

  //Eseguo Update nel Database
  const uuid_fattura = response.data.uuid;
  const id = props.match.params.id
  const n_fattura = props.match.params.n_fattura

  axios.post(`${window.$apiEndpoint}/invoice/updateStatoFattura`, {
      codice_cliente: id,
      n_fattura: n_fattura,
      uuid_fattura: uuid_fattura,

  }, {
      headers: {
          'x-access-token' : cookies.token
      }
  })
      .then(res => {
          //Setto un Timeout per la risposta
          setTimeout(() => {setCreated(true)},3000)
          setSucc('Fattura inviata con successo, a breve verrete indirizzati sulla lista delle fatture dove potrete visionare lo stato delle fatture!')
      }).catch(err => {
          setErr('Errore - ' + err.message)
      })

})
.catch(function (error) {
  console.log(error);
});


}


//Funzione per inviare la fattura Elettronica con Metodo Bonifico
const inviaFatturaBonificoPrivato = (n_fattura, codice_fiscale, fname, lname, strada, civico, comune, cap, provincia, trattamento, allImporto, allTasse, allTotale, qua, prezzoU) => {

//Definisco le variabili che mi servono
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

const descrizione = trattamento.toUpperCase();

const importoImpo = allImporto.toFixed(2);
const importTotDecimale = allTotale.toFixed(2);
const importTasseDecimale = allTasse.toFixed(2);
const importUniDecimale = prezzoU.toFixed(2);
const quantDec = qua.toFixed(2);

console.log(importTotDecimale)

//Procedo ad inviare la fattura
var data = JSON.stringify({
  "fattura_elettronica_header": {
    "dati_trasmissione": {
      "id_trasmittente": {
        "id_paese": "IT",
        "id_codice": "03458770041"
      },
      "progressivo_invio": `${n_fattura}`,
      "formato_trasmissione": "FPR12",
      "codice_destinatario": "0000000"
    },
    "cedente_prestatore": {
      "dati_anagrafici": {
        "id_fiscale_iva": {
          "id_paese": "IT",
          "id_codice": "03458770041"
        },
        "anagrafica": {
          "denominazione": "COSTA GIANLUCA"
        },
        "regime_fiscale": "RF01"
      },
      "sede": {
        "indirizzo": "VIA REVELLO 38/L",
        "cap": "12037",
        "comune": "SALUZZO",
        "provincia": "CN",
        "nazione": "IT"
      }
    },
    "cessionario_committente": {
      "dati_anagrafici": {
        "codice_fiscale": `${codice_fiscale}`,
        "anagrafica": {
          "nome": `${fname}`,
          "cognome": `${lname}`
        }
      },
      "sede": {
        "indirizzo": `${strada} ${civico}`,
        "cap": `${cap}`,
        "comune": `${comune}`,
        "provincia": `${provincia}`,
        "nazione": "IT"
      }
    }
  },
  "fattura_elettronica_body": [
    {
      "dati_generali": {
        "dati_generali_documento": {
          "tipo_documento": "TD01",
          "divisa": "EUR",
          "data": `${today}`,
          "numero": `${n_fattura}`,
          "causale": [
            `SESSIONI DI ${descrizione}`
          ]
        },
        "dati_ordine_acquisto": [
          {
            "riferimento_numero_linea": [
              1
            ],
            "id_documento": `${n_fattura}`,
            "num_item": "1"
          }
        ]
      },
      "dati_beni_servizi": {
        "dettaglio_linee": [
          {
            "numero_linea": 1,
            "descrizione": `${descrizione}`,
            "quantita": `${quantDec}`,
            "prezzo_unitario": `${importUniDecimale}`,
            "prezzo_totale": `${importoImpo}`,
            "aliquota_iva": "22.00"
          }
        ],
        "dati_riepilogo": [
          {
            "imponibile_importo": `${importoImpo}`,
            "imposta": `${importTasseDecimale}`,
            "aliquota_iva": "22.00"
          }
        ]
      },
      "dati_pagamento": [
        {
          "condizioni_pagamento": "TP02",
          "dettaglio_pagamento": [
            {
              "modalita_pagamento": "MP05",
              "data_scadenza_pagamento": `${today}`,
              "importo_pagamento": `${importTotDecimale}`,
              "istituto_finanziario": "BANCA MEDIOLANUM S.P.A.",
              "iban": "IT55H0306234210000001072803"
            }
          ]
        }
      ]
    }
  ]
});

console.log(data)

var config = {
  method: 'post',
  url: `${window.$InvoiceEndpointApi}/invoices`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${TokenKeyApiCube}`
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(response.data.uuid);

  //Eseguo Update nel Database
  const uuid_fattura = response.data.uuid;
  const id = props.match.params.id
  const n_fattura = props.match.params.n_fattura

  axios.post(`${window.$apiEndpoint}/invoice/updateStatoFattura`, {
      codice_cliente: id,
      n_fattura: n_fattura,
      uuid_fattura: uuid_fattura,

  }, {
      headers: {
          'x-access-token' : cookies.token
      }
  })
  .then(res => {
      //Setto un Timeout per la risposta
      setTimeout(() => {setCreated(true)},3000)
      setSucc('Fattura inviata con successo, a breve verrete indirizzati sulla  lista delle fatture dove potrete visionare lo stato delle fatture!')
  }).catch(err => {
      setErr('Errore - ' + err.message)
  })

})
.catch(function (error) {
  console.log(error);
});


}

//Funzione per inviare la fattura Elettronica con Metodo Contanti
const inviaFatturaContantiPrivato = (n_fattura, codice_fiscale, fname, lname, strada, civico, comune, cap, provincia, trattamento, allImporto, allTasse, allTotale, qua, prezzoU) => {

//Definisco le variabili che mi servono
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

const descrizione = trattamento.toUpperCase();

const importoImpo = allImporto.toFixed(2);
const importTotDecimale = allTotale.toFixed(2);
const importTasseDecimale = allTasse.toFixed(2);
const importUniDecimale = prezzoU.toFixed(2);
const quantDec = qua.toFixed(2);

console.log(importTotDecimale)

//Procedo ad inviare la fattura
var data = JSON.stringify({
  "fattura_elettronica_header": {
    "dati_trasmissione": {
      "id_trasmittente": {
        "id_paese": "IT",
        "id_codice": "03458770041"
      },
      "progressivo_invio": `${n_fattura}`,
      "formato_trasmissione": "FPR12",
      "codice_destinatario": "0000000"
    },
    "cedente_prestatore": {
      "dati_anagrafici": {
        "id_fiscale_iva": {
          "id_paese": "IT",
          "id_codice": "03458770041"
        },
        "anagrafica": {
          "denominazione": "COSTA GIANLUCA"
        },
        "regime_fiscale": "RF01"
      },
      "sede": {
        "indirizzo": "VIA REVELLO 38/L",
        "cap": "12037",
        "comune": "SALUZZO",
        "provincia": "CN",
        "nazione": "IT"
      }
    },
    "cessionario_committente": {
      "dati_anagrafici": {
        "codice_fiscale": `${codice_fiscale}`,
        "anagrafica": {
          "nome": `${fname}`,
          "cognome": `${lname}`
        }
      },
      "sede": {
        "indirizzo": `${strada} ${civico}`,
        "cap": `${cap}`,
        "comune": `${comune}`,
        "provincia": `${provincia}`,
        "nazione": "IT"
      }
    }
  },
  "fattura_elettronica_body": [
    {
      "dati_generali": {
        "dati_generali_documento": {
          "tipo_documento": "TD01",
          "divisa": "EUR",
          "data": `${today}`,
          "numero": `${n_fattura}`,
          "causale": [
            `SESSIONI DI ${descrizione}`
          ]
        },
        "dati_ordine_acquisto": [
          {
            "riferimento_numero_linea": [
              1
            ],
            "id_documento": `${n_fattura}`,
            "num_item": "1"
          }
        ]
      },
      "dati_beni_servizi": {
        "dettaglio_linee": [
          {
            "numero_linea": 1,
            "descrizione": `${descrizione}`,
            "quantita": `${quantDec}`,
            "prezzo_unitario": `${importUniDecimale}`,
            "prezzo_totale": `${importoImpo}`,
            "aliquota_iva": "22.00"
          }
        ],
        "dati_riepilogo": [
          {
            "imponibile_importo": `${importoImpo}`,
            "imposta": `${importTasseDecimale}`,
            "aliquota_iva": "22.00"
          }
        ]
      },
      "dati_pagamento": [
        {
          "condizioni_pagamento": "TP02",
          "dettaglio_pagamento": [
            {
              "modalita_pagamento": "MP01",
              "data_scadenza_pagamento": `${today}`,
              "importo_pagamento": `${importTotDecimale}`
            }
          ]
        }
      ]
    }
  ]
});

console.log(data)

var config = {
  method: 'post',
  url: `${window.$InvoiceEndpointApi}/invoices`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${TokenKeyApiCube}`
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(response.data.uuid);

  //Eseguo Update nel Database
  const uuid_fattura = response.data.uuid;
  const id = props.match.params.id
  const n_fattura = props.match.params.n_fattura

  axios.post(`${window.$apiEndpoint}/invoice/updateStatoFattura`, {
      codice_cliente: id,
      n_fattura: n_fattura,
      uuid_fattura: uuid_fattura,

  }, {
      headers: {
          'x-access-token' : cookies.token
      }
  })
  .then(res => {
      //Setto un Timeout per la risposta
      setTimeout(() => {setCreated(true)},3000)
      setSucc('Fattura inviata con successo, a breve verrete indirizzati sulla  lista delle fatture dove potrete visionare lo stato delle fatture!')
  }).catch(err => {
      setErr('Errore - ' + err.message)
  })

})
.catch(function (error) {
  console.log(error);
});


}

//Funzione per inviare la fattura Elettronica con Metodo carta Azienda
const inviaFatturaCartaAzienda = (n_fattura, iva, ragione_sociale, fname, lname, strada, civico, comune, cap, provincia, trattamento, allImporto, allTasse, allTotale, qua, prezzoU) => {

//Definisco le variabili che mi servono
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

const descrizione = trattamento.toUpperCase();

const importoImpo = allImporto.toFixed(2);
const importTotDecimale = allTotale.toFixed(2);
const importTasseDecimale = allTasse.toFixed(2);
const importUniDecimale = prezzoU.toFixed(2);
const quantDec = qua.toFixed(2);

console.log(importTotDecimale)

//Procedo ad inviare la fattura
var data = JSON.stringify({
  "fattura_elettronica_header": {
    "dati_trasmissione": {
      "id_trasmittente": {
        "id_paese": "IT",
        "id_codice": "03458770041"
      },
      "progressivo_invio": `${n_fattura}`,
      "formato_trasmissione": "FPR12",
      "codice_destinatario": "0000000"
    },
    "cedente_prestatore": {
      "dati_anagrafici": {
        "id_fiscale_iva": {
          "id_paese": "IT",
          "id_codice": "03458770041"
        },
        "anagrafica": {
          "denominazione": "COSTA GIANLUCA"
        },
        "regime_fiscale": "RF01"
      },
      "sede": {
        "indirizzo": "VIA REVELLO 38/L",
        "cap": "12037",
        "comune": "SALUZZO",
        "provincia": "CN",
        "nazione": "IT"
      }
    },
    "cessionario_committente": {
      "dati_anagrafici":{
            "id_fiscale_iva": {
                "id_paese": "IT",
                "id_codice": `${iva}`
            },
            "anagrafica":{
               "denominazione":`${ragione_sociale}`
            }
         },
      "sede": {
        "indirizzo": `${strada} ${civico}`,
        "cap": `${cap}`,
        "comune": `${comune}`,
        "provincia": `${provincia}`,
        "nazione": "IT"
      }
    }
  },
  "fattura_elettronica_body": [
    {
      "dati_generali": {
        "dati_generali_documento": {
          "tipo_documento": "TD01",
          "divisa": "EUR",
          "data": `${today}`,
          "numero": `${n_fattura}`,
          "causale": [
            `SESSIONI DI ${descrizione}`
          ]
        },
        "dati_ordine_acquisto": [
          {
            "riferimento_numero_linea": [
              1
            ],
            "id_documento": `${n_fattura}`,
            "num_item": "1"
          }
        ]
      },
      "dati_beni_servizi": {
        "dettaglio_linee": [
          {
            "numero_linea": 1,
            "descrizione": `${descrizione}`,
            "quantita": `${quantDec}`,
            "prezzo_unitario": `${importUniDecimale}`,
            "prezzo_totale": `${importoImpo}`,
            "aliquota_iva": "22.00"
          }
        ],
        "dati_riepilogo": [
          {
            "imponibile_importo": `${importoImpo}`,
            "imposta": `${importTasseDecimale}`,
            "aliquota_iva": "22.00"
          }
        ]
      },
      "dati_pagamento": [
        {
          "condizioni_pagamento": "TP02",
          "dettaglio_pagamento": [
            {
              "modalita_pagamento": "MP08",
              "data_scadenza_pagamento": `${today}`,
              "importo_pagamento": `${importTotDecimale}`
            }
          ]
        }
      ]
    }
  ]
});

console.log(data)

var config = {
  method: 'post',
  url: `${window.$InvoiceEndpointApi}/invoices`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${TokenKeyApiCube}`
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(response.data.uuid);

  //Eseguo Update nel Database
  const uuid_fattura = response.data.uuid;
  const id = props.match.params.id
  const n_fattura = props.match.params.n_fattura

  axios.post(`${window.$apiEndpoint}/invoice/updateStatoFattura`, {
      codice_cliente: id,
      n_fattura: n_fattura,
      uuid_fattura: uuid_fattura,

  }, {
      headers: {
          'x-access-token' : cookies.token
      }
  })
  .then(res => {
      //Setto un Timeout per la risposta
      setTimeout(() => {setCreated(true)},3000)
      setSucc('Fattura inviata con successo, a breve verrete indirizzati sulla  lista delle fatture dove potrete visionare lo stato delle fatture!')
  }).catch(err => {
      setErr('Errore - ' + err.message)
  })
})
.catch(function (error) {
  console.log(error);
});


}


//Funzione per inviare la fattura Elettronica con Metodo Bonifico Azienda
const inviaFatturaBonificoAzienda = (n_fattura, iva, ragione_sociale, fname, lname, strada, civico, comune, cap, provincia, trattamento, allImporto, allTasse, allTotale, qua, prezzoU) => {

//Definisco le variabili che mi servono
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

const descrizione = trattamento.toUpperCase();

const importoImpo = allImporto.toFixed(2);
const importTotDecimale = allTotale.toFixed(2);
const importTasseDecimale = allTasse.toFixed(2);
const importUniDecimale = prezzoU.toFixed(2);
const quantDec = qua.toFixed(2);

console.log(importTotDecimale)

//Procedo ad inviare la fattura
var data = JSON.stringify({
  "fattura_elettronica_header": {
    "dati_trasmissione": {
      "id_trasmittente": {
        "id_paese": "IT",
        "id_codice": "03458770041"
      },
      "progressivo_invio": `${n_fattura}`,
      "formato_trasmissione": "FPR12",
      "codice_destinatario": "0000000"
    },
    "cedente_prestatore": {
      "dati_anagrafici": {
        "id_fiscale_iva": {
          "id_paese": "IT",
          "id_codice": "03458770041"
        },
        "anagrafica": {
          "denominazione": "COSTA GIANLUCA"
        },
        "regime_fiscale": "RF01"
      },
      "sede": {
        "indirizzo": "VIA REVELLO 38/L",
        "cap": "12037",
        "comune": "SALUZZO",
        "provincia": "CN",
        "nazione": "IT"
      }
    },
    "cessionario_committente": {
      "dati_anagrafici":{
            "id_fiscale_iva": {
                "id_paese": "IT",
                "id_codice": `${iva}`
            },
            "anagrafica":{
               "denominazione":`${ragione_sociale}`
            }
         },
      "sede": {
        "indirizzo": `${strada} ${civico}`,
        "cap": `${cap}`,
        "comune": `${comune}`,
        "provincia": `${provincia}`,
        "nazione": "IT"
      }
    }
  },
  "fattura_elettronica_body": [
    {
      "dati_generali": {
        "dati_generali_documento": {
          "tipo_documento": "TD01",
          "divisa": "EUR",
          "data": `${today}`,
          "numero": `${n_fattura}`,
          "causale": [
            `SESSIONI DI ${descrizione}`
          ]
        },
        "dati_ordine_acquisto": [
          {
            "riferimento_numero_linea": [
              1
            ],
            "id_documento": `${n_fattura}`,
            "num_item": "1"
          }
        ]
      },
      "dati_beni_servizi": {
        "dettaglio_linee": [
          {
            "numero_linea": 1,
            "descrizione": `${descrizione}`,
            "quantita": `${quantDec}`,
            "prezzo_unitario": `${importUniDecimale}`,
            "prezzo_totale": `${importoImpo}`,
            "aliquota_iva": "22.00"
          }
        ],
        "dati_riepilogo": [
          {
            "imponibile_importo": `${importoImpo}`,
            "imposta": `${importTasseDecimale}`,
            "aliquota_iva": "22.00"
          }
        ]
      },
      "dati_pagamento": [
        {
          "condizioni_pagamento": "TP02",
          "dettaglio_pagamento": [
            {
              "modalita_pagamento": "MP05",
              "data_scadenza_pagamento": `${today}`,
              "importo_pagamento": `${importTotDecimale}`,
              "istituto_finanziario": "BANCA MEDIOLANUM S.P.A.",
              "iban": "IT55H0306234210000001072803"
            }
          ]
        }
      ]
    }
  ]
});

console.log(data)

var config = {
  method: 'post',
  url: `${window.$InvoiceEndpointApi}/invoices`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${TokenKeyApiCube}`
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(response.data.uuid);

  //Eseguo Update nel Database
  const uuid_fattura = response.data.uuid;
  const id = props.match.params.id
  const n_fattura = props.match.params.n_fattura

  axios.post(`${window.$apiEndpoint}/invoice/updateStatoFattura`, {
      codice_cliente: id,
      n_fattura: n_fattura,
      uuid_fattura: uuid_fattura,

  }, {
      headers: {
          'x-access-token' : cookies.token
      }
  })
  .then(res => {
      //Setto un Timeout per la risposta
      setTimeout(() => {setCreated(true)},3000)
      setSucc('Fattura inviata con successo, a breve verrete indirizzati sulla  lista delle fatture dove potrete visionare lo stato delle fatture!')
  }).catch(err => {
      setErr('Errore - ' + err.message)
  })

})
.catch(function (error) {
  console.log(error);
});


}

//Funzione per inviare la fattura Elettronica con Metodo Contanti Azienda
const inviaFatturaContantiAzienda = (n_fattura, iva, ragione_sociale, fname, lname, strada, civico, comune, cap, provincia, trattamento, allImporto, allTasse, allTotale, qua, prezzoU) => {

//Definisco le variabili che mi servono
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

const descrizione = trattamento.toUpperCase();

const importoImpo = allImporto.toFixed(2);
const importTotDecimale = allTotale.toFixed(2);
const importTasseDecimale = allTasse.toFixed(2);
const importUniDecimale = prezzoU.toFixed(2);
const quantDec = qua.toFixed(2);

console.log(importTotDecimale)

//Procedo ad inviare la fattura
var data = JSON.stringify({
  "fattura_elettronica_header": {
    "dati_trasmissione": {
      "id_trasmittente": {
        "id_paese": "IT",
        "id_codice": "03458770041"
      },
      "progressivo_invio": `${n_fattura}`,
      "formato_trasmissione": "FPR12",
      "codice_destinatario": "0000000"
    },
    "cedente_prestatore": {
      "dati_anagrafici": {
        "id_fiscale_iva": {
          "id_paese": "IT",
          "id_codice": "03458770041"
        },
        "anagrafica": {
          "denominazione": "COSTA GIANLUCA"
        },
        "regime_fiscale": "RF01"
      },
      "sede": {
        "indirizzo": "VIA REVELLO 38/L",
        "cap": "12037",
        "comune": "SALUZZO",
        "provincia": "CN",
        "nazione": "IT"
      }
    },
    "cessionario_committente": {
      "dati_anagrafici":{
            "id_fiscale_iva": {
                "id_paese": "IT",
                "id_codice": `${iva}`
            },
            "anagrafica":{
               "denominazione":`${ragione_sociale}`
            }
         },
      "sede": {
        "indirizzo": `${strada} ${civico}`,
        "cap": `${cap}`,
        "comune": `${comune}`,
        "provincia": `${provincia}`,
        "nazione": "IT"
      }
    }
  },
  "fattura_elettronica_body": [
    {
      "dati_generali": {
        "dati_generali_documento": {
          "tipo_documento": "TD01",
          "divisa": "EUR",
          "data": `${today}`,
          "numero": `${n_fattura}`,
          "causale": [
            `SESSIONI DI ${descrizione}`
          ]
        },
        "dati_ordine_acquisto": [
          {
            "riferimento_numero_linea": [
              1
            ],
            "id_documento": `${n_fattura}`,
            "num_item": "1"
          }
        ]
      },
      "dati_beni_servizi": {
        "dettaglio_linee": [
          {
            "numero_linea": 1,
            "descrizione": `${descrizione}`,
            "quantita": `${quantDec}`,
            "prezzo_unitario": `${importUniDecimale}`,
            "prezzo_totale": `${importoImpo}`,
            "aliquota_iva": "22.00"
          }
        ],
        "dati_riepilogo": [
          {
            "imponibile_importo": `${importoImpo}`,
            "imposta": `${importTasseDecimale}`,
            "aliquota_iva": "22.00"
          }
        ]
      },
      "dati_pagamento": [
        {
          "condizioni_pagamento": "TP02",
          "dettaglio_pagamento": [
            {
              "modalita_pagamento": "MP01",
              "data_scadenza_pagamento": `${today}`,
              "importo_pagamento": `${importTotDecimale}`
            }
          ]
        }
      ]
    }
  ]
});

console.log(data)

var config = {
  method: 'post',
  url: `${window.$InvoiceEndpointApi}/invoices`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${TokenKeyApiCube}`
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(response.data.uuid);

  //Eseguo Update nel Database
  const uuid_fattura = response.data.uuid;
  const id = props.match.params.id
  const n_fattura = props.match.params.n_fattura

  axios.post(`${window.$apiEndpoint}/invoice/updateStatoFattura`, {
      codice_cliente: id,
      n_fattura: n_fattura,
      uuid_fattura: uuid_fattura,

  }, {
      headers: {
          'x-access-token' : cookies.token
      }
  })
  .then(res => {
      //Setto un Timeout per la risposta
      setTimeout(() => {setCreated(true)},3000)
      setSucc('Fattura inviata con successo, a breve verrete indirizzati sulla  lista delle fatture dove potrete visionare lo stato delle fatture!')
  }).catch(err => {
      setErr('Errore - ' + err.message)
  })

})
.catch(function (error) {
  console.log(error);
});


}

//Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
if (isCreated) {
    // redirect to home if signed up
    //Sleep 1
    return <Redirect to={`/fatt_elettr2/${anno}`} delay={5000} />;
}

  return (

    <>

    <SectionTitle title="Controllo pre fatturazione " subtitle="Fattura Elettronica" />
    <center><pre style={{marginTop: '-2vh', fontSize: '1.3vh', width: '300px'}}><center>{allUsers.fname}_{allUsers.lname}_FATTURA_NUMERO_{allApp[0].n_fattura}</center></pre></center>

      { tipologia == 0 ?  <Container style={{marginTop: '-3vh', marginBottom: '7vh'}}>
      <Row className="justify-content-center">
      <Col md={9}>
      <div  className="card border-left-warning mt-6 shadow h-100 py-4 px-3">

      <div className="ml-3">
      <div  dpi={300} ref={ref} style={{fontFamily: "'Montserrat', sans-serif"}}>
      <div style={{height: '30px', marginTop: '1.9vh'}}></div>
      <div style={{height: '2.3px', backgroundColor: '#424242', width: '500px', marginBottom: '1vh', marginLeft:'4vh'}}></div>
      <Row>
      <Col xs={2}><img style={{ marginBottom: '1vh', marginLeft:'6vh'}} src={Logo} width="40%" alt="Chinelab"/></Col>
      <Col><span style={{ marginBottom: '1vh', marginLeft:'-3vh', position: 'absolute', marginTop:'-1.1vh'}} > <br/>dott. Gianluca Costa</span></Col>
      </Row>
      <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '1.5vh', fontSize: '1.1vh',}} >Chinesiologo Proffessionista di cui alla Legge4/2013</p>
      <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '0.9vh',}} >ISCRITTO ALL'UNIONE NAZIONALE CHINESIOLOGI CON IL N° 11927</p>

      <p style={{ marginBottom: '1vh', marginLeft:'43vh', marginTop: '-11vh'}} >Cliente:</p>
      <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} ><strong>{allUsers.fname} {allUsers.lname}</strong></p>
      <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} >{allUsers.strada}, {allUsers.civico} </p>
      <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} >{allUsers.comune} {allUsers.cap} ({allUsers.provincia}) </p>
      <p style={{ marginBottom: '0.2vh', marginLeft:'43vh'}} >CF: {allUsers.codice_fiscale}</p>



      <p  style={{ marginLeft:'43vh', marginTop: '2vh', }} ><strong>FATTURA N.</strong></p>

      <div className="mt-1" style={{height: '2.3px', backgroundColor: '#424242', width: '280px', marginBottom: '1vh', marginLeft:'43vh'}}></div>

      <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} >{allApp[0].n_fattura}/{anno}<span  style={{ marginLeft:'16vh'}}>{dataCreated}</span></p>


      <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-2.3vh'}} ><strong>DETTAGLIO</strong></p>


      <div className="mt-1" style={{height: '2.3px', backgroundColor: '#424242', width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}></div>


      <div className="mt-1" style={{ width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}>
                                          <Table table-sm>

                                            <tbody>
                                            {allApp.map((item,index) => {
                                            return (

                                              <tr>
                                                <td>{item.trattamento}</td>
                                                <td>{convertDate(item.data_giorno)}</td>
                                                <td>{item.startDate}</td>
                                                <td>{item.endDate}</td>
                                              </tr>
                                            )
                                          })}


                                            </tbody>
                                          </Table>
                                          </div>

                                          <div className="mt-1" style={{ width: '300px', marginBottom: '1vh', marginLeft:'41vh'}}>


                                          <p style={{ marginBottom: '1vh', marginTop: '2.5vh'}} >Imponibile<span> <strong style={{ float:'right'}}>{allImporto} €</strong></span> </p>
                                          <p style={{ marginBottom: '1vh'}} >{allApp[0].descrizione_aliquota}  {allApp[0].aliquota} %<span> <strong style={{ float:'right'}}>{allTasse} €</strong></span> </p>

                                          <p style={{ marginBottom: '1vh', marginTop: '2vh'}} >Totale EUR:<strong style={{ float:'right'}}> {allTotale} €</strong></p>

                                          </div>




                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '3.5vh', fontSize: '1vh'}} ></p>
                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-1vh', fontSize: '1vh'}} ><br/></p>
                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-1vh',fontSize: '1vh'}} ></p>

                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1vh', marginTop: '1vh'}} ><strong>Coordinate Bancarie </strong></p>
                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1.2vh'}} >IT 55H 03062 34210 00000 10728 03</p>
                                          <div className="mt-3" style={{height: '2.3px', backgroundColor: '#424242', width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}></div>

                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1.1vh', marginTop: '1vh'}} >Gianluca Costa - Via Revello 38/L - 12037 Saluzzo (CN) - Codice Fiscale: CSTGLC88S14I470T - Partita IVA: 03458770041</p>
                                          <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '1.5vh', fontSize: '1vh'}} >Trattiamo i Vostri dati per fini amministrativi e contabili. Li tuteliamo rispetto la Privacy e a richiesta rimettiamo Informativa Completa <br/> ai sensi degli art. 13-14-15-16-17-18-19-20-21-22 del Regolamento UE 2016/679</p>



                                           </div>




      <Col className="mb-4">



    <Row className="justify-content-md-center">
    <Col md="auto">
    <center>
    <Pdf targetRef={ref}  options={options} filename={`${allUsers.fname}_${allUsers.lname}_FATTURA_NUMERO_${allApp[0].n_fattura}`}>
      {({ toPdf }) => <Button onClick={toPdf}  className="mt-2" variant="info" size="lg"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
      <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300 mr-2' icon={faDownload} />
     Scarica Fattura
     </Button>

      }
    </Pdf>

    </center>
    </Col>

    <Col md="auto">  <center><a href={`/fatt_elettr`}><Button variant="info" className="mt-2" size="lg"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
    <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300 mr-2' icon={faFileInvoice} />
    Lista Fatture
    </Button></a></center></Col>


    <Col md="auto">
    <center><Button variant="info" className="mt-2" size="lg"  onClick={() => inviaFatturaCartaPrivato(allApp[0].n_fattura, allUsers.codice_fiscale,allUsers.fname, allUsers.lname, allUsers.strada, allUsers.civico, allUsers.comune, allUsers.cap, allUsers.provincia, allApp[0].trattamento, allImporto, allTasse, allTotale, quantitaApp, prezzoUnitario ) } style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
    <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300 mr-2' icon={faPaperPlane} />
    Fattura Elettronica Pagamento con Carta
    </Button></center>
    </Col>

    <Col md="auto">
    <center><Button variant="info" className="mt-2" size="lg"  onClick={() => inviaFatturaBonificoPrivato(allApp[0].n_fattura, allUsers.codice_fiscale,allUsers.fname, allUsers.lname, allUsers.strada, allUsers.civico, allUsers.comune, allUsers.cap, allUsers.provincia, allApp[0].trattamento, allImporto, allTasse, allTotale, quantitaApp, prezzoUnitario ) } style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
    <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300 mr-2' icon={faPaperPlane} />
    Fattura Elettronica Pagamento con Bonifico
    </Button></center>
    </Col>

    <Col md="auto">
    <center><Button variant="info" className="mt-2" size="lg"  onClick={() => inviaFatturaContantiPrivato(allApp[0].n_fattura, allUsers.codice_fiscale,allUsers.fname, allUsers.lname, allUsers.strada, allUsers.civico, allUsers.comune, allUsers.cap, allUsers.provincia, allApp[0].trattamento, allImporto, allTasse, allTotale, quantitaApp, prezzoUnitario ) } style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
    <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300 mr-2' icon={faPaperPlane} />
    Fattura Elettronica Pagamento in Contanti
    </Button></center>
    </Col>





  </Row>

  <Col className="mt-2 mb-2" md="auto">

  {showErr ? <Alert variant="danger">
  <center>{showErr}</center>
  </Alert> : null }
  {showSucc ? <Alert variant="success">
  <center>{showSucc}</center>
  </Alert> : null }
  </Col>



      </Col>

      </div>
      </div>

              </Col>

          </Row>

    </Container> : tipologia == 1 ?
    <Container style={{marginTop: '-3vh', marginBottom: '7vh'}}>
    <Row className="justify-content-center">
    <Col md={9}>
    <div  className="card border-left-warning mt-6 shadow h-100 py-4 px-3">

    <div className="ml-3">
    <div  dpi={300} ref={ref} style={{fontFamily: "'Montserrat', sans-serif"}}>
    <div style={{height: '30px', marginTop: '1.9vh'}}></div>
    <div style={{height: '2.3px', backgroundColor: '#424242', width: '500px', marginBottom: '1vh', marginLeft:'4vh'}}></div>
    <Row>
    <Col xs={2}><img style={{ marginBottom: '1vh', marginLeft:'6vh'}} src={Logo} width="40%" alt="Chinelab"/></Col>
    <Col><span style={{ marginBottom: '1vh', marginLeft:'-3vh', position: 'absolute', marginTop:'-1.1vh'}} > <br/>dott. Gianluca Costa</span></Col>
    </Row>
    <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '1.5vh', fontSize: '1.1vh',}} >Chinesiologo Proffessionista di cui alla Legge4/2013</p>
    <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '0.9vh',}} >ISCRITTO ALL'UNIONE NAZIONALE CHINESIOLOGI CON IL N° 11927</p>

    <p style={{ marginBottom: '1vh', marginLeft:'43vh', marginTop: '-11vh'}} >Cliente:</p>
    <p style={{ marginBottom: '0.2vh', marginLeft:'43vh'}} ><strong>{allUsers.fname} {allUsers.lname}</strong></p>
    <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} ><strong>{allUsers.ragione_sociale}</strong></p>
    <p style={{ marginBottom: '0.2vh', marginLeft:'43vh'}} >{allUsers.strada}, {allUsers.civico} </p>
    <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} >{allUsers.comune} {allUsers.cap} ({allUsers.provincia}) </p>
    <p style={{ marginBottom: '0.2vh', marginLeft:'43vh'}} >Partita Iva: <strong>{allUsers.p_iva} </strong>| SDI: <strong>{allUsers.codice_sdi}</strong></p>
    <p style={{ marginBottom: '0.2vh', marginLeft:'43vh'}} >Indirizzo Pec: <strong>{allUsers.pec}</strong></p>



    <p  style={{ marginLeft:'43vh', marginTop: '2vh', }} ><strong>FATTURA N.</strong></p>

    <div className="mt-1" style={{height: '2.3px', backgroundColor: '#424242', width: '280px', marginBottom: '1vh', marginLeft:'43vh'}}></div>

    <p style={{ marginBottom: '1vh', marginLeft:'43vh'}} >{allApp[0].n_fattura}/{anno}<span  style={{ marginLeft:'16vh'}}>{dataCreated}</span></p>


    <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-2.3vh'}} ><strong>DETTAGLIO</strong></p>


    <div className="mt-1" style={{height: '2.3px', backgroundColor: '#424242', width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}></div>


    <div className="mt-1" style={{ width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}>
                                        <Table table-sm>

                                          <tbody>
                                          {allApp.map((item,index) => {
                                          return (

                                            <tr>
                                              <td>{item.trattamento}</td>
                                              <td>{convertDate(item.data_giorno)}</td>
                                              <td>{item.startDate}</td>
                                              <td>{item.endDate}</td>
                                            </tr>
                                          )
                                        })}


                                          </tbody>
                                        </Table>
                                        </div>

                                        <div className="mt-1" style={{ width: '300px', marginBottom: '1vh', marginLeft:'41vh'}}>


                                        <p style={{ marginBottom: '1vh', marginTop: '2.5vh'}} >Imponibile<span> <strong style={{ float:'right'}}>{allImporto} €</strong></span> </p>
                                        <p style={{ marginBottom: '1vh'}} >{allApp[0].descrizione_aliquota}  {allApp[0].aliquota} %<span> <strong style={{ float:'right'}}>{allTasse} €</strong></span> </p>

                                        <p style={{ marginBottom: '1vh', marginTop: '2vh'}} >Totale EUR:<strong style={{ float:'right'}}> {allTotale} €</strong></p>

                                        </div>




                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '3.5vh', fontSize: '1vh'}} >Imposta di bollo assolta sull'originale per importi maggiori di 77,47€.</p>
                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-1vh', fontSize: '1vh'}} >Operazione effettuata ai sensi dell'art. 1 co.54-89, della Legge n. 190/2014, così come modificato dalla legge n. 208/2015 e <br/> dalle Legge n. 145/2018.</p>
                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '-1vh',fontSize: '1vh'}} >Si richiede la non applicazione della ritenuta alla fonte a titolo d'acconto ai sensi dell'art. 1 co. 67 della Legge n. 190/2014.</p>

                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1vh', marginTop: '1vh'}} ><strong>Coordinate Bancarie </strong></p>
                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1.2vh'}} >IT 55H 03062 34210 00000 10728 03</p>
                                        <div className="mt-3" style={{height: '2.3px', backgroundColor: '#424242', width: '650px', marginBottom: '1vh', marginLeft:'6vh'}}></div>

                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', fontSize: '1.1vh', marginTop: '1vh'}} >Gianluca Costa - Via Revello, 38/L - 12037 Saluzzo (CN) - Codice Fiscale: CSTGLC88S14I470T - Partita IVA: 03458770041</p>
                                        <p style={{ marginBottom: '1vh', marginLeft:'6vh', marginTop: '1.5vh', fontSize: '1vh'}} >Trattiamo i Vostri dati per fini amministrativi e contabili. Li tuteliamo rispetto la Privacy e a richiesta rimettiamo Informativa Completa <br/> ai sensi degli art. 13-14-15-16-17-18-19-20-21-22 del Regolamento UE 2016/679</p>



                                         </div>




    <Col className="mb-4">
    <Row className="justify-content-md-center">
    <Col md="auto">
    <center>
    <Pdf targetRef={ref}  options={options} filename={`${allUsers.fname}_${allUsers.lname}_FATTURA_NUMERO_${allApp[0].n_fattura}`}>
      {({ toPdf }) => <Button onClick={toPdf}  className="mt-2" variant="info" size="lg"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
      <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300 mr-2' icon={faDownload} />
     Scarica Fattura
     </Button>

      }
    </Pdf>

    </center>
    </Col>

    <Col md="auto">  <center><a href={`/fatt_elettr`}><Button variant="info" className="mt-2" size="lg"  style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
    <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300 mr-2' icon={faFileInvoice} />
    Lista Fatture
    </Button></a></center></Col>


    <Col md="auto">
    <center><Button variant="info" className="mt-2" size="lg"  onClick={() => inviaFatturaCartaAzienda(allApp[0].n_fattura, allUsers.p_iva, allUsers.ragione_sociale,  allUsers.fname, allUsers.lname, allUsers.strada, allUsers.civico, allUsers.comune, allUsers.cap, allUsers.provincia, allApp[0].trattamento, allImporto, allTasse, allTotale, quantitaApp, prezzoUnitario ) } style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
    <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300 mr-2' icon={faPaperPlane} />
    Fattura Elettronica Pagamento con Carta
    </Button></center>
    </Col>

    <Col md="auto">
    <center><Button variant="info" className="mt-2" size="lg"  onClick={() => inviaFatturaBonificoAzienda(allApp[0].n_fattura, allUsers.p_iva , allUsers.ragione_sociale, allUsers.fname, allUsers.lname, allUsers.strada, allUsers.civico, allUsers.comune, allUsers.cap, allUsers.provincia, allApp[0].trattamento, allImporto, allTasse, allTotale, quantitaApp, prezzoUnitario ) } style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
    <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300 mr-2' icon={faPaperPlane} />
    Fattura Elettronica Pagamento con Bonifico
    </Button></center>
    </Col>

    <Col md="auto">
    <center><Button variant="info" className="mt-2" size="lg"  onClick={() => inviaFatturaContantiAzienda(allApp[0].n_fattura, allUsers.p_iva, allUsers.ragione_sociale, allUsers.fname, allUsers.lname, allUsers.strada, allUsers.civico, allUsers.comune, allUsers.cap, allUsers.provincia, allApp[0].trattamento, allImporto, allTasse, allTotale, quantitaApp, prezzoUnitario ) } style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
    <FontAwesomeIcon style={{ color: 'white',}} className='fa-1x text-gray-300 mr-2' icon={faPaperPlane} />
    Fattura Elettronica Pagamento in Contanti
    </Button></center>
    </Col>



  </Row>

  <Col className="mt-2 mb-2" md="auto">

  {showErr ? <Alert variant="danger">
  <center>{showErr}</center>
  </Alert> : null }
  {showSucc ? <Alert variant="success">
  <center>{showSucc}</center>
  </Alert> : null }
  </Col>
    </Col>

    </div>
    </div>

            </Col>

        </Row>

  </Container>

    :
    null
    }
  </>
)

}
export default GeninvoiceE;
