import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";


const EditApp = () => {

    const editor = useRef(null)


    //Setto gli state
    const [allUsers, viewUsers] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [isCreated, setCreated] = useState(false)
    const [showSucc, setSucc] = useState('')
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);
    const [selectedOptionTrat, setSelectedOptionTrat] = useState(null);
    const [selectedOptionDate, setSelectedOptionDate] = useState(null);
    const [selectedOptionTs, setSelectedOptionTs] = useState(null);
    const [selectedOptionTe, setSelectedOptionTe] = useState(null);








	  const [content, setContent] = useState('')

	  const config = {
		readonly: false,
    height: 'auto',
    placeholder: '',
    language: 'it' // all options from https://xdsoft.net/jodit/doc/
	  }


      //Recupero i dati dal db
    useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/appoiments/fetchEApp`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(users.data)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Controllo se siamo nel ora legale o solare
  function isDST(d) {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) != d.getTimezoneOffset();
  }



    //Chiamata post per inserimento utente
    const onSubmit = data => {
        const codice_cliente = selectedOption;
        const startDate = data.startDate;
        const endDate = data.endDate;
        const trattamento = content;


        const trattamento_html = trattamento.replace(/<[^>]*>?/gm, '');
        const trattamento_html_2 = trattamento_html.replace(/\&nbsp;/g, '');



        //Controllo se siamo nell'ora legale o solare e a seconda di questo cambio UTC

        const dataSelezionata = new Date(startDate);
        const controlloG = isDST(dataSelezionata)

        if (controlloG === true) {
        //Siamo nell'ora legale
        //Genero le date come servono a Google Calendar
        const data_starDate_Google = startDate + ":00+02:00"
        const data_endDate_Google = endDate + ":00+02:00"

        //Controllo se il codice cliente è selezionato
        if (!codice_cliente) {
          return setErr('Appuntamento non selezionato')
        }

        //Controllo se il trattamento è stato inserito
        if (!trattamento) {
          return setErr('Si prega di inserire il trattamento')
        }


        //Eseguo update su Google Calendar

        axios.get(`${window.$apiEndpoint}/calendar/update-event/${trattamento_html_2}/${data_starDate_Google}/${data_endDate_Google}/${codice_cliente}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(res => {
          axios.post(`${window.$apiEndpoint}/appoiments/update`, {
          codice_cliente: codice_cliente,
          startDate: startDate,
          endDate: endDate,
          trattamento: trattamento,
          IdGoogle: res.data.Id_Google

        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
            .then(res => {
                setUser(res.data)
                setTimeout(() => {setCreated(true)},2500)
                setSucc('Appuntamento modificato con successo, a breve la pagina verrà ricaricata.')
            }).catch(err => {
              setErr('Erore - ' + err.message)
            })

        })
        } else {
        //Siamo nell'ora Solare
        //Genero le date come servono a Google Calendar
        const data_starDate_Google = startDate + ":00+01:00"
        const data_endDate_Google = endDate + ":00+01:00"

        //Controllo se il codice cliente è selezionato
        if (!codice_cliente) {
          return setErr('Appuntamento non selezionato')
        }

        //Controllo se il trattamento è stato inserito
        if (!trattamento) {
          return setErr('Si prega di inserire il trattamento')
        }


        //Eseguo update su Google Calendar

        axios.get(`${window.$apiEndpoint}/calendar/update-event/${trattamento_html_2}/${data_starDate_Google}/${data_endDate_Google}/${codice_cliente}`, {
          headers: {
            "x-access-token": cookies.token
          }
        }).then(res => {
          axios.post(`${window.$apiEndpoint}/appoiments/update`, {
          codice_cliente: codice_cliente,
          startDate: startDate,
          endDate: endDate,
          trattamento: trattamento,
          IdGoogle: res.data.Id_Google

        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
            .then(res => {
                setUser(res.data)
                setTimeout(() => {setCreated(true)},2500)
                setSucc('Appuntamento modificato con successo, a breve la pagina verrà ricaricata.')
            }).catch(err => {
              setErr('Erore - ' + err.message)
            })

        })

        }

    }

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
        // redirect to home if signed up
        return <Redirect to={'/add-appointments'} />;
        //return window.location.reload();
    }

    //Quando Seleziono

   const handleChange = selectedOption => {
      setSelectedOption(selectedOption.value);
      setSelectedOptionLabel(selectedOption.title);
      setSelectedOptionTrat(selectedOption.trattamento);
      setSelectedOptionDate(selectedOption.date);
      setSelectedOptionTs(selectedOption.startDate);
      setSelectedOptionTe(selectedOption.startEnd);
    };

    return (
        <>
            <SectionTitle title="Modifica" subtitle="Modifica appuntamento" />
                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                                    <Col xs={6}>
                                    <Form.Group  controlId="cliente" >
                                    <Form.Label>Seleziona Appuntamento:</Form.Label>

                                    {loading ?
                                    <Select value={selectedOption} onChange={handleChange} options={allUsers} />
                                    : <center><Spinner size="sm" animation="border" variant="info" /></center>
                                    }
                                    <pre className="mt-2">Appuntamento Selezionato: {selectedOptionLabel} </pre>
                                    <pre className="mt-0">Data: {selectedOptionDate} </pre>
                                    <pre className="mt-0">Orario Arrivo: {selectedOptionTs} </pre>
                                    <pre className="mt-0">Orario Partenza: {selectedOptionTe} </pre>
                                    <pre className="mt-0">Trattamento: {selectedOptionTrat} </pre>


                                    </Form.Group>
                                    </Col>


                                    <Col>

                                    <Form.Group  controlId="startDate" >
                                    <Form.Label>Orario di Arrivo</Form.Label>
                                    <Form.Control type="datetime-local" name="startDate" ref={register({ required: true })}/>
                                    </Form.Group>

                                    </Col>


                                     <Col>

                                    <Form.Group  controlId="endDate" >
                                    <Form.Label>Orario di partenza</Form.Label>
                                    <Form.Control type="datetime-local" name="endDate" ref={register({ required: true })} />
                                    </Form.Group>

                                    </Col>




                                </Form.Row>

                                <Form.Row>


                                    <Form.Group  controlId="trattamento" as={Col} >
                                    <Form.Label>Trattamento</Form.Label>
                                    <JoditEditor  name="trattamento" value={content} config={config} ref={editor} tabIndex={1} onBlur={newContent => setContent(newContent.target.innerHTML)} onChange={newContent => {}}/>
                                    </Form.Group>



                                </Form.Row>
                                {errors.startDate && <p className="my-2" style={{ fontWeight: 'bold', color: '#f15a24' }}>Tutti i campi sono obbligatori</p>}
                                <p  className="my-2" style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>

                                <Button variant="info" size="md" type="submit" style={{ backgroundColor: "#009688", borderColor: "#009688", fontWeight: '600'}}>
                                    Modifica Appuntamento
                                </Button>
                                    </Form>
                        </div>

                                </Col>

                            </Row>



                        </Container>

        </>
    )
}
export default EditApp
