import React from 'react'
import {useLocation} from 'react-router-dom'
import Centered from './centered'
import Empty from './empty'
import Layout1 from './layout-1'

const Layouts = ({children}) => {
  let location = useLocation()
  let {pathname} = {...location}
  if (
    [
      '/login',
      '/contact-us-1',
      '/create-account',
      '/email-confirmation',
      '/logout',
      '/reset-password',
      '/forgot-password',
      '/lock-screen',
      '/subscribe',
      '/error-page',
      '/coming-soon'
    ].includes(pathname)
  ) {
    return <Centered>{children}</Centered>
   } else if (['/landing'].includes(pathname)) {
    return <Empty>{children}</Empty>
    //Eccezzione Layout pagina di Bridge per Pagamenti automatici
  } else if ([`/bridge`].includes(pathname)) {
    return <Empty>{children}</Empty>
    //Eccezzione Layout pagina pagamenti 3DS
   } else if ([`/3ds_payment`].includes(pathname)) {
     return <Empty>{children}</Empty>
   //Eccezzione Layout pagina di atterraggio per pagamento
   } else if ([`/checkout`].includes(pathname)) {
     return <Empty>{children}</Empty>
   //Eccezzione Layout pagina di visualizzazione fattura
 } else if (pathname.indexOf("fattura_esterna") > -1) {
     return <Empty>{children}</Empty>
   } else {
    return <Layout1>{children}</Layout1>
  }
}



export default Layouts
