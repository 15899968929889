import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Nav, NavDropdown} from 'react-bootstrap'
import { parseCookies, destroyCookie } from 'nookies'
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus ,faFileInvoice, faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import chinelab from './img/logo.png'
import ReactTooltip from 'react-tooltip';





const NavbarCampus = () => {

  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);


  useEffect(() => {
    const cookies = parseCookies()
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
    }
  }, [setLogout])

  const logout = () => {
    const confirmation = window.confirm('Sei sicuro di voler uscire?')
    if(confirmation === true) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
    }
  }


  if (isLoggedOut) {
    return <Redirect to={`/login`} />;
  }

  const anno = new Date().getFullYear();



  return (

    <>
    <ReactTooltip />

            <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6">
            <div className="flex items-center flex-shrink-0 text-white mr-6">

              <h3 style={{ fontWeight: '900' }}>ChineLab</h3>
              <sup style={{ fontSize: '0.9rem', fontWeight: '600', color: '#424242', marginLeft: '0.2em', marginBottom: '1vh',  }} > Planner</sup>

            </div>
            <div className="block lg:hidden">
              <button
              className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
              onClick={() => toggleExpansion(!isExpanded)}
              >
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
              </button>
            </div>
            <div className={`${isExpanded ? `block` : `hidden`} w-full block flex-grow lg:flex lg:items-center lg:w-auto`}>
              <div className="text-sm lg:flex-grow">

              <a href="/" style={{fontSize: '1rem'}} data-tip="Dashboard" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-2"><FontAwesomeIcon icon={faHome} /> Home</a>
              <NavDropdown style={{fontSize: '1rem', color: 'white'}} title={<span data-tip="Gestisci Clienti" className="block lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-1" ><FontAwesomeIcon icon={faUserPlus} /> Clienti</span>} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white" >
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/clients">Vedi Clienti</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-user">Crea</NavDropdown.Item>
            </NavDropdown>
              <NavDropdown style={{fontSize: '1rem', color: 'white', marginLeft: '-1vh'}} title={<span data-tip="Gestisci Appuntamenti" className="block lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-1" ><FontAwesomeIcon icon={faCalendarPlus} /> Appuntamenti</span>} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white" >
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-appointments">Vedi Calendario</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-app">Crea Singolo</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-app-repeat">Crea Ripetitivo</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/edit-app">Modifica</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/del-app">Elimina</NavDropdown.Item>
            </NavDropdown>

              <NavDropdown style={{fontSize: '1rem', color: 'white', marginLeft: '-1vh'}} title={<span data-tip="Gestisci Fatture" className="block lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-1" ><FontAwesomeIcon icon={faFileInvoice} /> Fatture</span>} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white" >
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-invoice">Crea Fattura</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-invoice-assurance">Crea Fattura per Assicurazione</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/stats">Statistiche</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/export">Esporta Fatture</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/fatt_elettr">Fatturazione Elettronica (Per anno)</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href={`/fatt_elettr2/${anno}`}>Fatturazione Elettronica (Anno Coorrente & Notifiche)</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href={`/pagamenti/${anno}`}>Pagamenti Automatici & Avvalli</NavDropdown.Item>

            </NavDropdown>





              </div>
              <div>
              <a href="/settings" style={{fontSize: '1rem'}} data-tip="Setta Impostazioni" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-3"><FontAwesomeIcon icon={faCogs} />  Impostazioni</a>
              <a href="/add-admin" style={{fontSize: '1rem'}} data-tip="Crea utente admin" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-3"><FontAwesomeIcon icon={faUserLock} />  Utenti</a>
              <a href="/add-admin"   style={{fontSize: '1rem'}} data-tip="Logout" onClick={() => logout()} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-3"><FontAwesomeIcon icon={faSignOutAlt} /> Esci </a>
              </div>
            </div>
          </nav>
  </>
  )
}
export default NavbarCampus
