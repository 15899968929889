import React from 'react'
import {Switch, Route} from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Dashboard from './pages/dashboard'
import UserProfile from './pages/user-profile'
import Login from './pages/login'
import AddUser from './pages/add-user'
import AddUserAdmin from './pages/add-admin'
import Addappointments from './pages/add-appointments'
import Addapp from './pages/add-app'
import AddappRepeat from './pages/add-app-repeat'
import Editapp from './pages/edit-app'
import Delapp from './pages/del-app'
import Clients from './pages/clients'
import AddInvoice from './pages/add-invoice'
import Settings from './pages/settings'
import GenPdf from './pages/gen-pdf'
import GeninvoiceE from './pages/gen-invoiceE'
import GenReport from './pages/gen-report'
import Stats from './pages/stats'
import Export from './pages/export'
import Fatt_elettr from './pages/fatt_elettr'
import Fatt_elettr2 from './pages/fatt_elettr2'
import Bridge from './pages/bridge'
import Payment_3DS from './pages/3ds_payment'
import Checkout from './pages/checkout'
import Pagamenti from './pages/pagamenti'
import FatturaEsterna from './pages/fattura_esterna'
import AddInvoiceAssurance from './pages/add-invoice-assurance'
import GenPdfAss from './pages/gen-pdf-ass'










const Routes = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/user-profile/:id"
        component={UserProfile}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard"
        component={Dashboard}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/pagamenti/:id"
        component={Pagamenti}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/fatt_elettr"
        component={Fatt_elettr}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/fatt_elettr2/:id"
        component={Fatt_elettr2}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-user"
        component={AddUser}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-admin"
        component={AddUserAdmin}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-appointments"
        component={Addappointments}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-app"
        component={Addapp}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/edit-app"
        component={Editapp}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/export"
        component={Export}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/del-app"
        component={Delapp}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/clients"
        component={Clients}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-invoice"
        component={AddInvoice}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-invoice-assurance"
        component={AddInvoiceAssurance}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings"
        component={Settings}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/gen-pdf/:id/:n_fattura/:anno_riferimento"
        component={GenPdf}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/gen-pdf-ass/:id/:n_fattura/:anno_riferimento"
        component={GenPdfAss}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/gen-invoiceE/:id/:n_fattura/:anno_riferimento"
        component={GeninvoiceE}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/gen-report/:id/:n_fattura/:anno_riferimento_prev"
        component={GenReport}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/stats"
        component={Stats}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-app-repeat"
        component={AddappRepeat}
      ></PrivateRoute>
      <PrivateRoute exact path="/" component={Dashboard}></PrivateRoute>
      <Route exact path="/login" component={Login}></Route>
      <Route exact path="/bridge" component={Bridge}></Route>
      <Route exact path="/3ds_payment" component={Payment_3DS}></Route>
      <Route exact path="/checkout" component={Checkout}></Route>
      <Route
        exact
        path="/fattura_esterna/:id/:n_fattura/:anno_riferimento"
        component={FatturaEsterna}
      ></Route>



    </Switch>
  );
}
export default Routes
